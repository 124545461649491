<template>
  <div id="plan-content" class="profile pa-5" ref="content">
    <v-row>
      <v-col>
        <h1>תכנית עסקית - {{ loadedPlan.planName }}</h1>
        <!-- <p>מזהה התכנית: {{ planId }}</p> -->
      </v-col>

      <v-col align="end" data-html2canvas-ignore>
        <!-- <v-spacer></v-spacer> -->
        <!-- <v-btn color="#C1A97D" class="ml-2" @click="editMode = !editMode"
          >עריכה (בקרוב)</v-btn
        > -->
        <v-btn class="yazam-button" @click="getOffer('advisor')">
          <span v-if="offerSent['advisor']">
            ✓ בקשתך התקבלה
          </span>
          <span v-else> ייעוץ עם מומחה</span>
        </v-btn>
        <v-btn class="yazam-button mr-2" @click="getOffer('investor')">
          <span v-if="offerSent['investor']">
            ✓ בקשתך התקבלה
          </span>
          <span v-else> שליחת התכנית למשקיע</span>
        </v-btn>
        <v-btn class="yazam-button mr-2" @click="getOffer('loan')">
          <span v-if="offerSent['loan']">
            ✓ בקשתך התקבלה
          </span>
          <span v-else> בחינת הלוואה</span>
        </v-btn>
        <v-btn class="yazam-button mr-2" @click="duplicatePlan"
          >שכפול תכנית</v-btn
        >
        <v-btn
          color="#2953E8"
          class="mr-2 yazam-button-blue"
          @click="editThisPlan"
          >עריכת התכנית</v-btn
        >
        <!-- <v-btn color="#C1A97D" class="ml-2">מודל תמחור / תובנות עסקיות</v-btn> -->
        <!-- <v-btn class="yazam-button" @click="testpdf">שמירה כ-PDF</v-btn> -->
      </v-col>
    </v-row>

    <h2 class="text-center section-top" v-if="loaded">תמונת מצב עסקית</h2>
    <v-row class="mx-0" v-if="loaded">
      <v-col cols="3">
        <v-card class="pa-8 card-blue">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(calculateIncomePeriod).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center dashboard-box-title">הכנסה שנתית</h3>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card class="pa-8 card-red">
          <!-- <v-icon large color="red darken-2" class="mb-2">
            mdi-minus-circle
          </v-icon> -->

          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(calculateExpensesPeriod).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center dashboard-box-title">הוצאה שנתית</h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-green">
          <!-- <v-icon large color="yellow darken-2" class="mb-2">
            mdi-finance
          </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(calculateNetPeriod).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center dashboard-box-title">רווח שנתי</h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-yellow">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(calculateNetPeriodPercent).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            %
          </h3>
          <h3 class="d-flex justify-center dashboard-box-title">
            אחוז רווחיות העסק
          </h3>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-0" v-if="loaded">
      <v-col cols="3">
        <v-card class="pa-8 card-blue">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(monthsNetBestWorse["best"]["value"]).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center dashboard-box-title">
            חודש פעילות {{ monthsNetBestWorse["best"]["position"] }} הוא המוצלח
            ביותר
          </h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-red">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(monthsNetBestWorse["worse"]["value"]).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center dashboard-box-title">
            חודש פעילות {{ monthsNetBestWorse["worse"]["position"] }} הוא הנמוך
            ביותר
          </h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-green">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(calculatSaleSources.sum.count / 12).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )
            }}
          </h3>
          <h3 class="d-flex justify-center dashboard-box-title">
            ממוצע מספר לקוחות חודשי
          </h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-yellow">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(
                calculateIncomePeriod / calculatSaleSources.sum.count
              ).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center dashboard-box-title">
            שווי ממוצע עסקה
          </h3>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-0 mb-3" v-if="loaded">
      <v-col cols="3">
        <v-card class="pa-8 card-blue">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            מהחודש ה-{{ monthTheBusinessStartProfit }}
          </h3>
          <h3 class="d-flex justify-center text-center dashboard-box-title">
            העסק רווחי
          </h3>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card class="pa-8 card-red">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(calculateInitialAmountForStart).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center text-center dashboard-box-title">
            סכום התחלתי נדרש להקמה
          </h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-green">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            מהחודש ה-{{ monthForROI }}
          </h3>
          <h3 class="d-flex justify-center text-center dashboard-box-title">
            מחזירים את ההשקעה הראשונית
          </h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-yellow">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(
                Math.abs(
                  (calculateNetPeriod / calculateInitialAmountForStart) * 100
                )
              ).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            %
          </h3>
          <h3 class="d-flex justify-center text-center dashboard-box-title">
            תשואה על ההון הראשוני
          </h3>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mx-0 mb-3" v-if="loaded">
      <v-col cols="3">
        <v-card class="pa-8 card-blue">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(highestIncome.max).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center text-center dashboard-box-title">
            המוצר הכי מכניס
            <br />
            {{ highestIncome.item }}
          </h3>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card class="pa-8 card-red">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(highestExpense.max).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center text-center dashboard-box-title">
            ההוצאה הגדולה ביותר
            <br />
            {{ highestExpense.item }}
          </h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-green">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(calculateTaxPeriod.afterTax).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center text-center dashboard-box-title">
            הכנסה לאחר מיסוי צפוי
            <br />
            מס לתשלום:
            {{
              Number(calculateTaxPeriod.tax).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
        </v-card>
      </v-col>
      <v-col cols="3">
        <v-card class="pa-8 card-yellow">
          <!-- <v-icon x-large color="green darken-2" class="mb-2 d-flex justify-center"> mdi-cash </v-icon> -->
          <h3 class="mb-3 dashboard-box-number d-flex justify-center">
            {{
              Number(calculateNetPeriod / 12).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
          <h3 class="d-flex justify-center text-center dashboard-box-title">
            ממוצע רווח חודשי <br />
            יעד הרווח שהוגדר:
            {{
              Number(wantedProfit).toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })
            }}
            ₪
          </h3>
        </v-card>
      </v-col>
    </v-row>

    <h2 class="text-center section-top" v-if="loaded">תרחישים שנתיים</h2>
    <GraphsSinglePlan
      :expenses="loadedPlan.expenses"
      :income="loadedPlan.income"
      :salesCost="calculatSalesCostProfit.cost"
      v-if="loaded"
    />

    <v-card class="pa-4 mx-3 mb-6" elevation="1" v-if="loaded">
      <h3 class="text-center my-4">דו״ח הכנסות והוצאות חודשי (תזרים מזומנים)</h3>
      <v-simple-table v-if="loaded">
        <template v-slot:default>
          <thead>
            <tr>
              <th>פירוט</th>
              <th v-for="(item, i) in months" :key="i">חודש {{ i + 1 }}</th>
              <th>שנתי</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>הכנסות</td>
              <td
                v-for="(col, i) in calculateMonthsIncome"
                :key="i"
                class="table-cell"
              >
                {{
                  Number(col).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td>
                {{
                  Number(calculateIncomePeriod).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
            </tr>
            <tr>
              <td>הוצאות</td>
              <td
                v-for="(col, i) in calculateMonthsExpenses"
                :key="i"
                class="table-cell"
              >
                {{
                  Number(col).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td>
                {{
                  Number(calculateExpensesPeriod).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
            </tr>
            <tr class="summary-row">
              <td>סה"כ</td>
              <td v-for="(col, i) in calculateMonthsNet" :key="i">
                {{
                  Number(col).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td>
                {{
                  Number(
                    calculateIncomePeriod - calculateExpensesPeriod
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
            </tr>
            <tr class="summary-row">
              <td>יתרת מזומן</td>
              <td v-for="(col, i) in calculateMonthsProfitBalance" :key="i">
                {{
                  Number(col).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td>
                {{
                  Number(
                    calculateIncomePeriod - calculateExpensesPeriod
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <apexchart
        height="400"
        type="area"
        :options="chartOptionsArea"
        :series="monthsNetForGraph"
        v-if="loaded"
      ></apexchart>
    </v-card>

    <!-- <v-row class="mx-0 mb-3">
      <v-col cols="4">
        <v-card class="pa-3">
          <v-icon large color="green darken-2" class="mb-2"> mdi-check </v-icon>
          <h3 class="mb-4">החודש הטוב ביותר</h3>
          <p>
            חודש פעילות {{ monthsNetBestWorse["best"]["position"] }} בסכום
            {{
              Number(monthsNetBestWorse["best"]["value"]).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )
            }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3">
          <v-icon large color="red darken-2" class="mb-2">
            mdi-minus-circle
          </v-icon>
          <h3 class="mb-4">החודש הרע ביותר</h3>
          <p>
            חודש פעילות {{ monthsNetBestWorse["worse"]["position"] }} בסכום
            {{
              Number(monthsNetBestWorse["worse"]["value"]).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )
            }}
          </p>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3">
          <v-icon large color="yellow darken-2" class="mb-2">
            mdi-finance
          </v-icon>
          <h3 class="mb-4">ממוצע</h3>
          <p>
            ממוצע רווח חודשי:
            {{
              Number(monthNetAverage).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            }}
          </p>
        </v-card>
      </v-col>
    </v-row> -->
    <!-- <GraphsSinglePlan :expenses="[100, 150, 200]" :income="[200, 300, 400]" /> -->
    <v-divider class="mb-5 mx-3" v-if="loaded"></v-divider>

    <v-card class="pa-4 mx-3" elevation="1" v-if="loaded">
      <h2 class="text-center section-top">הכנסות</h2>
      <h3 class="text-center my-4">התפלגות הכנסות העסק</h3>
      <v-simple-table dense fixed-header v-if="loaded">
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 12%">פירוט</th>
              <th>עלות לעסק</th>
              <th style="width: 10%">מחיר ללקוח</th>
              <th style="width: 10%">מקורות הכנסה</th>
              <th>כמות מכירות</th>
              <th>הכנסה ממכירות</th>
              <th>עלות המכירות</th>
              <th style="width: 12%">רווח לאחר עלות מכירות</th>
              <th style="width: 12%">סה"כ רווח</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in loadedPlan.income.productsRowsData"
              :key="index"
              style="height: 50px"
            >
              <td class="table-name">
                {{ item.item.name }}
              </td>
              <td>
                {{
                  item.item.cost.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td>
                {{
                  item.item.price.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td>
                <div
                  v-for="(saleSource, i) in calculatSaleSourcesPerProduct[
                    index
                  ]['sources']"
                  :key="i"
                >
                  <div v-if="saleSource.count > 0" class="my-1">
                    {{ saleSource.name }}
                  </div>
                </div>
              </td>
              <td>
                <div
                  v-for="(saleSource, i) in calculatSaleSourcesPerProduct[
                    index
                  ]['sources']"
                  :key="i"
                >
                  <div v-if="saleSource.count > 0" class="my-1">
                    {{ saleSource.count }}
                  </div>
                </div>
              </td>
              <td>
                <div
                  v-for="(saleSource, i) in calculatSaleSourcesPerProduct[
                    index
                  ]['sources']"
                  :key="i"
                >
                  <div v-if="saleSource.count > 0" class="my-1">
                    {{
                      (saleSource.count * item.item.price).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2,
                        }
                      )
                    }}
                  </div>
                </div>
              </td>
              <td>
                <div
                  v-for="(saleSource, i) in calculatSaleSourcesPerProduct[
                    index
                  ]['sources']"
                  :key="i"
                >
                  <div v-if="saleSource.count > 0" class="my-1">
                    {{
                      (
                        saleSource.count * saleSource.cost +
                        item.item.cost * saleSource.count
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </div>
                </div>
              </td>
              <td>
                <div
                  v-for="(saleSource, i) in calculatSaleSourcesPerProduct[
                    index
                  ]['sources']"
                  :key="i"
                >
                  <div v-if="saleSource.count > 0" class="my-1">
                    {{
                      (
                        item.item.price * saleSource.count -
                        saleSource.cost * saleSource.count
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </div>
                </div>
              </td>
              <td>
                <div
                  v-for="(saleSource, i) in calculatSaleSourcesPerProduct[
                    index
                  ]['sources']"
                  :key="i"
                >
                  <div v-if="saleSource.count > 0" class="my-1">
                    {{
                      (
                        item.item.price * saleSource.count -
                        saleSource.cost * saleSource.count -
                        item.item.cost * saleSource.count
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </div>
                </div>
              </td>

              <!-- <td>
                <div
                  v-for="(saleSource, index) in item.saleSources"
                  :key="index"
                >
                  <div v-if="saleSource.count" class="my-1">
                    {{
                      (
                        item.item.price * saleSource.count -
                        saleSource.cost * saleSource.count
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                    }}
                  </div>
                </div>
              </td> -->

              <!-- <td>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    מחזור שנתי:
                    {{ item.yearlyEstimate.toLocaleString() }}
                    <br />
                    נטו שנתי:
                    {{ item.yearlyNet.toLocaleString() }}

                    <br />
                    מחזור חודשי:
                    {{ item.monthlyEstimate.toLocaleString() }}

                    <br />
                    נטו חודשי:
                    {{ item.monthlyNet.toLocaleString() }}
                  </div>
                  <v-chip color="primary" class="mr-2 percent-ltr">%</v-chip>
                </div>
                
              </td> -->
            </tr>
            <tr class="summary-row">
              <td>סה"כ</td>
              <td></td>
              <td></td>
              <td></td>
              <td>{{ calculatSaleSources.sum.count }}</td>
              <!-- <td>
                {{
                  Number(calculateIncomePeriod).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }}
              </td> -->
              <!-- <td></td> -->
              <td>
                {{
                  calculatSaleSources.sum.sum.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }}
              </td>
              <td>
                {{
                  calculatSalesCostProfit.cost.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })
                }}
              </td>
              <td>
                {{
                  calculatSalesCostProfit.profitAfterCost.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )
                }}
              </td>
              <td>
                {{
                  calculatSalesCostProfit.profitTotal.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h3 class="text-center my-4">מקורות הכנסה</h3>
      <v-simple-table dense v-if="loaded">
        <template v-slot:default>
          <thead>
            <tr>
              <th>פירוט</th>
              <th>כמות מכירות</th>
              <th>עלות למכירה</th>
              <th>שווי מכירות</th>
              <th>עלות מכירות</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(saleSource, index) in calculatSaleSources.data"
              :key="index"
            >
              <td>{{ saleSource.name }}</td>
              <td>{{ saleSource.count }}</td>
              <td>{{ saleSource.cost }}</td>
              <td>
                {{
                  Number(saleSource.sum).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
              <td>
                {{
                  Number(saleSource.sumCost).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                }}
              </td>
            </tr>
            <tr class="summary-row">
              <td>סה"כ</td>
              <td>{{ calculatSaleSources.sum.count }}</td>
              <td></td>
              <td>
                {{
                  Number(calculatSaleSources.sum.sum).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )
                }}
              </td>
              <td>
                {{
                  Number(calculatSaleSources.sum.sumCost).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h3 class="text-center my-4">הכנסה לפי חודש פעילות</h3>
      <ReviewTableIncome
        :rows="loadedPlan.income.productsRowsData"
        :months="12"
        typeProp="products"
        :editbale="editMode"
        class="my-4"
        v-if="loaded"
      />
      <!-- <Graphs
        :initialRowsData="loadedPlan.income.initialRowsData"
        :fixedRowsData="loadedPlan.income.fixedRowsData"
        :dynamicRowsData="loadedPlan.income.dynamicRowsData"
        typeText="הכנסות"
        v-if="loaded"
      /> -->
      <!-- <h3 class="text-center my-4">סיווג הכנסות</h3> -->
      <v-row>
        <v-col cols="6">
          <h3>סוג הכנסה</h3>
          <apexchart
            width="100%"
            height="400"
            type="donut"
            :options="PieChartSettings"
            :series="incomeValuesForDonut"
            v-if="loaded"
          ></apexchart>
        </v-col>
        <v-col cols="6">
          <h3>מקורות הכנסה</h3>
          <apexchart
            width="100%"
            height="400"
            type="donut"
            :options="SourcesPieChartSettings"
            :series="IncomeSourcesValues"
            v-if="loaded"
          ></apexchart>
        </v-col>
      </v-row>
    </v-card>

    <!-- <v-row class="mx-0 mb-3">
      <v-col cols="4">
        <v-card class="pa-3">
          <v-icon large color="green darken-2" class="mb-2"> mdi-check </v-icon>
          <h3 class="mb-4">המוצר הכי נמכר</h3>
          <p>המוצר "---" נמכר XX פעמים בשנה</p>
          <p>לעומת זאת, המוצר "---" נמכר הכי מעט, רק XX פעמים</p>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3">
          <v-icon large color="green darken-2" class="mb-2"> mdi-cash </v-icon>

          <h3 class="mb-4">המוצר הכי רווחי</h3>
          <p>המוצר "---" הכניס XXX השנה</p>
          <p>לעומת זאת, המוצר "---" הכניס הכי מעט, רק XX פעמים</p>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3">
          <v-icon large color="red darken-2" class="mb-2">
            mdi-minus-circle
          </v-icon>
          <h3 class="mb-4">מידע נוסף</h3>
          <p>---</p>
          <p>---</p>
        </v-card>
      </v-col>
    </v-row> -->

    <v-divider class="mt-7 mb-5 mx-3" v-if="loaded"></v-divider>
    <v-card class="pa-5 mx-3 mt-6" elevation="1" v-if="loaded">
      <h2 class="text-center section-top">הוצאות</h2>
      <ReviewTable
        :rows="combinedExpenses"
        :months="12"
        typeProp="products"
        :editbale="editMode"
        class="my-4"
      />
      <v-row>
        <v-col cols="6">
          <h3 class="mb-4">פירוט הוצאות</h3>
          <apexchart
            width="100%"
            height="400"
            type="donut"
            :options="PieChartExpensesSettings"
            :series="expensesValuesForDonutComp"
          ></apexchart>
        </v-col>
        <v-col cols="6">
          <h3 class="mb-4">סוגי הוצאות</h3>
          <apexchart
            width="100%"
            height="400"
            type="donut"
            :options="donutOptions"
            :series="[calcInitial, calcFixed, calcDynamic]"
          ></apexchart>
        </v-col>
      </v-row>

      <!-- <hr />
      <Graphs
        :initialRowsData="loadedPlan.expenses.initialRowsData"
        :fixedRowsData="loadedPlan.expenses.fixedRowsData"
        :dynamicRowsData="loadedPlan.expenses.dynamicRowsData"
        typeText="הוצאות"
        v-if="loaded"
      /> -->
    </v-card>

    <!-- <v-row class="mx-0" v-if="loaded">
      <v-col cols="6">
        <v-card class="pa-3">
          <h3 class="mb-4">פירוט הוצאות</h3>
          <apexchart
            width="100%"
            height="400"
            type="donut"
            :options="PieChartExpensesSettings"
            :series="expensesValuesForDonutComp"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-3">
          <h3 class="mb-4">סוגי הוצאות</h3>
          <apexchart
            width="100%"
            height="400"
            type="donut"
            :options="donutOptions"
            :series="[calcInitial, calcFixed, calcDynamic]"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row> -->

    <v-divider class="mt-7 mb-5 mx-3" v-if="loaded"></v-divider>
    <v-card
      class="pa-5 mx-3 mt-6"
      elevation="1"
      v-if="loaded && informativeData.stepVision"
    >
      <h2 class="text-center section-top">חזון ומטרות</h2>
      <!-- <p v-for="item in informativeData.stepVision" :key="item">
        <v-icon>mdi-check </v-icon>{{ item }}
      </p> -->
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מה החזון של העסק?</h3>
            <p>מה תחום הפעילות של העסק? לאן תרצו להגיע בעוד 5 שנים?</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepVision.q1"
              label="מה החזון של העסק?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מהי הבעיה הקיימת בשוק?</h3>
            <p>
              מה קורה כיום בשוק? מהו הצורך העיקרי עליו אנחנו רוצים לענות
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepVision.q2"
              label="מהי הבעיה הקיימת בשוק?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מהו הפתרון שאנחנו מציעים?</h3>
            <p>
              מה אנחנו בעצם רוצים להציג ללקוחות שיפתר להם את הבעיות שיש להם כיום
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepVision.q3"
              label="מהו הפתרון שאנחנו מציעים?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מהו המודל העסקי עליו חשבנו?</h3>
            <p>
              תשלום חד פעמי, תשלום חודשי קבוע, תשלום מקהל היעד בצורה ישירה או
              עקיפה
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepVision.q4"
              label="מהו המודל העסקי עליו חשבנו?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מה מיוחד בחברה שלנו?</h3>
            <p>
              במה אנחנו שונים מחברות נוספות? האם יש לנו ייחודיות כלשהי?
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepVision.q5"
              label="מה מיוחד בחברה שלנו?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      class="pa-5 mx-3 mt-6"
      elevation="1"
      v-if="loaded && informativeData.stepResearch"
    >
      <h2 class="text-center section-top">מחקר שוק והיבטים משפטיים</h2>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מהו גודל השוק אליו אנחנו מכוונים?</h3>
            <p>
              - היקף פעילות השוק בעולם
              <br />
              - היקף פעילות השוק מקומית
              <br />
              - פוטנציאל גידול השוק
              <br />
              - מגמות חברתיות שצריך להתייחס אליהם (מלחמה, מגפה וכו׳)
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepResearch.q1"
              label="מהו גודל השוק אליו אנחנו מכוונים?"
              rows="6"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מיהם המתחרים העיקריים שיש לנו?</h3>
            <p>
              - שם החברה, שנת הקמה
              <br />
              - אתר אינטרנט
              <br />
              - מידע כללי
              <br />
              - יתרונות
              <br />
              - חסרונות
              <br />
              - מחזור מכירות
              <br />
              - גודל שוק
              <br />
              - סוגי לקוחות אליהם פונים
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepResearch.q2"
              label="מיהם המתחרים העיקריים שיש לנו?"
              rows="9"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מהם המחירים כיום בשוק לשירות דומה לשלנו?</h3>
            <p>
              - טווח מחירים שיש בשוק היום
              <br />
              - מה המחיר כולל? שווי לקוח?
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepResearch.q3"
              label="מהם המחירים כיום בשוק לשירות דומה לשלנו?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>כמה עסקים דומים לשלי נפתחו/נסגרו ב-3 שנים האחרונות?</h3>
            <!-- <p>
                  תשלום חד פעמי, תשלום חודשי קבוע, תשלום מקהל היעד בצורה ישירה
                  או עקיפה
                </p> -->
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepResearch.q4"
              label="כמה עסקים דומים לשלי נפתחו/נסגרו ב-3 שנים האחרונות?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>

        <hr class="mb-4" />
        <h2>היבטים משפטיים</h2>

        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>האם קיימים הגבלים משפטיים שעלינו לקחת בחשבון?</h3>
            <!-- <p>
                  במה אנחנו שונים מחברות נוספות? האם יש לנו ייחודיות כלשהי?
                </p> -->
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepResearch.q5"
              label="האם קיימים הגבלים משפטיים שעלינו לקחת בחשבון?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>
              האם אנחנו צריכים לקבל אישור/רישיון מסוים בשביל לשווק, למכור
              ולהפעיל את העסק? במידה וכן, מה עלות הרישיון?
            </h3>
            <!-- <p>
                  במה אנחנו שונים מחברות נוספות? האם יש לנו ייחודיות כלשהי?
                </p> -->
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepResearch.q6"
              label="האם אנחנו צריכים לקבל אישור/רישיון מסוים בשביל לשווק, למכור ולהפעיל את העסק? במידה וכן, מה עלות הרישיון?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>
              האם צריך להקים ישות משפטית מקומית/בינלאומית (הקמת חברה
              מקומית/בינלאומית)?
            </h3>
            <!-- <p>
                  במה אנחנו שונים מחברות נוספות? האם יש לנו ייחודיות כלשהי?
                </p> -->
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepResearch.q7"
              label="האם צריך להקים ישות משפטית מקומית/בינלאומית (הקמת חברה מקומית/בינלאומית)?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      class="pa-5 mx-3 mt-6"
      elevation="1"
      v-if="loaded && informativeData.stepMarketing"
    >
      <h2 class="text-center section-top">שיווק ומכירות</h2>
      <!-- <p v-for="item in informativeData.stepMarketing" :key="item">
        <v-icon>mdi-check </v-icon>{{ item }}
      </p> -->
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>למי אנחנו מתכננים לשווק ולמכור? מיהו קהל היעד שלנו?</h3>
            <p>B2B / B2C / שילוב של שניהם - לפרט</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepMarketing.q1"
              label="למי אנחנו מתכננים לשווק ולמכור? מיהו קהל היעד שלנו?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מהו פרופיל הלקוח שלנו?</h3>
            <p>מין, גיל, מיקום, סטטוס אישי, רמת הכנסה, תחומי עניין</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepMarketing.q2"
              label="מהו פרופיל הלקוח שלנו?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>איך אנחנו מתכוונים למכור את המוצרים שלנו?</h3>
            <p>אתר אינטרנט / מפגשים אישיים / חנות פיזית / שעות עבודה</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepMarketing.q3"
              label="איך אנחנו מתכוונים למכור את המוצרים שלנו?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>מה מיוחד במוצר / שירות שלנו?</h3>
            <p>יש לנו יתרון יחסי כלשהו?</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepMarketing.q4"
              label="מה מיוחד במוצר / שירות שלנו?"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>

        <hr class="my-4" />
        <h2>בניית מודל SWOT</h2>
        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>ציין 5 חוזקות שיש לנו</h3>
            <p>במה יש לנו יתרון? במה אנחנו הכי טובים?</p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepMarketing.q5"
              label="חוזקות"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>ציין 5 חולשות שיש לנו</h3>
            <p>
              במה יש לנו חיסרון? ידע / טכנולוגיה / מימון כספי / זמן – כל מה
              שקשרו אלינו באופן אישי בעסק
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepMarketing.q6"
              label="חולשות"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>ציין 5 הזדמנויות שיש לנו</h3>
            <p>
              האם יש שוק חדש שעדיין לא עובד בתחום העיסוק שלנו? האם קיים משבר
              כלשהי שמעניק לנו יתרון משמעותי היום?
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepMarketing.q7"
              label="הזדמנויות"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="3">
            <v-icon color="primary"> mdi-information-outline </v-icon>
            <h3>ציין 5 איומים חיצוניים שיש לנו</h3>
            <p>
              מי מאיים על העסק שלנו? מתחרה דומיננטי בשוק? משבר כלשהו שיכול למוטט
              לנו את העסק? ממה עלינו להיזהר ולקחת בחשבון לפני שאנחנו יוצאים
              לדרך?
            </p>
          </v-col>
          <v-col cols="12" md="9">
            <v-textarea
              v-model="informativeData.stepMarketing.q8"
              label="איומים"
              outlined
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      class="pa-5 mx-3 mt-6"
      elevation="1"
      v-if="loaded && informativeData.teamMembers"
    >
      <h2 class="text-center section-top">בעלי תפקידים</h2>
      <!-- <div v-for="item in informativeData.teamMembers" :key="item">
        <v-icon>mdi-account </v-icon>
        <p>
          שם: {{ item.name }}
          <br />
          תפקיד: {{ item.role }}
          <br />
          הגדרת תפקיד: {{ item.roleDesc }}
          <br />
          חוזקות: {{ item.advantage }}
          <br />
          רקע מקצועי: {{ item.background }}
        </p>
      </div> -->

      <v-simple-table dense fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th>שם</th>
              <th>תפקיד</th>
              <th>הגדרת תפקיד</th>
              <th>חוזקות</th>
              <th>רקע מקצועי</th>
              <!-- <th style="width: 20%">פירוט</th>
                <th style="width: 15%" v-if="!isInitial">חודש פעילות להתחלה</th>
                <th style="width: 20%">הערות</th>
                <th style="width: 25%">מבט שנתי / תקופתי</th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in informativeData.teamMembers" :key="i">
              <td>
                <v-textarea
                  v-model="item['name']"
                  label="שם"
                  rows="3"
                  outlined
                  readonly
                ></v-textarea>
              </td>
              <td>
                <v-textarea
                  v-model="item['role']"
                  label="תפקיד"
                  rows="3"
                  outlined
                  readonly
                ></v-textarea>
              </td>
              <td class="py-2">
                <v-textarea
                  v-model="item['roleDesc']"
                  label="הגדרת תפקיד"
                  rows="3"
                  outlined
                  readonly
                ></v-textarea>
              </td>
              <td class="py-2">
                <v-textarea
                  v-model="item['advantage']"
                  label="חוזקות"
                  rows="3"
                  outlined
                  readonly
                ></v-textarea>
              </td>
              <td class="py-2">
                <v-textarea
                  v-model="item['background']"
                  label="רקע מקצועי"
                  rows="3"
                  outlined
                  readonly
                ></v-textarea>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-alert prominent dense type="info" color="primary" dark v-if="!loaded">
      <v-row align="center">
        <v-col class="grow">
          התכנית נמצאת בשלבי עריכה - ניתן להמשיך לערוך אותה
        </v-col>
        <v-col class="shrink">
          <!-- <v-btn @click="$router.push('/profile')" color="white" rounded style="color: black;">ליצירת עסק חדש</v-btn> -->
          <v-btn
            @click="editThisPlan"
            color="white"
            rounded
            style="color: black;"
            >עריכה</v-btn
          >
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
// import Graphs from "@/components/Graphs.vue";
import GraphsSinglePlan from "@/components/GraphsSinglePlan.vue";
import ReviewTableIncome from "@/components/ReviewTableIncome.vue";
import ReviewTable from "@/components/ReviewTable.vue";
import { plansCollection } from "../firebase";

import VueApexCharts from "vue-apexcharts";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  name: "SinglePlan",
  components: {
    // Graphs,
    GraphsSinglePlan,
    ReviewTableIncome,
    ReviewTable,
    apexchart: VueApexCharts,
  },
  data: () => ({
    loadedPlan: {},
    loaded: false,
    editMode: false,
    months: 12,
    chartOptionsLine: {
      chart: {
        // height: 1000,
        // type: 'line',
        toolbar: {
          show: false,
        },
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
      },
      stroke: {
        width: 7,
        curve: "smooth",
      },
      xaxis: {
        //type: "datetime",
        categories: [
          // "1/11/2000",
          // "2/11/2000",
          // "3/11/2000",
          // "4/11/2000",
          // "5/11/2000",
          // "6/11/2000",
          // "7/11/2000",
          // "8/11/2000",
          // "9/11/2000",
          // "10/11/2000",
          // "11/11/2000",
          // "12/11/2000",
          // "1/11/2001",
          // "2/11/2001",
          // "3/11/2001",
          // "4/11/2001",
          // "5/11/2001",
          // "6/11/2001"
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
        ],
        //tickAmount: 12,
        labels: {
          // formatter: function(value, timestamp, opts) {
          //   return opts.dateFormatter(new Date(timestamp), "dd MMM");
          // }
        },
      },
      // title: {
      //   text: "Social Media",
      //   align: "center",
      //   style: {
      //     fontSize: "16px",
      //     color: "#666"
      //   }
      // },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#FDD835"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100],
        },
      },
      markers: {
        size: 4,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
      yaxis: {
        decimalsInFloat: 2,
        // min: -10,
        // max: 40,
        // title: {
        //   text: "סכום חודשי"
        // }
      },
    },
    chartOptionsArea: {
      chart: {
        // height: 1000,
        // type: 'line',
        toolbar: {
          show: false,
        },
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
      },
      stroke: {
        width: 3,
        curve: "smooth",
      },
      xaxis: {
        //type: "datetime",
        categories: [
          // "1/11/2000",
          // "2/11/2000",
          // "3/11/2000",
          // "4/11/2000",
          // "5/11/2000",
          // "6/11/2000",
          // "7/11/2000",
          // "8/11/2000",
          // "9/11/2000",
          // "10/11/2000",
          // "11/11/2000",
          // "12/11/2000",
          // "1/11/2001",
          // "2/11/2001",
          // "3/11/2001",
          // "4/11/2001",
          // "5/11/2001",
          // "6/11/2001"
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
        ],
        //tickAmount: 12,
        labels: {
          // formatter: function(value, timestamp, opts) {
          //   return opts.dateFormatter(new Date(timestamp), "dd MMM");
          // }
        },
      },
      // title: {
      //   text: "Social Media",
      //   align: "center",
      //   style: {
      //     fontSize: "16px",
      //     color: "#666"
      //   }
      // },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      markers: {
        size: 4,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
      yaxis: {
        decimalsInFloat: 2,
        // min: -10,
        // max: 40,
        // title: {
        //   text: "סכום חודשי"
        // }
      },
      dataLabels: {
        enabled: false,
      },
    },
    donutOptions: {
      labels: ["הוצאות הקמה", "הוצאות קבועות", "הוצאות משתנות"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                fontSize: "16px",
              },
              value: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                formatter: (value) => {
                  return Number(value).toLocaleString();
                },
              },
            },
          },
        },
      },
      legend: {
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        fontSize: "16px",
      },
      dataLabels: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
      },
      tooltip: {
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        y: {
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
    },
    donutOptionsExpenses: {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                fontSize: "16px",
              },
              value: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                formatter: (value) => {
                  return Number(value).toLocaleString();
                },
              },
            },
          },
        },
      },
      legend: {
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        fontSize: "16px",
      },
      dataLabels: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
      },
      tooltip: {
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        y: {
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
    },
    donutOptionsIncome: {
      // labels: this.IncomeNames,
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                fontSize: "16px",
              },
              value: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                formatter: (value) => {
                  return Number(value).toLocaleString();
                },
              },
            },
          },
        },
      },
      legend: {
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        fontSize: "16px",
      },
      dataLabels: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
      },
      tooltip: {
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        y: {
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
    },
    incomeNamesForDonut: [],
    incomeValuesForDonut: [],
    // expensesValuesForDonut: [],
    offerSent: {
      investor: false,
      advisor: false,
      loan: false,
    },
  }),
  computed: {
    planId: function() {
      return this.$route.params.id;
    },
    calculateMonthsIncome: function() {
      let array = [];
      for (let index = 0; index < this.months; index++) {
        array[index] = 0;
      }

      this.loadedPlan?.income?.productsRowsData.forEach((element) => {
        for (let index = 0; index < this.months; index++) {
          array[index] += Number(element.months[index]["monthlyEstimate"]);
        }
      });
      return array;
    },
    calculateMonthsIncomeCosts: function() {
      let array = [];
      for (let index = 0; index < this.months; index++) {
        array[index] = 0;
      }

      this.loadedPlan?.income?.productsRowsData.forEach((prod) => {
        for (let month = 0; month < this.months; month++) {
          prod.months[month].saleSources.forEach((src) => {
            array[month] += Number(src.count * src.cost);
          });
          array[month] += Number(
            prod.months[month].monthlysales * prod.item.cost
          );
          // array[index] += Number(element.months[index]["monthlyEstimate"]);
        }
      });
      return array;
    },
    calculateIncomePeriod: function() {
      return this.calculateMonthsIncome.reduce(
        (acc, current) => acc + current,
        0
      );
    },
    calculateMonthsExpenses: function() {
      let array = [];
      for (let index = 0; index < this.months; index++) {
        // array[index] = 0;
        array[index] = this.calculateMonthsIncomeCosts[index];
      }

      this.loadedPlan.expenses.initialRowsData.forEach((element) => {
        // for (let index = 0; index < this.months; index++) {
        //   array[index] += Number(element.months[index]);
        // }
        array[0] += Number(element.months[0]);
      });
      this.loadedPlan.expenses.fixedRowsData.forEach((element) => {
        for (let index = 0; index < this.months; index++) {
          array[index] += Number(element.months[index]);
        }
      });
      this.loadedPlan.expenses.dynamicRowsData.forEach((element) => {
        for (let index = 0; index < this.months; index++) {
          array[index] += Number(element.months[index]);
        }
      });
      return array;
    },
    calculateExpensesPeriod: function() {
      return this.calculateMonthsExpenses.reduce(
        (acc, current) => acc + current,
        0
      );
    },
    calculateNetPeriod: function() {
      return (
        this.calculateIncomePeriod - this.calculateExpensesPeriod
        // this.calculatSalesCostProfit.cost
      );
    },
    calculateNetPeriodPercent: function() {
      return (this.calculateNetPeriod / this.calculateIncomePeriod) * 100;
    },
    calculateTaxPeriod: function() {
      return {
        tax: this.calculateNetPeriod * 0.25,
        afterTax: this.calculateNetPeriod - this.calculateNetPeriod * 0.25,
      };
    },
    calculateMonthsNet: function() {
      let array = [];
      for (let index = 0; index < this.months; index++) {
        array[index] =
          this.calculateMonthsIncome[index] -
          this.calculateMonthsExpenses[index];
      }

      return array;
    },
    calculateMonthsProfitBalance: function() {
      let array = [];
      array[0] =
        this.calculateMonthsIncome[0] - this.calculateMonthsExpenses[0];
      for (let index = 1; index < this.months; index++) {
        array[index] =
          this.calculateMonthsIncome[index] -
          this.calculateMonthsExpenses[index] +
          array[index - 1];
      }

      return array;
    },
    calculateInitialAmountForStart: function() {
      let amount = 0;
      this.calculateMonthsNet.forEach((month) => {
        if (month < 0) {
          amount += month;
        }
      });
      return Math.abs(amount);
    },
    monthTheBusinessStartProfit: function() {
      let i = 0;
      while (this.calculateMonthsNet[i] < 0) {
        i++;
      }
      return i + 1;
    },
    monthForROI: function() {
      let i = 0;
      while (this.calculateMonthsProfitBalance[i] < 0) {
        i++;
      }
      return i + 1;
    },
    monthsNetForGraph: function() {
      return [
        {
          name: "סכום חודשי",
          data: this.calculateMonthsNet,
        },
      ];
    },
    monthsNetBestWorse: function() {
      const best = Math.max(...this.calculateMonthsNet);
      const worse = Math.min(...this.calculateMonthsNet);

      return {
        best: {
          value: best,
          position: this.calculateMonthsNet.indexOf(best) + 1,
        },
        worse: {
          value: worse,
          position: this.calculateMonthsNet.indexOf(worse) + 1,
        },
      };
    },
    monthNetAverage: function() {
      const sum = this.calculateMonthsNet.reduce((a, b) => a + b, 0);
      const avg = sum / this.calculateMonthsNet.length || 0;
      return avg;
    },

    combinedExpenses: function() {
      const initial = [...this.loadedPlan.expenses.initialRowsData];
      initial.forEach((element) => {
        for (let index = 1; index < this.months; index++) {
          element["months"][index] = 0;
        }
      });

      let combined = [
        ...initial,
        ...this.loadedPlan.expenses.fixedRowsData,
        ...this.loadedPlan.expenses.dynamicRowsData,
      ];
      return combined;
    },

    expensesValuesForDonutComp: function() {
      return this.combinedExpenses.map((obj) => Number(obj.yearly));
    },
    expensesNameForDonutComp: function() {
      return this.combinedExpenses.map((obj) => obj.item);
    },

    highestIncome: function() {
      const yearly = this.loadedPlan?.income?.productsRowsData.map((object) => {
        return object.yearlyEstimateMonthlyChange
          ? object.yearlyEstimateMonthlyChange
          : object.yearlyEstimate;
      });

      const max = Math.max(...yearly);
      const index = yearly.indexOf(max);

      return {
        max: max,
        item: this.loadedPlan?.income?.productsRowsData[index]["item"]["name"],
      };
    },

    highestExpense: function() {
      const yearly = this.combinedExpenses.map((object) => {
        return Number(object.yearly);
      });

      const max = Math.max(...yearly);
      const index = yearly.indexOf(max);

      return {
        max: max,
        item: this.combinedExpenses[index]["item"],
      };
    },

    calcInitial: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.loadedPlan?.expenses?.initialRowsData.reduce(
        (acc, current) => acc + Number(current["yearly"]),
        0
      );
    },
    calcFixed: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.loadedPlan?.expenses?.fixedRowsData.reduce(
        (acc, current) => acc + Number(current["yearly"]),
        0
      );
    },
    calcDynamic: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.loadedPlan?.expenses?.dynamicRowsData.reduce(
        (acc, current) => acc + Number(current["yearly"]),
        0
      );
    },
    informativeData: function() {
      return {
        stepVision: this.loadedPlan.stepVision,
        stepResearch: this.loadedPlan.stepResearch,
        stepMarketing: this.loadedPlan.stepMarketing,
        teamMembers: this.loadedPlan.teamMembers,
      };
    },
    wantedProfit: function() {
      return this.loadedPlan.expectedMonthlyprofit;
    },
    calculatSaleSources: function() {
      let saleSources = [];
      for (let index = 0; index < this.loadedPlan.saleSources.length; index++) {
        // saleSources[index] = 0;
        saleSources[index] = {
          name: this.loadedPlan.saleSources[index]["name"],
          cost: this.loadedPlan.saleSources[index]["cost"],
          count: 0,
          sum: 0,
          sumCost: 0,
        };
      }

      this.loadedPlan.income.productsRowsData.forEach((prod) => {
        prod.months.forEach((month) => {
          month.saleSources.forEach((src, i) => {
            saleSources[i]["count"] += Number(src.count);
            if (src.count > 0) {
              // saleSources[i]["sum"] += Number(month.monthlyNet);
              saleSources[i]["sum"] +=
                Number(prod.item.price) * Number(src.count);
              saleSources[i]["sumCost"] +=
                saleSources[i]["cost"] * Number(src.count);
            }
          });
        });
      });

      let summary = {
        count: saleSources.reduce(
          (acc, current) => acc + Number(current["count"]),
          0
        ),
        sum: saleSources.reduce(
          (acc, current) => acc + Number(current["sum"]),
          0
        ),
        sumCost: saleSources.reduce(
          (acc, current) => acc + Number(current["sumCost"]),
          0
        ),
      };

      return {
        data: saleSources,
        sum: summary,
      };

      // let sources = this.loadedPlan.saleSources.map(item=>({
      //   "name": item.name,
      //   "cost": item.cost,

      //   }));

      // return sources;

      // let array = [];
      // for (let index = 0; index < this.months; index++) {
      //   array[index] = 0;
      // }

      // this.loadedPlan.income.productsRowsData.forEach((element) => {
      //   for (let index = 0; index < this.months; index++) {
      //     array[index] += Number(element.months[index]["monthlyEstimate"]);
      //   }
      // });
      // return array;
    },
    calculatSaleSourcesPerProduct: function() {
      let products = [];

      this.loadedPlan.income.productsRowsData.forEach((prod, index) => {
        let saleSources = [];
        for (
          let indexSource = 0;
          indexSource < this.loadedPlan.saleSources.length;
          indexSource++
        ) {
          // saleSources[index] = 0;
          saleSources[indexSource] = {
            name: this.loadedPlan.saleSources[indexSource]["name"],
            cost: this.loadedPlan.saleSources[indexSource]["cost"],
            count: 0,
          };
        }

        products.push({
          name: prod.item.name,
          cost: prod.item.cost,
          price: prod.item.price,
          sources: saleSources,
        });

        prod.months.forEach((month) => {
          month.saleSources.forEach((src, i) => {
            if (src.count > 0) {
              products[index]["sources"][i]["count"] += Number(src.count);
            }
          });
        });
      });

      return products;
    },
    calculatSalesCostProfit: function() {
      let cost = 0;
      let profitAfterCost = 0;
      let profitTotal = 0;
      this.calculatSaleSourcesPerProduct.forEach((item) => {
        item.sources.forEach((src) => {
          cost += src.cost * src.count + src.count * item.cost;
          profitAfterCost += item.price * src.count - src.cost * src.count;
          profitTotal +=
            item.price * src.count -
            src.cost * src.count -
            item.cost * src.count;
        });
      });
      return {
        cost: cost,
        profitAfterCost: profitAfterCost,
        profitTotal: profitTotal,
      };
    },
    // incomeNames: function () {
    //   let array = [];
    //   this.loadedPlan.income.productsRowsData.forEach((item) => {
    //     array.push(item.item.name);
    //   });
    //   return array;
    // },
    IncomeNames: function() {
      return this.incomeNamesForDonut;
    },
    IncomeSourcesNames: function() {
      return this.calculatSaleSources.data.map((item) => item.name);
    },
    IncomeSourcesValues: function() {
      return this.calculatSaleSources.data.map((item) => item.sum);
    },
    PieChartSettings: function() {
      return {
        labels: this.IncomeNames,
        ...this.donutOptionsIncome,
      };
    },
    PieChartExpensesSettings: function() {
      return {
        labels: this.expensesNameForDonutComp,
        ...this.donutOptionsExpenses,
      };
    },
    SourcesPieChartSettings: function() {
      return {
        labels: this.IncomeSourcesNames,
        ...this.donutOptionsIncome,
      };
    },
  },
  // mounted: async function() {
  //   console.log("route id: " + this.planId);
  //   const planDoc = await plansCollection
  //     .doc(this.planId)
  //     .get()
  //     .then((doc) => doc.data());

  //   this.loadedPlan = planDoc;
  //   if (
  //     this.loadedPlan.expenses &&
  //     this.loadedPlan.income &&
  //     this.loadedPlan.saleSources
  //   ) {
  //     this.loaded = true;
  //   }

  //   this.incomeNamesForDonut = this.mapIncomeNames();
  //   this.incomeValuesForDonut = this.mapIncomeValues();
  //   // this.expensesValuesForDonut = this.mapExpensesValues();

  //   console.log(this.loadedPlan);
  // },
  async activated() {
    console.log("activated again");
    console.log("route id: " + this.planId);
    const planDoc = await plansCollection
      .doc(this.planId)
      .get()
      .then((doc) => doc.data());

    this.loadedPlan = planDoc;
    if (
      this.loadedPlan.expenses &&
      this.loadedPlan.income &&
      this.loadedPlan.saleSources
    ) {
      this.loaded = true;
    }

    this.incomeNamesForDonut = this.mapIncomeNames();
    this.incomeValuesForDonut = this.mapIncomeValues();
    // this.expensesValuesForDonut = this.mapExpensesValues();

    console.log(this.loadedPlan);
  },
  methods: {
    download() {
      console.log("download");
      const doc = new jsPDF();

      const contentHtml = this.$refs.content.innerHTML;
      console.log(contentHtml);
      // doc.fromHTML(contentHtml, 15, 15, {
      //   width: 170
      // });
      //doc.save("sample.pdf");

      doc.html(contentHtml, {
        callback: function(doc) {
          doc.save();
        },
        x: 10,
        y: 10,
      });
    },

    downloadWithCSS() {
      const doc = new jsPDF();
      /** WITH CSS */
      var canvasElement = document.createElement("canvas");
      html2canvas(this.$refs.content, { canvas: canvasElement }).then(function(
        canvas
      ) {
        const img = canvas.toDataURL("image/jpeg", 0.8);
        doc.addImage(img, "JPEG", 20, 20);
        doc.save("sample.pdf");
      });
    },

    testpdf() {
      html2canvas(document.querySelector("#plan-content"), {}).then(
        (canvas) => {
          console.log(canvas);
          this.simulateDownloadImageClick(canvas.toDataURL(), "simba-plan.png");
        }
      );
    },

    simulateDownloadImageClick(uri, filename) {
      const doc = new jsPDF({
        format: [500, 1000],
      });
      console.log("download");
      var link = document.createElement("a");
      if (typeof link.download !== "string") {
        window.open(uri);
      } else {
        link.href = uri;
        link.download = filename;
        this.accountForFirefox(this.clickLink, link);
      }

      console.log(link);

      doc.addImage(uri, "PNG", 20, 20);
      doc.save("simba-plan.pdf");
    },

    clickLink(link) {
      link.click();
    },

    accountForFirefox(click) {
      // wrapper function
      let link = arguments[1];
      document.body.appendChild(link);
      click(link);
      document.body.removeChild(link);
    },

    editThisPlan() {
      // if(Object.keys(this.$store.state.editedPlanData).length > 0) {
      //   console.log("not empty")
      // }
      this.$store.commit("setEditedPlanData", this.loadedPlan);
      this.$store.commit("setCurrentPlanID", this.planId);
      this.$store.commit("setCurrentBusiness", this.loadedPlan?.business);
      this.$store.commit("setTempsaleSources", this.loadedPlan?.saleSources);

      if (this.loadedPlan?.expenses) {
        let payloadInitial = {
          data: this.loadedPlan?.expenses?.initialRowsData,
          type: "initial",
        };

        let payloadFixed = {
          data: this.loadedPlan?.expenses?.fixedRowsData,
          type: "fixed",
        };

        let payloadDynamic = {
          data: this.loadedPlan?.expenses?.dynamicRowsData,
          type: "dynamic",
        };

        let payloadProducts = {
          data: this.loadedPlan?.income?.productsRowsData,
          type: "products",
        };

        this.$store.commit("updatePlan", payloadInitial);
        this.$store.commit("updatePlan", payloadFixed);
        this.$store.commit("updatePlan", payloadDynamic);
        this.$store.commit("updatePlan", payloadProducts);
      }

      console.log("editedPlanData: ", this.$store.state.editedPlanData);
      this.$router.push("/new-plan/intro");
    },

    mapIncomeNames() {
      let array = [];
      this.loadedPlan?.income?.productsRowsData.forEach((item) => {
        array.push(item.item.name);
      });
      console.log("mapIncomeNames", array);
      // this.incomeNamesForDonut = array;
      return array;
    },
    mapIncomeValues() {
      let array = [];
      this.loadedPlan?.income?.productsRowsData.forEach((item) => {
        if (item.yearlyEstimateMonthlyChange) {
          array.push(item.yearlyEstimateMonthlyChange);
        } else {
          array.push(item.yearlyNet);
        }
      });
      console.log("mapIncomeValues", array);
      // this.incomeNamesForDonut = array;
      return array;
    },
    async duplicatePlan() {
      console.log("duplicate plan id: " + this.planId);
      await plansCollection
        .doc(this.planId)
        .get()
        .then((doc) => doc.data())
        .then((data) => {
          plansCollection.add({
            ...data,
            planName: `${data.planName} (שכפול)`,
          });
        });
      // .then(this.$store.dispatch("loadAllPlans"))
      // .then(this.$router.push("/plans"));

      this.$store.dispatch("loadAllPlans");
      this.$router.push("/plans");
    },
    getOffer(topic) {
      fetch("https://hook.eu1.make.com/2hbgh6763weaq4e7ak4qg1pylqvxrk8f", {
        method: "POST",
        body: JSON.stringify({
          userId: this.$store.state.currentUser,
          userData: this.$store.state.currentUserData,
          services: [topic],
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      this.offerSent[topic] = true;
    },
  },
};
</script>

<style>
.section-top {
  font-size: 35px;
}

.dashboard-box-title {
  /* color: var(--main-blue); */
  font-weight: 400;
}

.dashboard-box-number {
  color: #000000;
  font-size: 36px;
  font-weight: 600;
}

.card-blue {
  box-shadow: none !important;
  border-bottom: solid 3px #2953e8 !important;
  border-radius: 15px !important;
}

.card-red {
  box-shadow: none !important;
  border-bottom: solid 3px #e64a19 !important;
  border-radius: 15px !important;
}

.card-yellow {
  box-shadow: none !important;
  border-bottom: solid 3px #fdd835 !important;
  border-radius: 15px !important;
}

.card-green {
  box-shadow: none !important;
  border-bottom: solid 3px green !important;
  border-radius: 15px !important;
}

h3.dashboard-box-number {
  direction: ltr;
}

/* #plan-content h3, #plan-content h2, h2.text-center.section-top{
  direction: rtl !important;
  word-wrap: break-word !important;
  white-space: pre !important;
  word-break: normal !important;
  overflow-wrap: normal !important;
  letter-spacing: normal !important;
  font-variant-ligatures:none !important;
  word-spacing: normal !important;
} */
</style>
