<template>
  <v-app id="app">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      v-if="this.$route.path !== '/login' && this.$route.path !== '/register'"
      permanent
      right
      app
    >
      <v-list-item class="px-2" style="height: 64px">
        <v-list-item-avatar>
          <v-img
            src="https://yazam.app/wp-content/uploads/2022/06/yazam-profile.png"
          ></v-img>
          <!-- <v-img :src="require('../assets/growth.png')"></v-img> -->
        </v-list-item-avatar>

        <v-list-item-title
          >שלום, {{ this.$store.state.currentUserData.name }}</v-list-item-title
        >

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <!-- <v-list>
        <v-list-item v-for="item in items" :key="item.title" link :to="item.link">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>-->

      <!-- <v-list-group :value="true" prepend-icon="mdi-plus">
        <template v-slot:activator>
          <v-list-item-title>תחזית - ניסיון</v-list-item-title>
        </template>

        <v-list-group :value="true" no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="([title, icon], i) in admins" :key="i" link>
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Actions</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="([title, icon], i) in cruds" :key="i" link>
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
      </v-list-group>-->

      <v-list>
        <template v-for="item in items">
          <!-- :prepend-icon="item.model ? item.icon : item.iconAlt" -->
          <!-- append-icon -->
          <v-list-group
            v-if="item.children"
            :key="item.title"
            v-model="item.model"
            :prepend-icon="item.icon"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              :to="child.link"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="item.title" link :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <!-- <v-divider></v-divider> -->
      <!-- TODO: dynamic height, depends on screen resolution -->
      <!-- <v-card class="yazam-banner" flat>
        <router-link to="/development">
          <v-img
            src="https://yazam.app/wp-content/uploads/2023/08/yazam-vision.jpg"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            height="160px"
          >
            <v-card-title>לייעוץ ופיתוח עסקי</v-card-title>
            <v-card-subtitle class="white--text">אנחנו כאן לשירותכם</v-card-subtitle>
          </v-img>
        </router-link>
      </v-card> -->
    </v-navigation-drawer>

    <v-app-bar
      app
      color="white"
      flat
      v-if="this.$route.path !== '/login' && this.$route.path !== '/register'"
    >
      <v-container class="py-0 fill-height">
        <!-- <v-avatar class="ml-5" color="grey darken-1" size="32"></v-avatar> -->

        <!-- <router-link v-for="link in links" :key="link.title" :to="link.link">
          <v-btn text>
            <v-list-item-title>{{ link.title }}</v-list-item-title>
          </v-btn>
        </router-link>-->

        <v-btn
          v-for="link in links"
          :key="link.title"
          :to="link.link"
          text
          class="menu-links"
        >
          <!-- <v-list-item-title>{{link.title}}</v-list-item-title> -->
          {{ link.title }}
        </v-btn>
        <v-btn to="/development" text class="menu-links mr-2">
          <v-badge content="מומלץ" color="green" offset-y="1" floating>
            פיתוח עסקי
          </v-badge>
        </v-btn>
        <v-btn text class="menu-links mr-2">
          <v-badge content="בקרוב" color="#9575CD" offset-y="1" floating>
            מחקר שוק AI
          </v-badge>
        </v-btn>

        <v-spacer></v-spacer>

        <v-responsive
          max-width="320"
          v-if="!this.$route.path.includes('/plans')"
        >
          <v-select
            v-model="selectedCurrentBusiness"
            :items="businessOptions"
            item-text="title"
            item-value="value"
            label="העסק הנוכחי"
            dense
            flat
            hide-details
            rounded
            solo-inverted
          ></v-select>

          <!-- <v-text-field dense flat hide-details rounded solo-inverted></v-text-field> -->
        </v-responsive>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2"
              @click="$router.push('/new-plan/intro')"
              fab
              dark
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>תכנית חדשה</span>
        </v-tooltip>

        <v-btn text class="menu-links" v-if="isLoggedIn" @click="logout"
          >התנתק</v-btn
        >
      </v-container>
    </v-app-bar>

    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <!-- <v-col cols="2" v-if="$route.name != 'Login'">
            <v-sheet rounded="lg">
              <v-list color="transparent">
                <v-list-item v-for="link in sideLinks" :key="link.title" link :to="link.link">
                  <v-list-item-content>

                    <v-list-item-title>{{link.title}}</v-list-item-title>

                  </v-list-item-content>
                </v-list-item>

                <v-divider class="my-2"></v-divider>

                <v-list-item link color="grey lighten-4">
                  <v-list-item-content>
                    <v-list-item-title>שיחת ייעוץ עם מומחה</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>-->

          <v-col>
            <!-- <v-sheet elevation="1" rounded="lg" class="mb-5 pa-5">
              <h1>כותרת</h1>
            </v-sheet>-->
            <!-- <v-sheet min-height="70vh" elevation="1" rounded="lg"> -->
            <!--  -->

            <keep-alive>
              <router-view :key="$route.fullPath"></router-view>
            </keep-alive>
            <!-- </v-sheet> -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { auth } from "./firebase";
//import { mapState } from "vuex"

export default {
  data: () => ({
    selectedCurrentBusiness: null,
    links: [
      { title: "דף הבית", link: "/" },
      { title: "העסקים שלי", link: "/profile" },
      { title: "תכניות עסקיות", link: "/plans" },
      { title: "האקדמיה ליזם", link: "/academy" },
      // { title: "שותפים להצלחה", link: "/development" },
      // { title: "תמיכה אונליין", link: "/contact" },
    ],
    sideLinks: [
      { title: "התחזיות שלי", link: "/plans" },
      { title: "תחזית חדשה", link: "/new-plan" },
      { title: "מודל תמחור / תובנות עסקיות", link: "/insights" },
    ],
    drawer: true,
    items: [
      { title: "התחזיות שלי", link: "/plans", icon: "mdi-finance" },
      {
        title: "כלי בניית תמחור",
        link: "/insights",
        icon: "mdi-calculator-variant-outline",
      },
      {
        title: "תכנית חדשה",
        link: "/new-plan/intro",
        icon: "mdi-file-document-outline",
        iconAlt: "mdi-plus-circle",
        model: false,
        children: [
          {
            title: "הקדמה והגדרות",
            link: "/new-plan/intro",
            icon: "mdi-information-outline",
          },
          {
            title: "חזון ומטרות",
            link: "/new-plan/vision",
            icon: "mdi-bullseye-arrow",
          },
          {
            title: "מחקר שוק ורגולציה",
            link: "/new-plan/research",
            icon: "mdi-earth",
          },
          {
            title: "בעלי תפקידים",
            link: "/new-plan/people",
            icon: "mdi-account-multiple",
          },
          {
            title: "שיווק ומכירות",
            link: "/new-plan/marketing",
            icon: "mdi-finance",
          },
          {
            title: "הוצאות",
            link: "/new-plan/expenses",
            icon: "mdi-minus-circle-outline",
          },
          {
            title: "הכנסות",
            link: "/new-plan/income",
            icon: "mdi-plus-circle-outline",
          },
          // {
          //   title: "מיסים (בקרוב)",
          //   // link: "/new-plan/taxes",
          //   link: "#",
          //   icon: "mdi-bank",
          // },
        ],
      },
      // {
      //   title: "שאלות ותשובות",
      //   link: "/contact",
      //   // link: "#",
      //   icon: "mdi-message-outline",
      // },
      // {
      //   title: "---תחזית חדשה (ישן)---",
      //   link: "/old-new-plan",
      //   icon: "mdi-file-document-outline",
      // },
      // {
      //   title: "---Welcome Wizard---",
      //   link: "/welcome",
      //   icon: "mdi-hand-wave",
      // },
    ],
    mini: false,
    // admins: [
    //   ["Management", "mdi-account-multiple-outline"],
    //   ["Settings", "mdi-cog-outline"]
    // ],
    // cruds: [
    //   ["Create", "mdi-plus-outline"],
    //   ["Read", "mdi-file-outline"],
    //   ["Update", "mdi-update"],
    //   ["Delete", "mdi-delete"]
    // ],

    // itemsNew: [
    //   { icon: "mdi-contacts", title: "Contacts" },
    //   { icon: "mdi-history", title: "Frequently contacted" },
    //   { icon: "mdi-content-copy", title: "Duplicates" },
    //   {
    //     icon: "mdi-chevron-up",
    //     iconAlt: "mdi-chevron-down",
    //     title: "Labels",
    //     model: true,
    //     children: [{ icon: "mdi-plus", title: "Create label" }]
    //   },
    //   {
    //     icon: "mdi-chevron-up",
    //     iconAlt: "mdi-chevron-down",
    //     title: "More",
    //     model: false,
    //     children: [
    //       { title: "Import" },
    //       { title: "Export" },
    //       { title: "Print" },
    //       { title: "Undo changes" },
    //       { title: "Other contacts" }
    //     ]
    //   },
    //   { icon: "mdi-cog", title: "Settings" },
    //   { icon: "mdi-message", title: "Send feedback" },
    //   { icon: "mdi-help-circle", title: "Help" },
    //   { icon: "mdi-cellphone-link", title: "App downloads" },
    //   { icon: "mdi-keyboard", title: "Go to the old version" }
    // ]
  }),
  methods: {
    logout: function() {
      auth.signOut().then(() => {
        console.log("Logged out");
        this.$store.dispatch("logoutUser");
        this.$router.push("/login");
      });
    },
  },
  computed: {
    isLoggedIn: function() {
      if (this.$store.state.currentUser) {
        return true;
      } else {
        console.log("isLoggedIn");
        console.log(this.$store.state.currentUser);
        return false;
      }
    },
    businessOptions: function() {
      let data = this.$store.state.userBusiness;
      let fixed = [];

      if (data) {
        data.forEach((item) => {
          fixed.push({
            title: item.data.businessname,
            value: item.id,
          });
        });
      }

      return fixed;
    },
    businessFromState: function() {
      return this.$store.state.userBusiness;
    },
    currentBusinessFromStore: function() {
      return this.$store.state.currentBusiness;
    },
  },
  created: function() {
    this.$store.dispatch("setCurrentUser").then((res) => {
      if (res) {
        console.log("setCurrentUser in created" + res);
        //this.$store.dispatch("setCurrentUserData");
        this.$store.dispatch("loadAllPlans");
        this.$store.dispatch("loadAllBusiness").then(() => {
          console.log(
            "created this.$store.state.currentBusiness" +
              this.$store.state.currentBusiness
          );
          this.selectedCurrentBusiness = this.$store.state.currentBusiness;
        });
      } else {
        console.log("Not logged in");
      }
    });

    //const LSUser = localStorage.getItem("simbaCurrentUser");
    //if (LSUser) {
    //this.$store.dispatch("setCurrentUserFromLS");
    //   console.log("created hook");
    //   console.log(LSUser);
    //   console.log("END created hook");
    // } else {
    //   console.log("LocalStorage is empty");
    // }
  },
  watch: {
    selectedCurrentBusiness: function(value) {
      console.log(value);
      this.$store.commit("setCurrentBusiness", value);
    },
    currentBusinessFromStore: function() {
      this.selectedCurrentBusiness = this.currentBusinessFromStore;
    },
  },
};
</script>

<style>
/* .v-btn__content {
  font-family: 'Open Sans Hebrew';
} */

:root {
  --main-blue: #2953e8;
  --main-grey: #f5f5f5;
}

div {
  font-family: "Open Sans Hebrew";
}

a {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none;
}

.menu-links {
  font-size: 1rem !important;
}

/* scroll */
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px 0 0 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #acb3c3;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808aa4;
}

.yazam-button  {
  background-color: #ffffff00 !important;
  border: 1px solid var(--main-blue);
  border-radius: 30px !important;
  box-shadow: none !important;
}

.yazam-button-grey  {
  /* background-color: #ffffff00 !important; */
  border: 0.25px solid #afb2b7;
  border-radius: 30px !important;
  box-shadow: none !important;
}

.yazam-button-blue {
  color: #ffffff !important;
  border-radius: 30px !important;
  box-shadow: none !important;
}

.yazam-banner {
  position: absolute !important;
  bottom: 0;
  padding: 10px;
}

/* .router-link-active {
  color: rgba(0, 0, 0, 0.87) !important;
  text-decoration: none;
} */
</style>
