<template>
  <div class="new-plan pa-5">
    <h1 class="mb-2">תחזית חדשה</h1>
    
    <ExpenseWizardCopy />

    <h2 class="my-6">מחשבון חדש</h2>
    <Wizard />

  </div>
</template>

<script>
import ExpenseWizardCopy from "@/components/ExpenseWizard copy";
import Wizard from "@/components/Wizard";

export default {
  name: "NewPlan",
  components: {
    ExpenseWizardCopy,
    Wizard
  }
};
</script>

