<template>
  <div>
    <v-simple-table class="elevation-1">
      <template v-slot:default>
        <thead>
          <tr>
            <th>פירוט</th>
            <th v-for="(item, i) in months" :key="i">חודש {{ i + 1 }}</th>
            <th width="160">שנתי (אחוז מהכלל)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, order) in rowsData" :key="order">
            <td>
              <v-btn
                @click="deleteRow(order)"
                color="red"
                icon
                x-small
                v-if="editbale"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
              {{ (typeProp = "products" ? item.item.name : item.item) }}
            </td>
            <td
              v-for="(col, i) in months"
              :key="i"
              @click="openDialog(i, item, order)"
              class="table-cell"
            >
              {{
                Number(item.months[i]["monthlyEstimate"]).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                )
              }}
            </td>
            <td class="d-flex justify-space-between align-center">
              {{
                item.yearlyEstimateMonthlyChange
                  ? item.yearlyEstimateMonthlyChange.toLocaleString(undefined, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    })
                  : item.yearlyEstimate.toLocaleString()
              }}

              <v-chip color="primary" small class="mr-1 percent-ltr"
                >{{
                  (
                    ((item.yearlyEstimateMonthlyChange
                      ? item.yearlyEstimateMonthlyChange
                      : item.yearlyEstimate) /
                      calculatePeriod) *
                    100
                  ).toFixed(2)
                }}%</v-chip
              >
            </td>
          </tr>
          <tr class="summary-row">
            <td>סה"כ</td>
            <td v-for="(col, i) in months" :key="i">
              {{
                calculateMonths[i].toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </td>
            <td>
              {{
                calculatePeriod.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <apexchart
      height="400"
      type="area"
      :options="chartOptionsArea"
      :series="monthsForGraph"
    ></apexchart>

    <v-dialog v-model="dialog" max-width="30%">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>עדכון נתונים</v-toolbar-title>
        </v-toolbar>
        <v-card-title v-if="dialog">{{ selecteditem.item.name }}</v-card-title>

        <v-card-text v-if="dialog">
          <ul class="mr-4 mb-4 black--text">
            <li>ערך לשינוי: {{ selecteditem.item.name }}</li>
            <li>
              מחיר מכירה ליחידה:
              {{
                Number(selecteditem.item.price).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </li>
            <li>החודש לשינוי: חודש פעילות {{ selectedmonth + 1 }}</li>
            <li>
              הערך הנוכחי:
              {{
                Number(
                  selecteditem.months[selectedmonth]["monthlyEstimate"]
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              }}
            </li>
            <li>
              מכירות בחודש זה:
              {{ selecteditem.months[selectedmonth]["monthlysales"] }}
            </li>
          </ul>

          <v-row>
            <v-col cols="6">
              <v-text-field
                type="number"
                label="עדכון כמות מכירות"
                v-model.number="changeMonthlySales"
                @input="
                  changeCell = changeMonthlySales * selecteditem.item.price
                "
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                type="number"
                label="הזנת ערך חדש"
                v-model.number="changeCell"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>

          <h3 class="black--text">מיפוי מקורות מכירה</h3>

          <div v-if="selecteditem.months">
            <v-text-field
              v-for="(item, i) in selecteditem.months[selectedmonth][
                'saleSources'
              ]"
              :key="i"
              type="number"
              :label="`הזנת כמות מכירות חודשית דרך ${item.item}`"
              :value="currentMonthlySaleSourcesCount[i]"
              @input="setItemSourceValue(i, $event)"
            ></v-text-field>
          </div>

          <v-alert type="info" color="primary" prominent dense class="mt-2">
            הוגדרו {{ currentMonthlySourcesSummary }} מכירות חודשיות בסיווג לפי
            מקורות.
            <br />
            אנא ודאו כי תואם לכמות המכירות החודשית.</v-alert
          >
          <!-- הוגדרו {{ currentItemSalesSourcesCount }} מכירות מתוך
          {{ rowsData[indexInRowsData(selecteditem)]["monthlysales"] }}
          <v-alert
            v-show="
              currentItemSalesSourcesCount > 0 &&
              currentItemSalesSourcesCount !=
                rowsData[indexInRowsData(selecteditem)]['monthlysales']
            "
            type="error"
            class="mt-2"
            >סיווג המקורות צריך להיות זהה לכמות המכירות החודשית</v-alert
          > -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="
              updateCell(
                changeCell,
                changeMonthlySales,
                currentMonthlySaleSourcesCount
              )
            "
            >שינוי תא בודד</v-btn
          >

          <!-- <v-btn
            color="primary"
            text
            @click="
              updateCell(
                changeCell,
                changeMonthlySales,
                currentMonthlySaleSourcesCount,
                'equal'
              )
            "
            >החלה זהה מכאן והלאה</v-btn
          > -->

          <!-- <v-btn
            color="primary"
            text
            @click="
              updateCell(
                changeCell,
                changeMonthlySales,
                currentMonthlySaleSourcesCount,
                'addition'
              )
            "
            >תוספת מכאן והלאה</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["rows", "typeProp", "months", "editbale"],
  components: { apexchart: VueApexCharts },
  data() {
    return {
      //rowsData: [],
      //rowsData: [...this.rows],
      //rowsData: Array.from(this.rows),
      rowsData: this.rows,
      monthsArray: [],

      dialog: false,
      changeCell: 0,
      changeMonthlySales: 0,
      currentMonthlySaleSourcesCount: [],
      currentMonthlySourcesSummary: 0,

      selectedmonth: null,
      selecteditem: null,
      selectedorder: null,

      chartOptionsLine: {
        chart: {
          // height: 1000,
          // type: 'line',
          toolbar: {
            show: false,
          },
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        stroke: {
          width: 7,
          curve: "smooth",
        },
        xaxis: {
          //type: "datetime",
          categories: [
            // "1/11/2000",
            // "2/11/2000",
            // "3/11/2000",
            // "4/11/2000",
            // "5/11/2000",
            // "6/11/2000",
            // "7/11/2000",
            // "8/11/2000",
            // "9/11/2000",
            // "10/11/2000",
            // "11/11/2000",
            // "12/11/2000",
            // "1/11/2001",
            // "2/11/2001",
            // "3/11/2001",
            // "4/11/2001",
            // "5/11/2001",
            // "6/11/2001"
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
          ],
          //tickAmount: 12,
          labels: {
            // formatter: function(value, timestamp, opts) {
            //   return opts.dateFormatter(new Date(timestamp), "dd MMM");
            // }
          },
        },
        // title: {
        //   text: "Social Media",
        //   align: "center",
        //   style: {
        //     fontSize: "16px",
        //     color: "#666"
        //   }
        // },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#FDD835"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 4,
          colors: ["#FFA41B"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        yaxis: {
          decimalsInFloat: 2,
          // min: -10,
          // max: 40,
          // title: {
          //   text: "סכום חודשי"
          // }
        },
      },
      chartOptionsArea: {
        chart: {
          // height: 1000,
          // type: 'line',
          toolbar: {
            show: false,
          },
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        xaxis: {
          //type: "datetime",
          categories: [
            // "1/11/2000",
            // "2/11/2000",
            // "3/11/2000",
            // "4/11/2000",
            // "5/11/2000",
            // "6/11/2000",
            // "7/11/2000",
            // "8/11/2000",
            // "9/11/2000",
            // "10/11/2000",
            // "11/11/2000",
            // "12/11/2000",
            // "1/11/2001",
            // "2/11/2001",
            // "3/11/2001",
            // "4/11/2001",
            // "5/11/2001",
            // "6/11/2001"
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
          ],
          //tickAmount: 12,
          labels: {
            // formatter: function(value, timestamp, opts) {
            //   return opts.dateFormatter(new Date(timestamp), "dd MMM");
            // }
          },
        },
        // title: {
        //   text: "Social Media",
        //   align: "center",
        //   style: {
        //     fontSize: "16px",
        //     color: "#666"
        //   }
        // },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        markers: {
          size: 4,
          colors: ["#FFA41B"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        yaxis: {
          decimalsInFloat: 2,
          // min: -10,
          // max: 40,
          // title: {
          //   text: "סכום חודשי"
          // }
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
  },

  computed: {
    calculateMonths: function() {
      let array = [];
      for (let index = 0; index < this.months; index++) {
        array[index] = 0;
      }
      //let array = this.monthsArray;

      // this.rowsData.forEach(element => {
      //   for (let index = 0; index < this.months; index++) {
      //     if (array[index]) {
      //       array[index] += Number(element.months[index]);
      //     } else {
      //       array.push(Number(element.months[index]));
      //     }
      //   }
      // });

      this.rowsData.forEach((element) => {
        for (let index = 0; index < this.months; index++) {
          array[index] += Number(element.months[index]["monthlyEstimate"]);
        }
      });
      return array;
    },

    monthsForGraph: function() {
      return [
        {
          name: "סכום חודשי",
          data: this.calculateMonths,
        },
      ];
    },

    calculatePeriod: function() {
      return this.calculateMonths.reduce((acc, current) => acc + current, 0);
    },
  },

  methods: {
    openDialog(month, item, order) {
      if (this.editbale) {
        console.log(month);
        console.log(item);
        console.log(order);

        this.selectedmonth = month;
        this.selecteditem = item;
        this.selectedorder = order;

        this.changeCell = this.selecteditem.months[this.selectedmonth][
          "monthlyEstimate"
        ];

        this.changeMonthlySales = this.selecteditem.months[this.selectedmonth][
          "monthlysales"
        ];

        this.calculateSelectedItemSaleSources();
        this.dialog = true;
      }
    },

    updateCell(
      value,
      changeMonthlySales,
      currentMonthlySaleSourcesCount,
      changeType
    ) {
      if (changeType == "equal") {
        let i = this.selectedmonth;
        for (i; i < this.months; i++) {
          //this.rowsData[this.selectedorder][`month${i}`] = Number(value);
          this.$set(
            this.rowsData[this.selectedorder]["months"][i],
            "monthlyEstimate",
            Number(value)
          );
        }
      } else if (changeType == "addition") {
        let i = this.selectedmonth;
        for (i; i < this.months; i++) {
          //this.rowsData[this.selectedorder][`month${i}`] += Number(value);
          let currentValue = Number(
            this.rowsData[this.selectedorder]["months"][i]["monthlyEstimate"]
          );
          this.$set(
            this.rowsData[this.selectedorder]["months"][i],
            "monthlyEstimate",
            Number(currentValue + value)
          );
        }
      } else {
        //this.rowsData[this.selectedorder]['months'][this.selectedmonth] = Number(value);
        this.$set(
          this.rowsData[this.selectedorder]["months"][this.selectedmonth],
          "monthlyEstimate",
          Number(value)
        );
        this.$set(
          this.rowsData[this.selectedorder]["months"][this.selectedmonth],
          "monthlysales",
          Number(changeMonthlySales)
        );

        // sales sources start

        let cloned = JSON.parse(
          JSON.stringify(
            this.rowsData[this.selectedorder]["months"][this.selectedmonth][
              "saleSources"
            ]
          )
        );

        cloned.forEach((element, index) => {
          element["count"] = currentMonthlySaleSourcesCount[index];
        });

        this.$set(
          this.rowsData[this.selectedorder]["months"][this.selectedmonth],
          "saleSources",
          cloned
        );
        // sales sources end
      }
      this.calculateYearly(this.rowsData[this.selectedorder]);

      let payload = {
        data: this.rowsData,
        type: this.typeProp,
      };

      this.$store.commit("updatePlan", payload);
    },
    calculateYearly(item) {
      console.log("calculateYearly");
      console.log(item);
      item.yearlyEstimateMonthlyChange = item["months"].reduce(
        (acc, current) => acc + Number(current["monthlyEstimate"]),
        0
      );
      console.log("After calculateYearly:");
      console.log(item);
    },
    calculateSelectedItemSaleSources: function() {
      if (this.selecteditem.saleSources) {
        this.selecteditem.months[this.selectedmonth]["saleSources"].forEach(
          (element, index) => {
            this.currentMonthlySaleSourcesCount[index] = element.count;
          }
        );
        this.calcCurrentMonthlySourcesSummary();
      }
      // let count = 0;
      // if (this.selecteditem.saleSources) {
      //   count = this.selecteditem.months[this.selectedmonth]["saleSources"].reduce(
      //     (acc, current) => acc + current.count,
      //     0
      //   );
      // }
      // console.log("calculateSelectedItemSaleSources", count);
      // this.currentMonthlySaleSourcesCount = count;
    },
    setItemSourceValue: function(index, value) {
      this.currentMonthlySaleSourcesCount[index] = value;
      this.calcCurrentMonthlySourcesSummary();
    },
    calcCurrentMonthlySourcesSummary: function() {
      let sum = this.currentMonthlySaleSourcesCount.reduce(
        (acc, current) => acc + Number(current),
        0
      );
      this.currentMonthlySourcesSummary = sum;
    },
    deleteRow: function(order) {
      if (confirm("נא לאשר מחיקת שורה זו") == true) {
        this.rowsData.splice(order, 1);
      }
    },
  },
  watch: {
    rows: {
      handler(val) {
        console.log("rows changed");
        console.log(val);
        this.rowsData = this.rows;
      },
      deep: true,
    },
  },
  mounted() {
    console.log("Prop: " + this.typeProp);
    console.log("isInitial: " + this.isInitial);
    //this.rowsData = [...this.rows];
  },
};
</script>

<style>
.table-cell {
  cursor: pointer;
}

.table-cell:hover {
  background-color: #2953e8;
  color: #ffffff;
}

.summary-row {
  background-color: #f5f5f5;
  font-weight: bold;
}

rect {
  fill: #2953e8;
}

td {
  font-size: 0.95rem !important;
}

.input-icon-switch i,
.input-icon-switch button {
  font-size: 16px !important;
}
</style>
