export function createAscendingIntArr(num) {
  return Array.from({ length: num }, (_, i) => i);
}

export function productElformInputsInit() {
  return [
    { price: '', item: '' },
    { price: '', item: '' },
  ];
}
