<template>
  <div class="login pa-6">
    <div class="inner-box">
      <HelloWorld />
      <v-form @submit.prevent="login">
        <v-text-field
          v-model="email"
          label="שם משתמש"
          required
          name="user"
        ></v-text-field>
        <v-text-field
          v-model="password"
          label="סיסמה"
          required
          name="password"
          type="password"
        ></v-text-field>
        <v-btn type="submit" color="#2953e8" class="yazam-button-blue">כניסה</v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";
import { auth } from "../firebase";

export default {
  name: "Login",
  components: {
    HelloWorld,
  },
  data: () => ({
    email: "",
    password: "",
    user: null,
  }),
  methods: {
    login: function () {
      auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          console.log(user);
          this.user = user;
          this.$store.dispatch("setCurrentUser");
          //this.$store.dispatch("setCurrentUserData");
          this.$store.dispatch("loadAllPlans");
          this.$store.dispatch("loadAllBusiness");
          this.$router.push("/");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
/* .v-main {
  background-image: url("https://yazam.app/wp-content/uploads/2022/06/yazam-bg.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 !important;
}

.container {
  padding: 0 !important;
}

.row > .col {
  padding: 0;
}

.login {
  background-color: #ffffff;
  width: 500px;
  height: 100vh;
} */

.login {
  background-image: url("https://yazam.app/wp-content/uploads/2022/06/yazam-bg.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 !important;
  margin-top: -30px;
  margin-block: -25px;
}

.inner-box {
  background-color: #ffffff;
  width: 500px;
  height: 100vh;
  padding: 30px;
}
</style>

