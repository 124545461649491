<template>
  <div class="profile pa-5">
    <v-card class="pa-4" elevation="1">
      <h1>העסקים שלי</h1>

      <v-row v-if="loadedBusiness">
        <v-col cols="3" v-for="item in loadedBusiness" :key="item.id">
          <v-card>
            <v-img src="https://simba-biz.com/wp-content/uploads/2018/04/simba-1.jpg" class="white--text align-end"
              style="cursor: pointer" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="150px"
              @click="loadBusinessData(item.id)">
              <v-card-title>
                {{ item.data.businessname }}
                <v-icon color="white" class="mr-2">mdi-circle-edit-outline</v-icon>
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
      <p v-else>לא נוצרו עסקים בחשבון זה</p>
      <v-btn @click="newBusinessForm" v-if="!isCreatingNewBusiness">יצירת עסק חדש</v-btn>
    </v-card>

    <v-card id="business-edit-form" class="pa-4 mt-6" elevation="1">
      <v-form v-model="valid" ref="businessForm">
        <v-container>
          <!-- <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="firstname"
              :rules="nameRules"
              label="שם מלא"
              required
              name="name"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="phoneNum" label="מספר טלפון" required name="phone"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="email" :rules="emailRules" label="אימייל" required name="email"></v-text-field>
          </v-col>
        </v-row> -->
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="businessname" label="שם העסק" required></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="selectedBusinessType" :items="businessType" label="סוג העסק"></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select v-model="selectedBusinessField" :items="businessField" label="תחום העסק"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select v-model="selectedSalesWay" :items="salesWay" label="ערוץ המכירה"></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-file-input :rules="rulesImg" accept="image/png, image/jpeg, image/bmp" placeholder="העלאת תמונה / לוגו"
                prepend-icon="mdi-camera" label="תמונה ראשית"></v-file-input>
            </v-col>
            <v-col cols="12" md="4">
              <v-select v-model="selectedCurrency" :items="currencyOptions" item-text="title" item-value="value"
                label="בחירת מטבע"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="currentProduct" label="המוצרים שלי" @keyup.enter="addProduct"></v-text-field>
              <v-chip v-for="(prod, i) in products" :key="i" close @click:close="deleteProduct(i)" outlined label
                color="primary" class="ma-1">{{ prod.name }}</v-chip>
              <v-simple-table fixed-header height="400px" class="elevation-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width: 15%">פירוט</th>
                      <th style="width: 10%"></th>
                      <th>עלות לעסק</th>
                      <th>סכום המכירה</th>
                      <th style="width: 15%">סוג המוצר</th>
                      <th>שיעור הרווח</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in products" :key="index">
                      <td class="table-name">{{ item.name }}</td>

                      <td class="table-name">
                        <v-btn color="#2953E8" class="yazam-button-blue" width="50" height="25"
                          @click="handleElementsClick(item, index)">פירוט</v-btn>
                      </td>

                      <td>
                        <!-- @input="inputChanged('cost',item,index,$event)" -->
                        <v-text-field label="הזינו נתון" type="number" v-model.number="item.cost"
                          :append-icon="`mdi-currency-${selectedCurrency}`" dense flat hide-details rounded
                          solo-inverted class="input-icon-switch"></v-text-field>
                      </td>
                      <td>
                        <!-- @input="inputChanged('price',item,index,$event)" -->
                        <v-text-field label="הזינו נתון" type="number" v-model.number="item.price"
                          :append-icon="`mdi-currency-${selectedCurrency}`" dense flat hide-details rounded
                          solo-inverted class="input-icon-switch"></v-text-field>
                      </td>
                      <td>
                        <v-select label="הזינו סוג מוצר" v-model="item.type" :items="productTypes" item-text="title"
                          item-value="value" solo-inverted flat dense rounded hide-details filled></v-select>
                      </td>
                      <td class="d-flex justify-space-between align-center">
                        {{ getProfitRate(item.price, item.cost) }}%
                        <v-btn @click="calcInsights(item.price, item.cost)">חישוב תמחור</v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-btn @click="createNewBusiness" v-if="isCreatingNewBusiness">שמירה ויצירת עסק חדש</v-btn>
          <v-btn @click="updateBusiness" v-else>עדכון נתוני עסק</v-btn>

          <!-- <p>שם העסק תחום מיקום בארץ קהל יעד פוטנציאלי מתחרים ידועים סוג המוצר - פיזי,שירות,תוכנה ערוץ המכירה - וירטואלי, חנות פיזית, גם וגם מודל עסקי - רכישה חד פעמית, מנוי חודשי</p> -->
        </v-container>
      </v-form>
    </v-card>

    <v-dialog v-model="dialog" max-width="30%">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>עדכון נתונים</v-toolbar-title>
        </v-toolbar>
        <v-card-title>{{ selectedItem.name }}</v-card-title>

        <div :style="{ margin: ' 0 2rem' }">
          <v-form @submit.prevent="handleElementsSubmit" ref="elementsForm">

            <v-row v-for="(el, index) in elementsRowsArr" :key="index">
              <v-col cols="12" md="4">
                <v-text-field v-model.number="formInputs[index].item" label="פריט" require name="element"
                  :ref="'elementRef_' + index"></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field v-model.number="formInputs[index].price" name="price" :ref="'priceRef_' + index"
                  type="number" label="מחיר"></v-text-field>
              </v-col>

              <v-col v-if="elementsRowsArr.length > 2" cols="12" md="4">
                <v-btn @click="spliceArr(index)" class="yazam-button-grey" :style="{ marginTop: '10px' }">מחק</v-btn>
              </v-col>
            </v-row>

            <div :style="{ marginBottom: '50px' }">
              <v-btn width="70px" class="yazam-button" @click="addOneToArr">+</v-btn>
            </div>
            <div :style="{ display: 'flex', gap: '15px', marginBottom: '50px' }">
              <h4>סך הכל</h4>
              <p>{{ getElementsTotalPrice() }} ₪</p>
            </div>
            <v-btn type="submit" color="#2953e8" class="yazam-button-blue">אישור</v-btn>
          </v-form>
        </div>
        <v-card-actions>

          <v-spacer></v-spacer>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </div>


</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { businessCollection } from "../firebase";
import { createAscendingIntArr, productElformInputsInit } from "../utils/helperFunctions";



export default {
  name: "Profile",
  components: {
    // HelloWorld
  },
  data: () => ({
    loaded: false,
    //loadedBusiness: [],
    //loadedBusiness: this.$store.state.userBusiness,
    currentBusiness: "",
    isCreatingNewBusiness: false,

    valid: false,
    firstname: "",
    phoneNum: "",
    nameRules: [(v) => !!v || "שם הוא שדה חובה"],
    email: "",
    emailRules: [
      (v) => !!v || "אימייל הוא שדה חובה",
      (v) => /.+@.+/.test(v) || "נא להזין כתובת אימייל תקנית",
    ],
    businessname: "",
    businessType: ["עוסק פטור", "עוסק מורשה", 'חברה בע"מ'],
    selectedBusinessType: "",
    businessField: [
      "כושר",
      "פיננסים",
      "טכנולוגיה",
      "שירותים",
      "תחבורה",
      "אופנה",
      "איקומרס",
      "תוכנה",
      "אחר"
    ],
    selectedBusinessField: "",
    salesWay: ["פיזי", "אונליין", "גם וגם"],
    selectedSalesWay: "",
    rulesImg: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    currencyOptions: [
      {
        title: "שקל",
        value: "ils",
      },
      { title: "דולר אמריקאי", value: "usd" },
    ],
    productTypes: [
      {
        title: "חד פעמי",
        value: "onetime",
      },
      { title: "ריטיינר / מחזורי", value: "subscription" },
    ],
    selectedCurrency: "ils",
    products: [],
    currentProduct: "",
    // --
    dialog: false,
    selectedItem: {},
    productIndex: -1,
    elementsRowsArr: [1, 2],
    formInputs: productElformInputsInit(),
    pricesChanged: '',
    totalPrice: 0,
  }),
  //---------------------------------------------------------
  created: function () {
    console.log("loadAllBusiness From Created ");
    //console.warn(this.$store.state.userBusiness);
    //this.loadAllBusiness();
    //this.loadedBusiness = this.$store.state.userBusiness;
  },

  computed: {
    loadedBusiness: function () {
      return this.$store.state.userBusiness;
    },
    currentBusinessFromStore: function () {
      return this.$store.state.currentBusiness;
    },

  },

  methods: {
    addOneToArr() {
      this.formInputs.push({ price: '', item: '' })
      const arr = this.elementsRowsArr;
      arr.push(arr[arr.length - 1] + 1)
    },

    spliceArr(index) {
      this.formInputs.splice(index, 1)
      this.elementsRowsArr.splice(index, 1)
    },

    initFormInputs() {
      this.formInputs = productElformInputsInit();
      this.elementsRowsArr = [1, 2];
    },

    handleElementsClick(item, index) {
      this.initFormInputs()
      this.productIndex = index;
      this.selectedItem = item;
      console.log('this.selectedItem: ', this.selectedItem) //removeEytan
      this.dialog = true;

      const productHasElements = this.products[index].elements.length;
      if (productHasElements) {
        // put data in form inputs 
        this.formInputs = this.products[index].elements;
        this.elementsRowsArr = createAscendingIntArr(this.formInputs.length);
      }
    },

    handleElementsSubmit(e) {
      const inputs = e.target.elements;

      const textInputsValues = [];
      for (const el in inputs) {
        if (el.includes('input')) {
          textInputsValues.push(inputs[el].value)
        }
      }

      const inputsObjs = [];

      this.elementsRowsArr.forEach((_, i) => {
        const enteredTitle = this.$refs[`elementRef_${i}`][0].$refs.input._value;
        const enteredPrice = this.$refs[`priceRef_${i}`][0].$refs.input._value;

        inputsObjs.push({ item: enteredTitle, price: enteredPrice });
      
      })

      this.formInputs = inputsObjs;
      this.updateProductElements();
      this.dialog = false;
    },

    getElementsTotalPrice() {
      const sum = this.formInputs.map(el => el.price).reduce((prev, cur) => {
        return +prev + +cur;
      }, 0)
      this.totalPrice = sum;
      return sum;
    },
    getProfitRate(price, cost) {
      const profitRate = (((price - cost) / cost) * 100).toFixed(2);
      if (isNaN(profitRate) || profitRate < 0) return 0;
      return profitRate
    },

    async loadAllBusiness() {
      this.loadedBusiness = [];
      const business = await businessCollection
        .where("user", "==", this.$store.state.currentUser)
        .get();

      if (business) {
        business.forEach((doc) => {
          //console.log(doc.id, "=>", doc.data());
          //this.loadedPlans.push(doc.data());

          const docData = {
            id: doc.id,
            data: doc.data(),
          };

          this.loadedBusiness.push(docData);
          this.loaded = true;
        });
      }
      console.log("the business");
      console.log(business);
      this.$store.commit("setUserBusiness");
    },
    async loadBusinessData(id) {
      const business = await businessCollection
        .doc(id)
        .get()
        .then((doc) => doc.data());
      console.log(business);

      if (business) {
        this.businessname = business.businessname;
        this.selectedBusinessType = business.selectedBusinessType;
        this.selectedBusinessField = business.selectedBusinessField;
        this.selectedSalesWay = business.selectedSalesWay;
        this.selectedCurrency = business.selectedCurrency;
        this.products = business.products;
      }

      this.currentBusiness = id;
      this.isCreatingNewBusiness = false;
      this.$vuetify.goTo("#business-edit-form");
      this.$store.commit("setCurrentBusiness", id);
      console.log("loadBusinessData");
    },

    async createNewBusiness() {
      if (this.valid) {
        const newBusiness = await businessCollection.add({
          user: this.$store.state.currentUser,
          businessname: this.businessname,
          selectedBusinessType: this.selectedBusinessType,
          selectedBusinessField: this.selectedBusinessField,
          selectedSalesWay: this.selectedSalesWay,
          selectedCurrency: this.selectedCurrency,
          products: this.products,
        });

        if (newBusiness) {
          // this.businessname = "";
          // this.selectedBusinessType = "";
          // this.selectedBusinessField = "";
          // this.selectedSalesWay = "";
          // this.selectedCurrency = "";

          this.$refs.businessForm.reset();
          this.products = [];
        }

        console.log("newBusiness");
        console.log(newBusiness.id);
        this.currentBusiness = newBusiness.id;
        //this.loadAllBusiness();
        this.$store.dispatch("loadAllBusiness");
      } else {
        console.log("not valid");
      }
    },

    async updateBusiness() {
      if (this.valid && this.currentBusiness) {
        await businessCollection
          .doc(this.currentBusiness)
          .set({
            user: this.$store.state.currentUser,
            businessname: this.businessname,
            selectedBusinessType: this.selectedBusinessType,
            selectedBusinessField: this.selectedBusinessField,
            selectedSalesWay: this.selectedSalesWay,
            selectedCurrency: this.selectedCurrency,
            products: this.products,
          })
          .then(() => {
            console.log(`Business ${this.currentBusiness} Updated`);
          });
        this.updateProducts();
      } else {
        console.log("not valid");
      }
    },

    newBusinessForm() {
      this.$refs.businessForm.reset();
      this.products = [];
      this.isCreatingNewBusiness = true;
    },

    addProduct() {
      if (this.currentProduct.length > 0) {
        this.products.push({
          name: this.currentProduct,
          cost: 0,
          price: 0,
          elements: []
        });
        this.currentProduct = "";
        this.updateProducts();
      }
    },

    updateProductElements() {
      this.products[this.productIndex].elements = this.formInputs;
      this.products[this.productIndex].cost = this.totalPrice;

      // this.currentProduct = "";
      this.updateProducts();
    },

    addDetails() {
      if (this.currentProduct.length > 0) {
        this.products.push({
          name: this.currentProduct,
          cost: 0,
          price: 0,
          elements: []
        });
        // this.currentProduct = "";
        this.updateProducts();
      }
    },

    deleteProduct(i) {
      this.products.splice(i, 1);
      this.updateProducts();
    },

    updateProducts() {
      this.$store.dispatch("setCurrentProducts", this.products);
    },

    inputChanged(type, item, index, e) {
      console.log(type);
      console.log(item);
      console.log(index);
      console.log(e);

      this.products[index][type] = Number(e);
      this.updateProducts();
    },

    calcInsights(itemPrice, itemCost) {
      console.log(itemPrice, itemCost);
      this.$router.push({
        name: "Insights",
        params: { price: itemPrice, cost: itemCost },
      });
    },
  },
  watch: {
    currentBusinessFromStore: function () {
      this.loadBusinessData(this.currentBusinessFromStore);
    },
  },
};
</script>
