<template>
  <v-container class="pa-0">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" editable>הקדמה</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2" editable>הוצאות צפויות</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" editable>הכנסות צפויות</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" class="grey lighten-5">
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="planName" label="שם התכנית"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="selectedBusiness"
                :items="businessOptions"
                item-text="title"
                item-value="value"
                label="שיוך לעסק"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="selectedCurrency"
                :items="currencyOptions"
                item-text="title"
                item-value="value"
                label="בחירת מטבע"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7">
              <v-expansion-panels class="mb-4">
                <v-expansion-panel>
                  <v-expansion-panel-header>איך להשתמש באשף יצירת תחזית עסקית?</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>תוכן תוכן תוכן....</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>איך מזינים הוצאות?</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>תוכן תוכן תוכן....</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>איך מזינים הכנסות?</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>תוכן תוכן תוכן....</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>מה ההבדל בין הוצאות קבועות להוצאות משתנות?</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>תוכן תוכן תוכן....</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>איך מוסיפים שורות לטבלה?</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>תוכן תוכן תוכן....</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>אפשר להגדיר הוצאות / הכנסות ברמה חודשית ולא רק שנתית?</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>תוכן תוכן תוכן....</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>האם ניתן לקבל עזרה של מומחה מבית סימבה?</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>תוכן תוכן תוכן....</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
            <v-col cols="5">
              <iframe
                width="100%"
                height="350"
                src="https://www.youtube.com/embed/x11eg6uxjQE"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </v-col>
          </v-row>
          <v-btn color="primary" @click="e1 = 2">המשך</v-btn>

          <v-btn text>ביטול</v-btn>
        </v-stepper-content>

        <v-stepper-content step="2" class="grey lighten-5">
          <v-container>
            <h2 class="mb-4">הזנת נתונים</h2>
            <v-expansion-panels class="mb-4">
              <v-expansion-panel>
                <v-expansion-panel-header>הוצאות הקמה</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Table
                    :rows="expenses.initial"
                    typeProp="initial"
                    :currency="selectedCurrency"
                    :months="months"
                    @dataUpdate="dataUpdate"
                  />
                  <v-btn @click="addRow('initial')" class="add-row">הוספת שורת הוצאות חדשה</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>הוצאות קבועות</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Table
                    :rows="expenses.fixed"
                    typeProp="fixed"
                    :currency="selectedCurrency"
                    :months="months"
                    @dataUpdate="dataUpdate"
                  />
                  <v-btn @click="addRow('fixed')" class="add-row">הוספת שורת הוצאות חדשה</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>הוצאות משתנות</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Table
                    :rows="expenses.dynamic"
                    typeProp="dynamic"
                    :currency="selectedCurrency"
                    :months="months"
                    @dataUpdate="dataUpdate"
                  />
                  <v-btn @click="addRow('dynamic')" class="add-row">הוספת שורת הוצאות חדשה</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <h2 class="mb-4">צפייה בנתונים ודיוקם</h2>
            <!-- <v-btn @click="showReview = !showReview">הצגת נתונים</v-btn> -->

            <ReviewTable :rows="fixedRowsData" :months="months" class="my-4" />
            <ReviewTable :rows="dynamicRowsData" :months="months" class="my-4" />
         
            <Graphs
              :initialRowsData="initialRowsData"
              :fixedRowsData="fixedRowsData"
              :dynamicRowsData="dynamicRowsData"
              typeText="הוצאות"
            />

            <v-btn color="primary" @click="saveData">המשך</v-btn>
            <!-- <v-btn color="primary" @click="saveData" class="mr-2">שמירה</v-btn> -->

            <v-btn text>ביטול</v-btn>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="3" class="grey lighten-5">
          <v-container>
            <h2 class="mb-4">הזנת נתונים</h2>
            <v-expansion-panels class="mb-4">
              <v-expansion-panel>
                <v-expansion-panel-header>הכנסות ראשוניות (חד פעמיות)</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Table :rows="income.initial" typeProp="Initial" @dataUpdate="incomeDataUpdate" />
                  <v-btn @click="incomeAddRow('initial')" class="add-row">הוספת שורת הוצאות חדשה</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>הכנסות קבועות</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Table :rows="income.fixed" typeProp="Fixed" @dataUpdate="incomeDataUpdate" />
                  <v-btn @click="incomeAddRow('fixed')" class="add-row">הוספת שורת הכנסות חדשה</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>הכנסות משתנות</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <Table :rows="income.dynamic" typeProp="Dynamic" @dataUpdate="incomeDataUpdate" />
                  <v-btn @click="incomeAddRow('dynamic')" class="add-row">הוספת שורת הכנסות חדשה</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <!-- :initialRowsData="initialRowsData" -->
            <!-- <Graphs
              :initialRowsData="incomeInitialRowsData"
              :fixedRowsData="incomeFixedRowsData"
              :dynamicRowsData="incomeDynamicRowsData"
              typeText="הכנסות"
            /> -->

            <!-- <v-btn color="primary" @click="e1 = 1">המשך</v-btn> -->
            <v-btn color="primary" @click="saveIncomeData" class="mr-2">שמירה וסיום</v-btn>

            <v-btn text>ביטול</v-btn>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import Table from "@/components/TableCopy.vue";
import Graphs from "@/components/GraphsCopy.vue";
import ReviewTable from "@/components/ReviewTable.vue";
import { plansCollection } from "../firebase";

export default {
  name: "Wizard",
  components: { Table, Graphs, ReviewTable },
  data: () => ({
    e1: 1,
    //e6: 1,
    //items: ["טכנולוגיה", "פיננסים", "לוגיסטיקה"],
    //enabled: false,
    //enabled1: false,

    fields: [
      "בידור",
      "ביטוח",
      "טכנולוגיה",
      "ייעוץ עסקי",
      "כושר",
      "לוגיסטיקה",
      "מזון",
      "פיננסים",
      "שיווק",
      "שירותים",
      "תוכנה",
      "תעופה",
      "תקשורת"
    ],
    expenses: {
      initial: [
        "אחסון לוגיסטי",
        "אתר אינטרנט",
        "הוצאות משפטיות",
        "הוצאות עסקיות",
        "כתיבת תוכן",
        "מחשוב",
        "מיתוג",
        "סקרי שוק ומחקרים",
        "פטנטים",
        "שירותי עיצוב",
        "שלטים",
        "שרתים"
      ],
      fixed: [
        "ארנונה",
        "ביטוח לעסק",
        "גז",
        "חשמל",
        "מחשוב (שירותי IT)",
        "מים",
        "מערכת CRM",
        "משפטי (ליווי משפטי)",
        "סליקה",
        "עובדים (משכורות)",
        "רואה חשבון (הנהלת חשבונות, שליחת דוחות וכו׳)",
        "רכב (דלק, ביטוח ופחת)",
        "שכירות משרד/חנות",
        "שרתים"
      ],
      dynamic: [
        "מחקר",
        "נסיעות",
        "עמלות",
        "פיתוח עסקי",
        "שיווק ופרסום",
        "שירותי ייעוץ"
      ]
    },
    income: {
      initial: ["הלוואה", "השקעה", "מימון"],
      fixed: [
        "דמי ניהול",
        "מכירת מוצרים",
        "מכירת שירותים",
        "עמלות",
        "תשואה חודשית"
      ],
      dynamic: [
        "דמי ניהול",
        "מכירת מוצרים",
        "מכירת שירותים",
        "עמלות",
        "תשואה חודשית"
      ]
    },
    //rowsData: {},
    //rowsData: [],
    initialRowsData: [],
    fixedRowsData: [],
    dynamicRowsData: [],

    incomeInitialRowsData: [],
    incomeFixedRowsData: [],
    incomeDynamicRowsData: [],

    months: 12,
    showReview: false,

    planName: "",
    businessOptions: [
      {
        title: "העסק של רועי",
        value: "123456789"
      }
    ],
    currencyOptions: [
      {
        title: "שקל",
        value: "ils"
      },
      { title: "דולר אמריקאי", value: "usd" }
    ],
    selectedBusiness: null,
    selectedCurrency: "ils",

    currentPlanID: null
  }),
  methods: {
    dataUpdate(Data, typeProp) {
      //this.rowsData[typeProp] = Data;
      //this.rowsData = Data;
      this[typeProp + "RowsData"] = Data;
      //let thisData = this[typeProp + "RowsData"];

      let payload = {
        data: Data,
        type: typeProp
      }

      this.$store.commit("updatePlan", payload);
      //console.log(Data);
      console.log("typeProp: " + typeProp);
    },
    incomeDataUpdate(Data, typeProp) {
      this["income" + typeProp + "RowsData"] = Data;
      console.log("income typeProp: " + typeProp);
    },
    addRow(type) {
      let name = prompt("הזינו שם שורה");
      if (name) {
        this.expenses[type].push(name);
      }
    },
    incomeAddRow(type) {
      let name = prompt("הזינו שם שורה");
      if (name) {
        this.income[type].push(name);
      }
    },
    async saveData() {
      const savedPlan = await plansCollection.add({
        planName: this.planName,
        user: this.$store.state.currentUser,
        expenses: {
          initialRowsData: this.initialRowsData,
          fixedRowsData: this.fixedRowsData,
          dynamicRowsData: this.dynamicRowsData
        }
      });

      console.log("savedPlan");
      console.log(savedPlan.id);
      this.currentPlanID = savedPlan.id;
      this.e1++;
    },
    async saveIncomeData() {
      await plansCollection
        .doc(this.currentPlanID)
        .update({
          income: {
            initialRowsData: this.incomeInitialRowsData,
            fixedRowsData: this.incomeFixedRowsData,
            dynamicRowsData: this.incomeDynamicRowsData
          }
        })
        .then(console.log("Saved Income"));

      this.$router.push("/plans/" + this.currentPlanID);
    }
  }
};
</script>

<style scoped>
.v-stepper--vertical .v-stepper__content {
  padding: 24px 24px 16px 60px;
}

button.v-expansion-panel-header {
  font-size: 16px;
}

button.add-row {
  margin-top: 10px;
  font-size: 16px;
  letter-spacing: 0px;
  background-color: #2953E8 !important;
}
</style>
