<template>
  <div>
    <v-alert type="error" v-if="!isSaleSourcesExist"
      >לא מוגדרים מקורות הכנסה, יש להגדירם בשלב "שיווק ומכירות" בשביל לערוך שלב
      זה.</v-alert
    >
    <v-simple-table dense fixed-header :height="isDynamic ? '380px' : '500px'">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 20%">פירוט</th>
            <th style="width: 15%">עלות לעסק</th>
            <th style="width: 15%">מחיר ללקוח</th>
            <th style="width: 15%">מספר מכירות חודשי</th>
            <!-- <th>אחוז שינוי חודשי</th> -->
            <th>מקורות הכנסה</th>
            <!-- <th style="width: 15%">חודש פעילות להתחלה</th> -->
            <th style="width: 20%">מבט שנתי / תקופתי</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in rows" :key="index" style="height: 50px">
            <!-- @click="currentRename = item" class="table-name" -->
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" dark v-bind="attrs" v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span>הסבר קצרצר</span>
              </v-tooltip>
              {{ item.name }}
              <v-text-field
                :value="item.name"
                :id="item"
                append-icon="mdi-content-save"
                @click:append="updateRename(item)"
                v-if="currentRename == item"
                dense
                flat
                hide-details
                rounded
                solo-inverted
                class="input-icon-switch my-1"
              ></v-text-field>
            </td>
            <td>{{ item.cost }}</td>
            <td>{{ item.price }}</td>
            <td>
              <v-text-field
                label="0"
                type="number"
                :disabled="!isSaleSourcesExist"
                @input="inputChanged('monthlysales', item, $event)"
                dense
                flat
                hide-details="auto"
                rounded
                solo-inverted
                class="input-icon-switch"
              ></v-text-field>
            </td>
            <td>
              <v-btn
                v-if="indexInRowsData(item) != -1"
                color="primary"
                class="yazam-button-blue"
                @click="openDialog(item, index)"
                >לסיווג ההכנסה</v-btn
              >
            </td>
            <!-- Monthly Change - reduced -->
            <!-- <td>
            <v-text-field
              label="0"
              type="number"
              @input="inputChanged('monthlychange', item, $event)"
              dense
              flat
              hide-details="auto"
              rounded
              solo-inverted
              class="input-icon-switch"
            ></v-text-field>
          </td> -->
            <!-- <td>
            <v-text-field
              label="1"
              type="number"
              @input="inputChanged('startMonth',item,$event)"
              :rules="inputRules"
              :max="months"
              min="1"
              dense
              flat
              hide-details="auto"
              rounded
              solo-inverted
              class="input-icon-switch"
            ></v-text-field>
          </td> -->
            <td>
              <!-- <v-expand-transition>
              <div v-if="indexInRowsData(item) != -1" class="pa-2">
                <v-sparkline
                  :value="[rowsData[indexInRowsData(item)]['minimum'],rowsData[indexInRowsData(item)]['yearly'],rowsData[indexInRowsData(item)]['maximum']]"
                  label-size="15"
                  line-width="30"
                  height="80"
                  type="bar"
                >
                  <template v-slot:label="data">{{ Number(data.value).toLocaleString() }} ₪</template>
                </v-sparkline>
              </div>
            </v-expand-transition>-->
              <div
                v-if="indexInRowsData(item) != -1"
                class="d-flex justify-space-between align-center"
              >
                <div>
                  מחזור שנתי:

                  {{
                    Number(
                      rowsData[indexInRowsData(item)]["yearlyEstimate"]
                    ).toLocaleString()
                  }}
                  <br />
                  נטו שנתי:

                  {{
                    Number(
                      rowsData[indexInRowsData(item)]["yearlyNet"]
                    ).toLocaleString()
                  }}
                  <br />
                  מחזור חודשי:

                  {{
                    Number(
                      rowsData[indexInRowsData(item)]["monthlyEstimate"]
                    ).toLocaleString()
                  }}
                  <br />
                  נטו חודשי:

                  {{
                    Number(
                      rowsData[indexInRowsData(item)]["monthlyNet"]
                    ).toLocaleString()
                  }}
                </div>
                <v-chip color="primary" class="mr-2 percent-ltr"
                  >{{
                    (
                      (rowsData[indexInRowsData(item)]["yearlyEstimate"] /
                        allValuesSummed) *
                      100
                    ).toFixed(2)
                  }}%</v-chip
                >
              </div>
              <!-- <p v-else>Empty</p> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialog" max-width="30%">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title v-if="dialog"
            >סיווג מקורות הכנסה - {{ selecteditem.name }}</v-toolbar-title
          >
        </v-toolbar>
        <v-card-title v-if="dialog">
          סיווג {{ rowsData[indexInRowsData(selecteditem)]["monthlysales"] }}
          מכירות חודשיות לפי מקורות:
        </v-card-title>

        <v-card-text v-if="dialog">
          <ul class="mr-4 mb-4 black--text">
            <li v-for="(item, i) in $store.state.tempsaleSources" :key="i">
              <span style="font-weight: bold">{{ item.name }} </span>
              (עלות למכירה: {{ item.cost }}):
              <v-text-field
                type="number"
                @input="
                  inputChanged('saleSources', selecteditem, {
                    index: i,
                    item: item.name,
                    cost: item.cost,
                    count: Number($event),
                  })
                "
                label="הזנת כמות מכירות חודשית"
                :value="
                  currentSalesSourcesData.length > 0 &&
                  currentSalesSourcesData[i]
                    ? currentSalesSourcesData[i]['count']
                    : 0
                "
              ></v-text-field>
            </li>
          </ul>
          הוגדרו {{ currentItemSalesSourcesCount }} מכירות מתוך
          {{ rowsData[indexInRowsData(selecteditem)]["monthlysales"] }}
          <v-alert
            v-show="
              currentItemSalesSourcesCount > 0 &&
              currentItemSalesSourcesCount !=
                rowsData[indexInRowsData(selecteditem)]['monthlysales']
            "
            type="error"
            class="mt-2"
            >סיווג המקורות צריך להיות זהה לכמות המכירות החודשית</v-alert
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="dialog = false"
            >שמירה ויציאה</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: ["rows", "typeProp", "currency", "months"],
  data() {
    return {
      rowsData: [],
      inputRules: [
        (v) => (v && v >= 1) || "ערך תקין: 1 ומעלה",
        (v) => (v && v <= this.months) || "ערך תקין: " + this.months + " ומטה",
      ],
      currentRename: null,
      dialog: false,
      selecteditem: null,
      currentItemSalesSourcesCount: 0,
      currentSalesSourcesData: null,
    };
  },

  computed: {
    isInitial() {
      return this.typeProp == "initial" || this.typeProp == "Initial";
    },
    isDynamic() {
      return this.typeProp == "dynamic" || this.typeProp == "Dynamic";
    },
    allValuesSummed() {
      return this.rowsData.reduce(
        (acc, current) => acc + Number(current["yearlyEstimate"]),
        0
      );
    },
    isSaleSourcesExist() {
      return Object.keys(this.$store.state.tempsaleSources).length === 0
        ? false
        : true;
    },
    isPlanInEdit: function () {
      if (Object.keys(this.$store.state.editedPlanData).length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    inputChanged(type, item, e) {
      console.log(type);
      console.log(item);
      console.log(e);

      let pos = this.indexInRowsData(item);
      let current = this.rowsData[pos];

      if (pos == -1) {
        let saleSourcesStructure = [];
        this.$store.state.tempsaleSources.forEach((item, i) => {
          saleSourcesStructure.push({
            index: i,
            item: item.name,
            cost: item.cost,
            count: 0,
          });
        });

        this.rowsData.push({
          item: item,
          [type]: e,
          yearlyEstimate: 0,
          yearly: 0,
          months: [],
          // saleSources: [],
          saleSources: saleSourcesStructure,
          //minimum: 0,
          //maximum: 0
        });
        current = this.rowsData[this.rowsData.length - 1];
      } else {
        if (type == "saleSources") {
          current[type][e.index] = e;
          this.calculateSaleSources();
          // current[type] = {
          //   [e.index]: e
          // }
        } else {
          current[type] = e;
        }
      }
      this.calculateYearly(current);
      this.manipulateMonths(current);
      this.$emit("dataUpdate", this.rowsData, this.typeProp);
    },

    calculateYearly(current) {
      if (current["item"]) {
        current["monthlyEstimate"] =
          current["item"]["price"] * current["monthlysales"];

        current["yearlyEstimate"] = current["monthlyEstimate"] * 12;

        // current["yearlyEstimateMonthlyChange"] = current["months"].reduce((acc, currentMonth) => acc + Number(currentMonth["monthlyEstimate"]), 0);

        current["monthlyNet"] =
          (current["item"]["price"] - current["item"]["cost"]) *
          current["monthlysales"];

        current["yearlyNet"] = current["monthlyNet"] * 12;
      }
    },
    manipulateMonths(current) {
      if (current["monthlysales"]) {
        if (current["monthlychange"]) {
          let sum;
          let lastValue;
          let firstValue = {
            monthlyEstimate: current["item"]["price"] * current["monthlysales"],
            monthlyNet: 0,
            monthlysales: current["monthlysales"],
          };
          for (let index = 0; index < this.months; index++) {
            if (index == 0) {
              let monthly = {
                monthlyEstimate: current["monthlyEstimate"],
                monthlyNet: current["monthlyNet"],
                monthlysales: current["monthlysales"],
              };
              // console.log("manipulateMonths > monthly index 0: " + monthly.monthlyEstimate + "Net: " + monthly.monthlyNet)
              this.$set(current.months, index, firstValue);
              // console.log(current.months);
              lastValue = monthly;
              sum = monthly;
            } else {
              let monthly = {
                monthlyEstimate:
                  lastValue["monthlyEstimate"] *
                  (current["monthlychange"] / 100 + 1),
                monthlyNet:
                  lastValue["monthlyNet"] *
                  (current["monthlychange"] / 100 + 1),
                monthlysales: lastValue["monthlysales"],
              };
              lastValue = monthly;
              sum["monthlyEstimate"] += monthly["monthlyEstimate"];
              sum["monthlyNet"] += monthly["monthlyNet"];
              this.$set(current.months, index, monthly);
            }
          }
          current["yearlyEstimateMonthlyChange"] = sum["monthlyEstimate"];
          current["yearlyNetMonthlyChange"] = sum["monthlyNet"];
        } else {
          for (let index = 0; index < this.months; index++) {
            let monthly = {
              monthlyEstimate: current["monthlyEstimate"],
              monthlyNet: current["monthlyNet"],
              monthlysales: current["monthlysales"],
              saleSources: current["saleSources"],
            };
            this.$set(current.months, index, monthly);
          }
        }
      }
    },
    replaceName(item) {
      this.currentRename = item;

      // if (this.currentRename == item) {
      //   this.currentRename = null;
      // } else {
      //   this.currentRename = item;
      // }
    },
    updateRename(item) {
      //console.log(item);
      let value = document.getElementById(item).value;
      //console.log(value);
      this.$emit("rowsUpdate", this.typeProp, item, value);
      this.currentRename = null;
    },

    // OLDcalculateYearly(current) {
    //   if (current["expense"]) {
    //     let yearly = this.isInitial
    //       ? current["expense"] * 1
    //       : current["expense"] * 12;
    //     current["yearly"] = yearly;
    //     current["minimum"] = yearly;
    //     current["maximum"] = yearly;
    //   }

    //   if (current["startMonth"] && current["yearly"]) {
    //     current["minimum"] =
    //       current["yearly"] - current["yearly"] * (current["deviation"] / 100);
    //     current["maximum"] =
    //       current["yearly"] + current["yearly"] * (current["deviation"] / 100);
    //   }
    // },

    indexInRowsData(item) {
      return this.rowsData.map((e) => e.item).indexOf(item);
    },
    test(e) {
      console.log(e);
    },
    openDialog(item, index) {
      console.log(item);
      console.log(index);

      this.selecteditem = item;
      this.calculateSaleSources();
      let currentindexInRowsData = this.indexInRowsData(this.selecteditem);
      this.currentSalesSourcesData =
        this.rowsData[currentindexInRowsData]["saleSources"];
      console.log("currentSalesSourcesData:", this.currentSalesSourcesData);

      this.dialog = true;
    },

    calculateSaleSources() {
      let count = 0;
      this.currentItemSalesSourcesCount = 0;
      let currentindexInRowsData = this.indexInRowsData(this.selecteditem);
      this.rowsData[currentindexInRowsData]["saleSources"].forEach(
        (element) => {
          count += Number(element.count);
        }
      );
      this.currentItemSalesSourcesCount = count;
      console.log(count);
    },
    updateDataFromStoreEditMode() {
      console.log("updateDataFromStoreEditMode ran from TableIncome");
      let editedPlan = this.$store.state.editedPlanData;

      this.rowsData = editedPlan?.income?.productsRowsData ? editedPlan?.income?.productsRowsData : [];
    },
  },
  created: function () {
    if (this.isPlanInEdit) {
      this.updateDataFromStoreEditMode();
    }
  },
  mounted() {
    console.log("Prop: " + this.typeProp);
    console.log("isInitial: " + this.isInitial);
  },
  watch: {
    isPlanInEdit: function (value) {
      console.log("watched isPlanInEdit from TableIncome: ", value);
      if (value) {
        this.updateDataFromStoreEditMode();
      }
    },
  },
};
</script>

<style>
rect {
  fill: #2953e8;
}

td {
  font-size: 0.95rem !important;
}

.table-name {
  cursor: pointer;
}

/* .table-name:hover {
  background-color: #c1a97d;
  color: #ffffff;
} */

.input-icon-switch i,
.input-icon-switch button {
  font-size: 16px !important;
}
</style>