<template>
  <v-container>
    <!-- <h2 class="mb-4">סיכום ביניים</h2> -->

    <!-- <v-simple-table class="mb-4 final-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 25%">פירוט</th>
            <th style="width: 25%">תרחיש שנתי</th>
            <th style="width: 25%">תרחיש שנתי מינימלי</th>
            <th style="width: 25%">תרחיש שנתי מקסימלי</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{typeText}} {{typeText == 'הוצאות' ? 'הקמה' : 'ראשוניות (חד פעמיות)'}}</td>
            <td>{{ calcInitial.toLocaleString() }}</td>
            <td>---</td>
            <td>---</td>
          </tr>
          <tr>
            <td>{{typeText}} קבועות</td>
            <td>{{ calcFixedRange['yearly'].toLocaleString() }}</td>
            <td>{{ calcFixedRange['minimum'].toLocaleString() }}</td>
            <td>{{ calcFixedRange['maximum'].toLocaleString() }}</td>
          </tr>
          <tr>
            <td>{{typeText}} משתנות</td>
            <td>{{ calcDynamicRange['yearly'].toLocaleString() }}</td>
            <td>{{ calcDynamicRange['minimum'].toLocaleString() }}</td>
            <td>{{ calcDynamicRange['maximum'].toLocaleString() }}</td>
          </tr>
          <tr>
            <td>כל ה{{typeText}}</td>
            <td>{{ calcAll['yearly'].toLocaleString() }}</td>
            <td>{{ calcAll['minimum'].toLocaleString() }}</td>
            <td>{{ calcAll['maximum'].toLocaleString() }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>-->

    <!-- <p>{{expenses}}</p> -->

    <v-card class="pa-4" elevation="1">
      <v-simple-table class="mb-4 final-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th style="width: 25%">פירוט</th>
              <th style="width: 25%">תרחיש שנתי</th>
              <th style="width: 25%">תרחיש שנתי מינימלי</th>
              <th style="width: 25%">תרחיש שנתי מקסימלי</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>הכנסות</td>
              <td>{{ calcIncome["yearly"].toLocaleString() }}</td>
              <td>{{ calcIncomeRange["minimum"].toLocaleString() }}</td>
              <td>{{ calcIncomeRange["maximum"].toLocaleString() }}</td>
            </tr>
            
            <tr>
              <td>עלות המכר</td>
              <td>{{ salesCost.toLocaleString() }}</td>
              <td>{{ salesCost.toLocaleString() }}</td>
              <td>{{ salesCost.toLocaleString() }}</td>
            </tr>
            <tr>
              <td>רווח גולמי</td>
              <td>{{ (calcIncome["yearly"]-salesCost).toLocaleString() }}</td>
              <td>{{ (calcIncomeRange["minimum"]-salesCost).toLocaleString() }}</td>
              <td>{{ (calcIncomeRange["maximum"]-salesCost).toLocaleString() }}</td>
            </tr>
            <tr>
              <td>הוצאות</td>
              <td>{{ calcExpenses["yearly"].toLocaleString() }}</td>
              <td>{{ calcExpensesRange["minimum"].toLocaleString() }}</td>
              <td>{{ calcExpensesRange["maximum"].toLocaleString() }}</td>
            </tr>
            <tr>
              <td>רווח תפעולי</td>
              <td>
                {{ calcNet["yearly"].toLocaleString() }}
                <v-chip
                  :color="calcNet['yearly'] > 0 ? '#008000' : 'red'"
                  class="mr-2 percent-ltr white--text"
                  >{{
                    ((calcNet["yearly"] / calcIncome["yearly"]) * 100).toFixed(
                      2
                    )
                  }}%</v-chip
                >
              </td>
              <td>
                {{ calcNet["minimum"].toLocaleString() }}
                <v-chip
                  :color="calcNet['minimum'] > 0 ? '#008000' : 'red'"
                  class="mr-2 percent-ltr white--text"
                  >{{
                    (
                      (calcNet["minimum"] / calcIncomeRange["minimum"]) *
                      100
                    ).toFixed(2)
                  }}%</v-chip
                >
              </td>
              <td>
                {{ calcNet["maximum"].toLocaleString() }}
                <v-chip
                  :color="calcNet['maximum'] > 0 ? '#008000' : 'red'"
                  class="mr-2 percent-ltr white--text"
                  >{{
                    (
                      (calcNet["maximum"] / calcIncomeRange["maximum"]) *
                      100
                    ).toFixed(2)
                  }}%</v-chip
                >
              </td>
            </tr>
            <tr>
              <td>משיכת משכורת בעלים</td>
              <td>{{ (ownerSalary * 12).toLocaleString() }}</td>
              <td>{{ (ownerSalary * 12).toLocaleString() }}</td>
              <td>{{ (ownerSalary * 12).toLocaleString() }}</td>
            </tr>
            <tr>
              <td>מס הכנסה</td>
              <td>
                {{ ((calcNet["yearly"] * taxRate) / 100).toLocaleString() }}
              </td>
              <td>
                {{ ((calcNet["minimum"] * taxRate) / 100).toLocaleString() }}
              </td>
              <td>
                {{ ((calcNet["maximum"] * taxRate) / 100).toLocaleString() }}
              </td>
            </tr>
            <!-- <tr>
              <td>ביטוח לאומי</td>
              <td>---</td>
              <td>---</td>
              <td>---</td>
            </tr> -->
            <tr>
              <td>רווח נקי</td>
              <td>
                {{
                  (
                    calcNet["yearly"] -
                    (calcNet["yearly"] * taxRate) / 100
                  ).toLocaleString()
                }}
              </td>
              <td>
                {{
                  (
                    calcNet["minimum"] -
                    (calcNet["minimum"] * taxRate) / 100
                  ).toLocaleString()
                }}
              </td>
              <td>
                {{
                  (
                    calcNet["maximum"] -
                    (calcNet["maximum"] * taxRate) / 100
                  ).toLocaleString()
                }}
              </td>
            </tr>
            <tr>
              <td>יתרה שנתית</td>
              <td>
                {{
                  (
                    calcNet["yearly"] -
                    (calcNet["yearly"] * taxRate) / 100 -
                    ownerSalary * 12
                  ).toLocaleString()
                }}
              </td>
              <td>
                {{
                  (
                    calcNet["minimum"] -
                    (calcNet["minimum"] * taxRate) / 100 -
                    ownerSalary * 12
                  ).toLocaleString()
                }}
              </td>
              <td>
                {{
                  (
                    calcNet["maximum"] -
                    (calcNet["maximum"] * taxRate) / 100 -
                    ownerSalary * 12
                  ).toLocaleString()
                }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <h3 class="text-center my-4">בדיקת תרחישים שנתיים</h3>
      <v-row justify="center">
        <v-col cols="3">
          <v-text-field
            type="number"
            label="בדיקת שינוי אחוז הכנסות"
            v-model.number="changePercentage"
            dense
            flat
            hide-details
            rounded
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            type="number"
            label="בדיקת שינוי אחוז הוצאות"
            v-model.number="changeExpensesPercentage"
            dense
            flat
            hide-details
            rounded
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            type="number"
            label="אחוז מס"
            v-model.number="taxRate"
            dense
            flat
            hide-details
            rounded
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            type="number"
            label="משכורת בעלים חודשית"
            v-model.number="ownerSalary"
            dense
            flat
            hide-details
            rounded
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-3">
      <v-col cols="4">
        <v-card class="pa-3">
          <h3 class="text-center mb-4">תרחיש שנתי מינימלי</h3>
          <!-- <apexchart
          width="500"
          type="donut"
          :options="donutOptions"
          :series="[calcIncome['yearly'],calcExpenses['yearly']]"
          ></apexchart>-->
          <!-- <apexchart
            type="radialBar"
            height="350"
            :options="chartOptions"
            :series="[(calcNet['minimum'] / calcIncomeRange['minimum']) * 100]"
          ></apexchart> -->

          <apexchart
            width="450"
            type="donut"
            :options="donutOptions"
            :series="[calcIncomeRange.minimum, calcExpensesRange.minimum]"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3">
          <h3 class="text-center mb-4">תרחיש שנתי</h3>
          <!-- <apexchart
          width="500"
          type="donut"
          :options="donutOptions"
          :series="[calcIncome['yearly'],calcExpenses['yearly']]"
          ></apexchart>-->
          <!-- <apexchart
            type="radialBar"
            height="350"
            :options="chartOptions"
            :series="[(calcNet['yearly'] / calcIncome['yearly']) * 100]"
          ></apexchart> -->
          <apexchart
            width="450"
            type="donut"
            :options="donutOptionsYearly"
            :series="[calcIncome.yearly, calcExpenses.yearly]"
          ></apexchart>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3">
          <h3 class="text-center mb-4">תרחיש שנתי מקסימלי</h3>
          <!-- <apexchart
          width="500"
          type="donut"
          :options="donutOptions"
          :series="[calcIncome['yearly'],calcExpenses['yearly']]"
          ></apexchart>-->
          <!-- <apexchart
            type="radialBar"
            height="350"
            :options="chartOptions"
            :series="[(calcNet['maximum'] / calcIncomeRange['maximum']) * 100]"
          ></apexchart> -->
          <apexchart
            width="450"
            type="donut"
            :options="donutOptions"
            :series="[calcIncomeRange.maximum, calcExpensesRange.maximum]"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row>
    <!-- not relevant anymore -->
    <!-- <v-row>
      <v-col cols="8">
        <v-card class="pa-3">
          <h3 class="mb-4">החזר השקעה לאחר</h3>
          <v-simple-table class="mb-4 final-table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="width: 25%">פירוט</th>
                  <th style="width: 25%">תרחיש שנתי</th>
                  <th style="width: 25%">תרחיש שנתי מינימלי</th>
                  <th style="width: 25%">תרחיש שנתי מקסימלי</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>הכנסות שנתיות</td>
                  <td>{{ calcIncome["yearly"].toLocaleString() }}</td>
                  <td>{{ calcIncomeRange["minimum"].toLocaleString() }}</td>
                  <td>{{ calcIncomeRange["maximum"].toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>הוצאות הקמה</td>
                  <td>{{ calcExpenses["initialOnly"].toLocaleString() }}</td>
                  <td>{{ calcExpenses["initialOnly"].toLocaleString() }}</td>
                  <td>{{ calcExpenses["initialOnly"].toLocaleString() }}</td>
                </tr>
                <tr>
                  <td>מספר חודשים להחזר</td>
                  <td>---</td>
                  <td>---</td>
                  <td>---</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div class="d-flex my-1">
            <v-icon large color="green darken-2" class="ml-2">
              mdi-check
            </v-icon>
            <div>
              <h3>נקודת איזון</h3>
              <p class="mb-n2">
                כל עוד ההכנסה השנתית עולה על
                {{ calcExpenses["yearly"].toLocaleString() }}
                העסק רווחי
              </p>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="pa-3">
          <h3 class="mb-4">הכנסות מול הוצאות</h3>
          <apexchart
            width="400"
            type="donut"
            :options="donutOptions"
            :series="[calcIncome['yearly'], calcExpenses['yearly']]"
          ></apexchart>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GraphsSinglePlan",
  //props: ["initialRowsData", "fixedRowsData", "dynamicRowsData", "typeText"],
  props: ["expenses", "income","salesCost"],
  components: { apexchart: VueApexCharts },
  data: () => ({
    changePercentage: 10,
    changeExpensesPercentage: null,
    taxRate: 23,
    ownerSalary: 15000,
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
      },
      xaxis: {
        categories: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
        ],
      },
      yaxis: {
        labels: {
          align: "center",
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
      legend: {
        fontSize: "16px",
      },
    },
    series: [
      {
        name: "series-1",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
      {
        name: "series-2",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      },
    ],

    chartOptions: {
      chart: {
        height: 350,
        type: "radialBar",
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function(val) {
                return val.toFixed(2) + "%";
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["אחוז רווחיות שנתי"],
    },

    donutOptions: {
      labels: ["הכנסות", "הוצאות"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                fontSize: "16px",
              },
              value: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                formatter: (value) => {
                  return Number(value).toLocaleString();
                },
              },
            },
          },
        },
      },
      legend: {
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        fontSize: "16px",
        position: "bottom",
      },
      colors: ["#008000", "#9F000F"],
      dataLabels: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
      },
      tooltip: {
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        y: {
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
    },
    donutOptionsYearly: {
      labels: ["הכנסות", "הוצאות"],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                fontSize: "16px",
              },
              value: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                formatter: (value) => {
                  return Number(value).toLocaleString();
                },
              },
            },
          },
        },
      },
      legend: {
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        fontSize: "16px",
        position: "bottom",
      },
      colors: ["#008000", "#9F000F"],
      dataLabels: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
      },
      tooltip: {
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        y: {
          formatter: (value) => {
            return Number(value).toLocaleString();
          },
        },
      },
    },
  }),
  computed: {
    // calcInitial: function () {

    //   return this.expenses.initialRowsData.reduce(
    //     (acc, current) => acc + Number(current["yearly"]),
    //     0
    //   );
    // },
    // calcFixed: function () {

    //   return this.expenses.fixedRowsData.reduce(
    //     (acc, current) => acc + Number(current["yearly"]),
    //     0
    //   );
    // },
    // calcDynamic: function () {

    //   return this.expenses.dynamicRowsData.reduce(
    //     (acc, current) => acc + Number(current["yearly"]),
    //     0
    //   );
    // },
    // calcAll: function () {
    //   return {
    //     yearly: this.calcInitial + this.calcFixed + this.calcDynamic,
    //     minimum:
    //       this.calcInitial +
    //       this.calcFixedRange["minimum"] +
    //       this.calcDynamicRange["minimum"],
    //     maximum:
    //       this.calcInitial +
    //       this.calcFixedRange["maximum"] +
    //       this.calcDynamicRange["maximum"],
    //   };
    // },
    // calcFixedRange: function () {
    //   return {
    //     yearly: this.expenses.fixedRowsData.reduce(
    //       (acc, current) => acc + Number(current["yearly"]),
    //       0
    //     ),
    //     minimum: this.expenses.fixedRowsData.reduce(
    //       (acc, current) => acc + Number(current["minimum"]),
    //       0
    //     ),
    //     maximum: this.expenses.fixedRowsData.reduce(
    //       (acc, current) => acc + Number(current["maximum"]),
    //       0
    //     ),
    //   };
    // },
    // calcDynamicRange: function () {
    //   return {
    //     yearly: this.expenses.dynamicRowsData.reduce(
    //       (acc, current) => acc + Number(current["yearly"]),
    //       0
    //     ),
    //     minimum: this.expenses.dynamicRowsData.reduce(
    //       (acc, current) => acc + Number(current["minimum"]),
    //       0
    //     ),
    //     maximum: this.expenses.dynamicRowsData.reduce(
    //       (acc, current) => acc + Number(current["maximum"]),
    //       0
    //     ),
    //   };
    // },
    // calcMonthly: function () {
    //   return {
    //     fixed: this.expenses.fixedRowsData.reduce(
    //       (acc, current) => acc + Number(current["expense"]),
    //       0
    //     ),
    //     dynamic: this.expenses.dynamicRowsData.reduce(
    //       (acc, current) => acc + Number(current["expense"]),
    //       0
    //     ),
    //   };
    // },
    // calcMonthlyForYearlyGraph: function () {
    //   let initialA = this.calcInitial;
    //   let fixed = [];
    //   let dynamic = [];

    //   for (let index = 0; index < 12; index++) {
    //     fixed[index] = this.calcMonthly.fixed;
    //     dynamic[index] = this.calcMonthly.dynamic;
    //   }

    //   return [
    //     {
    //       name: "הוצאות קבועות",
    //       data: fixed,
    //     },
    //     {
    //       name: "הוצאות משתנות",
    //       data: dynamic,
    //     },
    //     {
    //       name: "הוצאות הקמה",
    //       data: [initialA],
    //     },
    //   ];
    // },

    // all above not relevant
    calcIncome: function() {
      return {
        yearly: this.income.productsRowsData.reduce(
          (acc, current) =>
            current["yearlyEstimateMonthlyChange"]
              ? acc + Number(current["yearlyEstimateMonthlyChange"])
              : acc + Number(current["yearlyEstimate"]),
          0
        ),

        //   yearly:
        //     this.income.initialRowsData.reduce(
        //       (acc, current) => acc + Number(current["yearly"]),
        //       0
        //     ) +
        //     this.income.fixedRowsData.reduce(
        //       (acc, current) => acc + Number(current["yearly"]),
        //       0
        //     ) +
        //     this.income.dynamicRowsData.reduce(
        //       (acc, current) => acc + Number(current["yearly"]),
        //       0
        //     ),
        //   minimum:
        //     this.income.initialRowsData.reduce(
        //       (acc, current) => acc + Number(current["minimum"]),
        //       0
        //     ) +
        //     this.income.fixedRowsData.reduce(
        //       (acc, current) => acc + Number(current["minimum"]),
        //       0
        //     ) +
        //     this.income.dynamicRowsData.reduce(
        //       (acc, current) => acc + Number(current["minimum"]),
        //       0
        //     ),
        //   maximum:
        //     this.income.initialRowsData.reduce(
        //       (acc, current) => acc + Number(current["maximum"]),
        //       0
        //     ) +
        //     this.income.fixedRowsData.reduce(
        //       (acc, current) => acc + Number(current["maximum"]),
        //       0
        //     ) +
        //     this.income.dynamicRowsData.reduce(
        //       (acc, current) => acc + Number(current["maximum"]),
        //       0
        //     ),
        // };
      };
    },
    calcIncomeRange: function() {
      return {
        minimum: this.changePercentage
          ? this.calcIncome.yearly -
            (this.calcIncome.yearly * this.changePercentage) / 100
          : this.calcIncome.yearly,
        maximum: this.changePercentage
          ? this.calcIncome.yearly +
            (this.calcIncome.yearly * this.changePercentage) / 100
          : this.calcIncome.yearly,
      };
    },
    calcExpensesRange: function() {
      return {
        minimum: this.changeExpensesPercentage
          ? this.calcExpenses.yearly -
            (this.calcExpenses.yearly * this.changeExpensesPercentage) / 100
          : this.calcExpenses.yearly,
        maximum: this.changeExpensesPercentage
          ? this.calcExpenses.yearly +
            (this.calcExpenses.yearly * this.changeExpensesPercentage) / 100
          : this.calcExpenses.yearly,
      };
    },
    // calcExpensesOld: function () {
    //   return {
    //     yearly:
    //       this.expenses.initialRowsData.reduce(
    //         (acc, current) => acc + Number(current["yearly"]),
    //         0
    //       ) +
    //       this.expenses.fixedRowsData.reduce(
    //         (acc, current) => acc + Number(current["yearly"]),
    //         0
    //       ) +
    //       this.expenses.dynamicRowsData.reduce(
    //         (acc, current) => acc + Number(current["yearly"]),
    //         0
    //       ),
    //     minimum:
    //       this.expenses.initialRowsData.reduce(
    //         (acc, current) => acc + Number(current["minimum"]),
    //         0
    //       ) +
    //       this.expenses.fixedRowsData.reduce(
    //         (acc, current) => acc + Number(current["minimum"]),
    //         0
    //       ) +
    //       this.expenses.dynamicRowsData.reduce(
    //         (acc, current) => acc + Number(current["minimum"]),
    //         0
    //       ),
    //     maximum:
    //       this.expenses.initialRowsData.reduce(
    //         (acc, current) => acc + Number(current["maximum"]),
    //         0
    //       ) +
    //       this.expenses.fixedRowsData.reduce(
    //         (acc, current) => acc + Number(current["maximum"]),
    //         0
    //       ) +
    //       this.expenses.dynamicRowsData.reduce(
    //         (acc, current) => acc + Number(current["maximum"]),
    //         0
    //       ),
    //   };
    // },
    calcExpenses: function() {
      return {
        yearly:
          this.expenses.initialRowsData.reduce(
            (acc, current) => acc + Number(current["yearly"]),
            0
          ) +
          this.expenses.fixedRowsData.reduce(
            (acc, current) => acc + Number(current["yearly"]),
            0
          ) +
          this.expenses.dynamicRowsData.reduce(
            (acc, current) => acc + Number(current["yearly"]),
            0
          ),
        initialOnly: this.expenses.initialRowsData.reduce(
          (acc, current) => acc + Number(current["yearly"]),
          0
        ),
      };
    },
    calcNet: function() {
      return {
        yearly: this.calcIncome["yearly"] - this.calcExpenses["yearly"] - this.salesCost,
        minimum:
          this.calcIncomeRange["minimum"] - this.calcExpensesRange["minimum"] - this.salesCost,
        maximum:
          this.calcIncomeRange["maximum"] - this.calcExpensesRange["maximum"] - this.salesCost,
      };
    },
  },
};
</script>

<style>
span.apexcharts-legend-marker {
  margin-left: 5px;
  margin-right: 5px;
}

.final-table td,
.final-table th {
  font-size: 16px !important;
}

.percent-ltr {
  direction: ltr;
}
</style>
