//import * as firebase from 'firebase/app'
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID
};
firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.firestore()
const auth = firebase.auth()

auth.onAuthStateChanged(user => {
    console.log("onAuthStateChanged")
    console.log(user);
    console.log("END onAuthStateChanged")
})

// collection references
const plansCollection = db.collection('plans')
const usersCollection = db.collection('users')
const businessCollection = db.collection('business')

// const postsCollection = db.collection('posts')
// const commentsCollection = db.collection('comments')
// const likesCollection = db.collection('likes')

// export utils/refs
export {
    db,
    auth,
    plansCollection,
    usersCollection,
    businessCollection
}