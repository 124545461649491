<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <img :src="require('../assets/yazam-logo.png')">
      </v-col>

      <v-col class="my-4">
        <h1 class="display-2 font-weight-bold mb-3">
          ברוכים הבאים למערכת יזם
        </h1>

        <p class="subheading font-weight-regular">
          פלטפורמה לתכנון עסקי ופיתוח עסקי
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({

    }),
  }
</script>

<style scoped>
.v-application .display-2 {
  font-family: 'Open Sans Hebrew' !important;
}
</style>
