<template>
  <div class="login pa-3">
    <!-- <iframe src="https://wisite.co.il/contact/" frameborder="0" height="900" width="100%"></iframe> -->
    <v-row>
      <v-col cols="7">
        <v-expansion-panels class="mb-4">
          <v-expansion-panel>
            <v-expansion-panel-header
              >איך להשתמש באשף יצירת תחזית עסקית?</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>תוכן תוכן תוכן....</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >איך מזינים הוצאות?</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>תוכן תוכן תוכן....</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >איך מזינים הכנסות?</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>תוכן תוכן תוכן....</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >מה ההבדל בין הוצאות קבועות להוצאות
              משתנות?</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>תוכן תוכן תוכן....</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >איך מוסיפים שורות לטבלה?</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>תוכן תוכן תוכן....</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >אפשר להגדיר הוצאות / הכנסות ברמה חודשית ולא רק
              שנתית?</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>תוכן תוכן תוכן....</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >האם ניתן לקבל עזרה של מומחה מבית סימבה?</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>תוכן תוכן תוכן....</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="5">
        <!-- <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/x11eg6uxjQE"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Contact",
  components: {},
  data: () => ({}),
  methods: {},
};
</script>
