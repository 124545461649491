<template>
  <div>
    <v-simple-table class="elevation-1">
      <template v-slot:default>
        <thead>
          <tr>
            <th>פירוט</th>
            <th v-for="(item, i) in months" :key="i">חודש {{ i + 1 }}</th>
            <th>שנתי (אחוז מהכלל)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, order) in rowsData" :key="order">
            <td>
              <v-btn
                @click="deleteRow(order)"
                color="red"
                icon
                x-small
                v-if="editbale"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
              {{ item.item }}
            </td>
            <td
              v-for="(col, i) in months"
              :key="i"
              @click="openDialog(i, item, order)"
              class="table-cell"
            >
              {{ Number(item.months[i]).toLocaleString() }}
            </td>
            <td class="d-flex justify-space-between align-center">
              {{
                item.yearly > 0
                  ? Number(item.yearly).toLocaleString()
                  : Number(item.yearlyEstimate).toLocaleString()
              }}
              <v-chip color="primary" small class="mr-1 percent-ltr"
                >{{
                  (
                    ((item.yearly > 0 ? item.yearly : item.yearlyEstimate) /
                      calculatePeriod) *
                    100
                  ).toFixed(2)
                }}%</v-chip
              >
            </td>
          </tr>
          <tr class="summary-row">
            <td>סה"כ</td>
            <td v-for="(col, i) in months" :key="i">
              {{ calculateMonths[i].toLocaleString() }}
            </td>
            <td>{{ calculatePeriod.toLocaleString() }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <apexchart
      height="400"
      type="area"
      :options="chartOptionsArea"
      :series="monthsForGraph"
    ></apexchart>

    <v-dialog v-model="dialog" max-width="30%">
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>עדכון נתונים</v-toolbar-title>
        </v-toolbar>
        <v-card-title v-if="dialog">{{ selecteditem.item }}</v-card-title>

        <v-card-text v-if="dialog">
          <ul class="mr-4 mb-4 black--text">
            <li>ערך לשינוי: {{ selecteditem.item }}</li>
            <li>החודש לשינוי: חודש פעילות {{ selectedmonth + 1 }}</li>
            <li>הערך הנוכחי: {{ selecteditem.months[selectedmonth] }}</li>
          </ul>

          <v-text-field
            type="number"
            label="הזנת ערך חדש"
            v-model.number="changeCell"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="updateCell(changeCell)"
            >שינוי תא בודד</v-btn
          >

          <v-btn color="primary" text @click="updateCell(changeCell, 'equal')"
            >החלה זהה מכאן והלאה</v-btn
          >

          <v-btn
            color="primary"
            text
            @click="updateCell(changeCell, 'addition')"
            >תוספת מכאן והלאה</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: ["rows", "typeProp", "months", "editbale"],
  components: { apexchart: VueApexCharts },
  data() {
    return {
      //rowsData: [],
      //rowsData: [...this.rows],
      //rowsData: Array.from(this.rows),
      rowsData: this.rows,
      monthsArray: [],

      dialog: false,
      changeCell: 0,
      selectedmonth: null,
      selecteditem: null,
      selectedorder: null,

      chartOptionsLine: {
        chart: {
          // height: 1000,
          // type: 'line',
          toolbar: {
            show: false,
          },
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        stroke: {
          width: 7,
          curve: "smooth",
        },
        xaxis: {
          //type: "datetime",
          categories: [
            // "1/11/2000",
            // "2/11/2000",
            // "3/11/2000",
            // "4/11/2000",
            // "5/11/2000",
            // "6/11/2000",
            // "7/11/2000",
            // "8/11/2000",
            // "9/11/2000",
            // "10/11/2000",
            // "11/11/2000",
            // "12/11/2000",
            // "1/11/2001",
            // "2/11/2001",
            // "3/11/2001",
            // "4/11/2001",
            // "5/11/2001",
            // "6/11/2001"
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
          ],
          //tickAmount: 12,
          labels: {
            // formatter: function(value, timestamp, opts) {
            //   return opts.dateFormatter(new Date(timestamp), "dd MMM");
            // }
          },
        },
        // title: {
        //   text: "Social Media",
        //   align: "center",
        //   style: {
        //     fontSize: "16px",
        //     color: "#666"
        //   }
        // },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            gradientToColors: ["#FDD835"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 4,
          colors: ["#FFA41B"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        yaxis: {
          // min: -10,
          // max: 40,
          // title: {
          //   text: "סכום חודשי"
          // }
        },
      },
      chartOptionsArea: {
        chart: {
          // height: 1000,
          // type: 'line',
          toolbar: {
            show: false,
          },
          fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        },
        stroke: {
          width: 3,
          curve: "smooth",
        },
        xaxis: {
          //type: "datetime",
          categories: [
            // "1/11/2000",
            // "2/11/2000",
            // "3/11/2000",
            // "4/11/2000",
            // "5/11/2000",
            // "6/11/2000",
            // "7/11/2000",
            // "8/11/2000",
            // "9/11/2000",
            // "10/11/2000",
            // "11/11/2000",
            // "12/11/2000",
            // "1/11/2001",
            // "2/11/2001",
            // "3/11/2001",
            // "4/11/2001",
            // "5/11/2001",
            // "6/11/2001"
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
          ],
          //tickAmount: 12,
          labels: {
            // formatter: function(value, timestamp, opts) {
            //   return opts.dateFormatter(new Date(timestamp), "dd MMM");
            // }
          },
        },
        // title: {
        //   text: "Social Media",
        //   align: "center",
        //   style: {
        //     fontSize: "16px",
        //     color: "#666"
        //   }
        // },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100],
          },
        },
        markers: {
          size: 4,
          colors: ["#FFA41B"],
          strokeColors: "#fff",
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        yaxis: {
          decimalsInFloat: 2,
          // min: -10,
          // max: 40,
          // title: {
          //   text: "סכום חודשי"
          // }
        },
        dataLabels: {
          enabled: false,
        },
      },
    };
  },

  computed: {
    calculateMonths: function() {
      let array = [];
      for (let index = 0; index < this.months; index++) {
        array[index] = 0;
      }
      //let array = this.monthsArray;

      // this.rowsData.forEach(element => {
      //   for (let index = 0; index < this.months; index++) {
      //     if (array[index]) {
      //       array[index] += Number(element.months[index]);
      //     } else {
      //       array.push(Number(element.months[index]));
      //     }
      //   }
      // });

      this.rowsData.forEach((element) => {
        for (let index = 0; index < this.months; index++) {
          array[index] += Number(element.months[index]);
        }
      });
      return array;
    },

    monthsForGraph: function() {
      return [
        {
          name: "סכום חודשי",
          data: this.calculateMonths,
        },
      ];
    },

    calculatePeriod: function() {
      return this.calculateMonths.reduce((acc, current) => acc + current, 0);
    },
  },

  methods: {
    openDialog(month, item, order) {
      if (this.editbale && this.editbale != 'initial') {
        console.log(month);
        console.log(item);
        console.log(order);

        this.selectedmonth = month;
        this.selecteditem = item;
        this.selectedorder = order;

        this.dialog = true;
      }
    },

    updateCell(value, changeType) {
      if (changeType == "equal") {
        let i = this.selectedmonth;
        for (i; i < this.months; i++) {
          //this.rowsData[this.selectedorder][`month${i}`] = Number(value);
          this.$set(
            this.rowsData[this.selectedorder]["months"],
            i,
            Number(value)
          );
        }
      } else if (changeType == "addition") {
        let i = this.selectedmonth;
        for (i; i < this.months; i++) {
          //this.rowsData[this.selectedorder][`month${i}`] += Number(value);
          let currentValue = Number(
            this.rowsData[this.selectedorder]["months"][i]
          );
          this.$set(
            this.rowsData[this.selectedorder]["months"],
            i,
            Number(currentValue + value)
          );
        }
      } else {
        //this.rowsData[this.selectedorder]['months'][this.selectedmonth] = Number(value);
        this.$set(
          this.rowsData[this.selectedorder]["months"],
          this.selectedmonth,
          Number(value)
        );
      }
      this.calculateYearly(this.rowsData[this.selectedorder]);

      let payload = {
        data: this.rowsData,
        type: this.typeProp,
      };

      this.$store.commit("updatePlan", payload);
    },
    calculateYearly(item) {
      console.log(item);
      item.yearly = item["months"].reduce(
        (acc, current) => acc + Number(current),
        0
      );
    },
    deleteRow: function(order) {
      if (confirm("נא לאשר מחיקת שורה זו") == true) {
        this.rowsData.splice(order, 1);
      }
    },
  },
  watch: {
    rows: {
      handler(val) {
        console.log("rows changed");
        console.log(val);
        this.rowsData = this.rows;
      },
      deep: true,
    },
  },
  mounted() {
    console.log("Prop: " + this.typeProp);
    console.log("isInitial: " + this.isInitial);
    //this.rowsData = [...this.rows];
  },
};
</script>

<style>
.table-cell {
  cursor: pointer;
}

.table-cell:hover {
  background-color: #2953e8;
  color: #ffffff;
}

.summary-row {
  background-color: #f5f5f5;
  font-weight: bold;
}

rect {
  fill: #2953e8;
}

td {
  font-size: 0.95rem !important;
}

.input-icon-switch i,
.input-icon-switch button {
  font-size: 16px !important;
}
</style>
