<template>
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width: 20%">פירוט</th>
          <th
            :style="!isInitial ? 'width: 20%' : 'width: 35%'"
          >{{ isInitial ? 'סכום חד פעמי' : 'סכום חודשי' }}</th>
          <th style="width: 15%" v-if="!isInitial">סטייה שנתית באחוזים</th>
          <th style="width: 20%">הערות</th>
          <th style="width: 25%">מבט שנתי</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in rows" :key="item">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
              </template>
              <span>הסבר קצרצר</span>
            </v-tooltip>
            {{ item }}
          </td>
          <td>
            <v-text-field
              label="הזינו נתון"
              type="number"
              @input="inputChanged('expense',item,$event)"
              append-icon="mdi-currency-ils"
              @click:append="test"
              dense
              flat
              hide-details
              rounded
              solo-inverted
              class="input-icon-switch"
            ></v-text-field>
            <!-- <v-switch label="הזנה כאחוז"></v-switch> -->
          </td>
          <td v-if="!isInitial">
            <v-text-field
              label="הזינו נתון"
              type="number"
              @input="inputChanged('deviation',item,$event)"
              append-icon="mdi-percent-outline"
              dense
              flat
              hide-details
              rounded
              solo-inverted
              class="input-icon-switch"
            ></v-text-field>
          </td>
          <td class="py-2">
            <v-textarea
              label="הערות"
              rows="1"
              auto-grow
              clearable
              dense
              flat
              hide-details
              rounded
              solo-inverted
            ></v-textarea>
          </td>
          <td>
            <v-expand-transition>
              <div v-if="indexInRowsData(item) != -1" class="pa-2">
                <v-sparkline
                  :value="[rowsData[indexInRowsData(item)]['minimum'],rowsData[indexInRowsData(item)]['yearly'],rowsData[indexInRowsData(item)]['maximum']]"
                  label-size="15"
                  line-width="30"
                  height="80"
                  type="bar"
                >
                  <template v-slot:label="data">{{ Number(data.value).toLocaleString() }} ₪</template>
                </v-sparkline>
                <!-- <li>תרחיש שנתי: {{ rowsData[indexInRowsData(item)]['yearly'] }}</li>
              <li>תרחיש שלילי: {{ rowsData[indexInRowsData(item)]['minimum'] }}</li>
                <li>תרחיש חיובי: {{ rowsData[indexInRowsData(item)]['maximum'] }}</li>-->
              </div>
            </v-expand-transition>
            <!-- <p v-else>Empty</p> -->
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "Table",
  props: ["rows", "typeProp"],
  data() {
    return {
      rowsData: []
    };
  },

  computed: {
    isInitial() {
      return this.typeProp == "initial" || this.typeProp == "Initial";
    }
  },

  methods: {
    inputChanged(type, item, e) {
      console.log(type);
      console.log(item);
      console.log(e);

      let pos = this.indexInRowsData(item);
      let current = this.rowsData[pos];

      if (pos == -1) {
        this.rowsData.push({
          item: item,
          [type]: e,
          yearly: 0,
          minimum: 0,
          maximum: 0
        });
        current = this.rowsData[this.rowsData.length - 1];
      } else {
        current[type] = e;
      }
      this.calculateYearly(current);
      this.$emit("dataUpdate", this.rowsData, this.typeProp);
    },

    calculateYearly(current) {
      if (current["expense"]) {
        let yearly = this.isInitial
          ? current["expense"] * 1
          : current["expense"] * 12;
        current["yearly"] = yearly;
        current["minimum"] = yearly;
        current["maximum"] = yearly;
      }

      if (current["deviation"] && current["yearly"]) {
        current["minimum"] =
          current["yearly"] - current["yearly"] * (current["deviation"] / 100);
        current["maximum"] =
          current["yearly"] + current["yearly"] * (current["deviation"] / 100);
      }
    },

    indexInRowsData(item) {
      return this.rowsData.map(e => e.item).indexOf(item);
    },
    test(e) {
      console.log(e);
    }
  },
  mounted() {
    console.log("Prop: " + this.typeProp);
    console.log("isInitial: " + this.isInitial);
  }
};
</script>

<style>
rect {
  fill: #2953E8;
}

td {
  font-size: 0.95rem !important;
}

.input-icon-switch i, .input-icon-switch button{
  font-size: 16px !important;
}
</style>