import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebase'

import Home from '../views/Home.vue'
import WelcomeWizard from '../components/WelcomeWizard.vue'
import NewPlan from '../views/NewPlan.vue'
import SplitNewPlan from '../views/SplitNewPlan.vue'
import Profile from '../views/Profile.vue'
import Development from '../views/Development.vue'
import Academy from '../views/Academy.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Plans from '../views/Plans.vue'
import SinglePlan from '../views/NewSinglePlan.vue'
import Insights from '../views/Insights.vue'
import Contact from '../views/Contact.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/welcome',
    name: 'WelcomeWizard',
    component: WelcomeWizard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/plans',
    name: 'Plans',
    component: Plans,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/plans/:id',
    name: 'SinglePlan',
    component: SinglePlan,
    //component: () => import(/* webpackChunkName: "SinglePlan" */ '../views/SinglePlan.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    //path: '/new-plan',
    path: '/old-new-plan',
    name: 'NewPlan',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: NewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-plan/intro',
    name: 'SplitNewPlanIntro',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: SplitNewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-plan/expenses',
    name: 'SplitNewPlanExpenses',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: SplitNewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-plan/income',
    name: 'SplitNewPlanIncome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: SplitNewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-plan/vision',
    name: 'SplitNewPlanIncome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: SplitNewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-plan/research',
    name: 'SplitNewPlanIncome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: SplitNewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-plan/people',
    name: 'SplitNewPlanIncome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: SplitNewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-plan/marketing',
    name: 'SplitNewPlanIncome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: SplitNewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/new-plan/taxes',
    name: 'SplitNewPlanIncome',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/NewPlan.vue')
    component: SplitNewPlan,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/insights',
    name: 'Insights',
    component: Insights,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/development',
    name: 'Development',
    component: Development,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/academy',
    name: 'Academy',
    component: Academy,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
