import Vue from "vue";
import Vuex from "vuex";
import {
  auth,
  plansCollection,
  businessCollection,
  usersCollection,
} from "../firebase";
//import { businessCollection } from "../firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: null,
    currentUserData: {},
    currentBusiness: null,
    currentPlan: {
      initial: [],
      fixed: [],
    },
    currentProducts: [],
    currentPlanName: "",
    currentPlanID: "",
    userBusiness: [],
    userPlans: [],
    tempVisionData: {},
    tempResearchData: {},
    tempteamMembersData: {},
    tempMarketingData: {},
    tempsaleSources: {},
    // tempPlanData: {}
    editedPlanData: {},
  },
  mutations: {
    setCurrentUser: function(state) {
      state.currentUser = auth.currentUser.uid;
    },
    setCurrentUserData: function(state, payload) {
      state.currentUserData = payload;
    },
    logoutUser: function(state) {
      state.currentUser = null;
    },
    updatePlan: function(state, payload) {
      state.currentPlan[payload.type] = payload.data;
    },
    setCurrentProducts: function(state, payload) {
      state.currentProducts = payload;
    },
    setUserBusiness: function(state, payload) {
      state.userBusiness = payload;
      //state.currentBusiness = payload[0]['id'];
    },
    setUserPlans: function(state, payload) {
      state.userPlans = payload;
    },
    setCurrentBusiness: function(state, payload) {
      state.currentBusiness = payload;
    },
    setCurrentPlanName: function(state, payload) {
      state.currentPlanName = payload;
    },
    setCurrentPlanID: function(state, payload) {
      state.currentPlanID = payload;
    },
    // setTempPlanData: function (state, payload) {
    //   state.tempPlanData = payload;
    // },
    setTempVisionData: function(state, payload) {
      state.tempVisionData = payload;
    },
    setTempResearchData: function(state, payload) {
      state.tempResearchData = payload;
    },
    setTempteamMembersData: function(state, payload) {
      state.tempteamMembersData = payload;
    },
    setTempMarketingData: function(state, payload) {
      state.tempMarketingData = payload;
    },
    setTempsaleSources: function(state, payload) {
      if (payload) {
        state.tempsaleSources = payload;
      }
    },
    setEditedPlanData: function(state, payload) {
      state.editedPlanData = payload;
    },
  },
  actions: {
    setCurrentUser: async function(context) {
      if (auth.currentUser) {
        context.commit("setCurrentUser");
        await context.dispatch("setCurrentUserData");
        console.log("action setCurrentUser");
        return true;
      }

      return false;

      //localStorage.setItem('simbaCurrentUser', auth.currentUser.uid);
    },
    setCurrentUserData: async function(context) {
      const userData = await usersCollection
        .doc(this.state.currentUser)
        .get()
        .then((doc) => doc.data());

      console.log("this.currentUser", this.state.currentUser);
      context.commit("setCurrentUserData", userData);

      //localStorage.setItem('simbaCurrentUser', auth.currentUser.uid);
    },
    logoutUser: function(context) {
      context.commit("logoutUser");
      //localStorage.removeItem('simbaCurrentUser');
    },
    setCurrentProducts: function(context, payload) {
      context.commit("setCurrentProducts", payload);
    },
    loadAllPlans: async function(context) {
      console.log("action loadAllPlans");
      console.log(this.state.currentUser);

      // const plans = await plansCollection
      //   .where("user", "==", this.state.currentUser)
      //   .get();

      let plans;
      console.log(
        "this.state.currentUserData.role from loadAllPlans",
        this.state.currentUserData.role
      );
      if (this.state.currentUserData.role == "superuser") {
        plans = await plansCollection.get();
      } else {
        plans = await plansCollection
          .where("user", "==", this.state.currentUser)
          .get();
      }

      let loadedPlans = [];

      if (plans) {
        plans.forEach((doc) => {
          //console.log(doc.id, "=>", doc.data());
          //this.loadedPlans.push(doc.data());

          const docData = {
            id: doc.id,
            data: doc.data(),
          };

          loadedPlans.push(docData);
        });
      }
      console.log(plans);
      context.commit("setUserPlans", loadedPlans);
    },
    loadAllBusiness: async function(context) {
      const business = await businessCollection
        .where("user", "==", context.state.currentUser)
        .get();

      let docArray = [];

      if (business) {
        business.forEach((doc) => {
          const docData = {
            id: doc.id,
            data: doc.data(),
          };
          docArray.push(docData);
        });
      }
      context.commit("setUserBusiness", docArray);
    },
  },
  getters: {
    currentBusinessProducts: (state) => {
      let arr = state.userBusiness.filter(
        (business) => business.id == state.currentBusiness
      );
      if (arr[0]) {
        return arr[0].data.products;
      }
    },
    currentBusinessName: (state) => {
      let arr = state.userBusiness.filter(
        (business) => business.id == state.currentBusiness
      );
      if (arr[0]) {
        return arr[0].data.businessname;
      }
    },
  },
  modules: {},
});
