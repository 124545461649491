<template>
      <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" editable>שלב 1</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2" editable>שלב 2</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" editable>שלב 3</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" class="grey lighten-5">
 
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
</template>

<script>
export default {
    data: () => ({
        e1: 1
    })
}
</script>

<style>

</style>