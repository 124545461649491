<template>
  <v-container class="pa-0">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1" editable>הוצאות הקמה</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2" editable>הוצאות קבועות</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3" editable>הוצאות משתנות</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1" class="grey lighten-5">
          <v-container>
            <h2 class="mb-4">הזנת נתונים</h2>
            <Table :rows="expenses.initial" typeProp="initial" :currency="selectedCurrency" :months="months"
              @dataUpdate="dataUpdate" @rowsUpdate="rowsUpdate" />
            <v-btn @click="addRow('initial')" class="add-row yazam-button-blue">הוספת שורת הוצאות חדשה</v-btn>

            <h2 class="my-4">צפייה בנתונים</h2>
            <ReviewTable :rows="initialRowsData" months="1" editbale="initial" typeProp="fixed" class="my-4" />

            <!-- <h2 class="my-4">צפייה בנתונים ודיוקם</h2>
            <ReviewTable :rows="initialRowsData" :months="months" class="my-4" />-->

            <v-btn @click="nextStep" class="add-row yazam-button-blue mr-4">המשך</v-btn>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="2" class="grey lighten-5">
          <v-container>
            <h2 class="mb-4">הזנת נתונים</h2>

            <Table :rows="expenses.fixed" typeProp="fixed" :currency="selectedCurrency" :months="months"
              @dataUpdate="dataUpdate" @rowsUpdate="rowsUpdate" />
            <v-btn @click="addRow('fixed')" class="add-row yazam-button-blue">הוספת שורת הוצאות חדשה</v-btn>

            <h2 class="my-4">צפייה בנתונים ודיוקם</h2>
            <ReviewTable :rows="fixedRowsData" :months="months" :editbale="editMode" typeProp="fixed" class="my-4" />

            <v-btn @click="nextStep" class="add-row yazam-button-blue">המשך</v-btn>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="3" class="grey lighten-5">
          <v-container>
            <h2 class="mb-4">הזנת נתונים</h2>
            <!-- expenses.dynamic -->
            <Table :rows="dynamicPlusProducts" typeProp="dynamic" :currency="selectedCurrency" :months="months"
              @dataUpdate="dataUpdate" />
            <v-btn @click="addRow('dynamic')" class="add-row yazam-button-blue">הוספת שורת הוצאות חדשה</v-btn>

            <h2 class="my-4">צפייה בנתונים ודיוקם</h2>
            <ReviewTable :rows="dynamicRowsData" :months="months" :editbale="editMode" typeProp="dynamic"
              class="my-4" />

            <v-btn @click="saveDataNew" class="add-row yazam-button-blue">שמור והמשך</v-btn>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script>
import Table from "@/components/TableCopy.vue";
//import Graphs from "@/components/GraphsCopy.vue";
import ReviewTable from "@/components/ReviewTable.vue";
import { plansCollection } from "../firebase";

export default {
  name: "ExpensesOnly",
  components: { Table, ReviewTable },
  data: () => ({
    e1: 1,
    editMode: true,
    //e6: 1,
    //items: ["טכנולוגיה", "פיננסים", "לוגיסטיקה"],
    //enabled: false,
    //enabled1: false,

    fields: [
      "בידור",
      "ביטוח",
      "טכנולוגיה",
      "ייעוץ עסקי",
      "כושר",
      "לוגיסטיקה",
      "מזון",
      "פיננסים",
      "שיווק",
      "שירותים",
      "תוכנה",
      "תעופה",
      "תקשורת",
    ],
    expenses: {
      initial: [
        "אחסון לוגיסטי",
        "אתר אינטרנט",
        "הוצאות משפטיות",
        "הוצאות עסקיות",
        "כתיבת תוכן",
        "מחשוב",
        "מיתוג",
        "סקרי שוק ומחקרים",
        "פטנטים",
        "שירותי עיצוב",
        "שלטים",
        "שרתים",
      ],
      fixed: [
        "ארנונה",
        "ביטוח לעסק",
        "גז",
        "חשמל",
        "מחשוב (שירותי IT)",
        "מים",
        "מערכת CRM",
        "משפטי (ליווי משפטי)",
        "סליקה",
        "עובדים (משכורות)",
        "רואה חשבון (הנהלת חשבונות, שליחת דוחות וכו׳)",
        "רכב (דלק, ביטוח ופחת)",
        "שכירות משרד/חנות",
        "שרתים",
      ],
      dynamic: [
        "מחקר",
        "נסיעות",
        "עמלות",
        "פיתוח עסקי",
        "שיווק ופרסום",
        "שירותי ייעוץ",
      ],
    },
    income: {
      initial: ["הלוואה", "השקעה", "מימון"],
      fixed: [
        "דמי ניהול",
        "מכירת מוצרים",
        "מכירת שירותים",
        "עמלות",
        "תשואה חודשית",
      ],
      dynamic: [
        "דמי ניהול",
        "מכירת מוצרים",
        "מכירת שירותים",
        "עמלות",
        "תשואה חודשית",
      ],
    },
    //rowsData: {},
    //rowsData: [],
    initialRowsData: [],
    fixedRowsData: [],
    dynamicRowsData: [],

    incomeInitialRowsData: [],
    incomeFixedRowsData: [],
    incomeDynamicRowsData: [],

    months: 12,
    showReview: false,

    planName: "",
    businessOptions: [
      {
        title: "העסק של רועי",
        value: "123456789",
      },
    ],
    currencyOptions: [
      {
        title: "שקל",
        value: "ils",
      },
      { title: "דולר אמריקאי", value: "usd" },
    ],
    selectedBusiness: null,
    selectedCurrency: "ils",

    currentPlanID: null,
  }),
  methods: {
    dataUpdate(Data, typeProp) {
      //this.rowsData[typeProp] = Data;
      //this.rowsData = Data;
      this[typeProp + "RowsData"] = Data;
      //let thisData = this[typeProp + "RowsData"];

      let payload = {
        data: Data,
        type: typeProp,
      };

      this.$store.commit("updatePlan", payload);
      //console.log(Data);
      console.log("typeProp: " + typeProp);
    },
    incomeDataUpdate(Data, typeProp) {
      this["income" + typeProp + "RowsData"] = Data;
      console.log("income typeProp: " + typeProp);
    },
    addRow(type) {
      let name = prompt("הזינו שם שורה");
      if (name) {
        this.expenses[type].push(name);
      }
    },
    incomeAddRow(type) {
      let name = prompt("הזינו שם שורה");
      if (name) {
        this.income[type].push(name);
      }
    },
    rowsUpdate(type, id, value) {
      console.log("rowsUpdate");
      let key = this.expenses[type].findIndex((e) => e == id);
      //this.expenses[type][key] = value;

      this.$set(this.expenses[type], key, value);

      console.log(this.expenses[type][key]);
    },
    nextStep() {
      this.saveDataNew();
      this.$vuetify.goTo(0).then(() => {
        this.e1++;
      });
    },
    async saveData() {
      if (
        this.$store.state.currentPlanName &&
        this.$store.state.currentBusiness
      ) {
        const savedPlan = await plansCollection.add({
          planName: this.$store.state.currentPlanName,
          user: this.$store.state.currentUser,
          expenses: {
            initialRowsData: this.$store.state.currentPlan.initial,
            fixedRowsData: this.$store.state.currentPlan.fixed,
            dynamicRowsData: this.$store.state.currentPlan.dynamic,
          },
        });

        console.log("savedPlan");
        console.log(savedPlan.id);
        this.currentPlanID = savedPlan.id;
        this.$store.commit("setCurrentPlanID", this.currentPlanID);
        this.$router.push("/new-plan/income");
      } else {
        alert("לא ניתן לשמור אם לא מוגדר שם לתכנית או משויך עסק");
      }

      //this.e1++;
    },
    async saveDataNew() {
      let currentPlanIDfromStore = this.$store.state.currentPlanID;
      //currentPlanID = "i1mVD3nh7m013D8nuaZJ";
      console.log("currentPlanID: " + currentPlanIDfromStore);

      // was this.currentPlanID in the if and in .doc
      if (currentPlanIDfromStore) {
        await plansCollection
          .doc(currentPlanIDfromStore)
          .update({
            expenses: {
              initialRowsData: this.$store.state.currentPlan?.initial ? this.$store.state.currentPlan?.initial : [],
              fixedRowsData: this.$store.state.currentPlan?.fixed ? this.$store.state.currentPlan?.fixed : [],
              dynamicRowsData: this.$store.state.currentPlan?.dynamic ? this.$store.state.currentPlan?.dynamic : [],
            },
          })
          .then(console.log("Saved expenses data"));

        if (this.e1 == 3) {
          this.$router.push("/new-plan/income");
        }
        // this.$router.push("/new-plan/income");
      } else {
        alert("לא ניתן לשמור מקטע הוצאות, לא משויכת תכנית עסקית");
      }
    },
    async saveIncomeData() {
      await plansCollection
        .doc(this.currentPlanID)
        .update({
          income: {
            initialRowsData: this.incomeInitialRowsData,
            fixedRowsData: this.incomeFixedRowsData,
            dynamicRowsData: this.incomeDynamicRowsData,
          },
        })
        .then(console.log("Saved Income"));

      this.$router.push("/plans/" + this.currentPlanID);
    },
    updateDataFromStoreEditMode() {
      console.log("updateDataFromStoreEditMode ran from ExpensesOnly");
      let editedPlan = this.$store.state.editedPlanData;

      this.initialRowsData = editedPlan?.expenses?.initialRowsData ? editedPlan?.expenses?.initialRowsData : [];
      this.fixedRowsData = editedPlan?.expenses?.fixedRowsData ? editedPlan?.expenses?.fixedRowsData : [];
      this.dynamicRowsData = editedPlan?.expenses?.dynamicRowsData ? editedPlan?.expenses?.dynamicRowsData : [];
    },
  },
  computed: {
    dynamicPlusProducts: function () {
      /*
       explanation:
       the previous dev wanted apparently to add the business products to this list but did this wrong. If a request comes up, fix it here.
       */
      // return this.$store.state.currentProducts.concat(this.expenses.dynamic); // what the prev dev did
      return this.expenses.dynamic; // my patch
    },
    isPlanInEdit: function () {
      if (Object.keys(this.$store.state.editedPlanData).length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created: function () {
    if (this.isPlanInEdit) {
      this.updateDataFromStoreEditMode();
    }
  },
  watch: {
    isPlanInEdit: function (value) {
      console.log("watched isPlanInEdit from ExpensesOnly: ", value);
      if (value) {
        this.updateDataFromStoreEditMode();
      }
    },
  },
};
</script>

<style scoped>
.v-stepper--vertical .v-stepper__content {
  padding: 24px 24px 16px 60px;
}

button.v-expansion-panel-header {
  font-size: 16px;
}

button.add-row {
  margin-top: 10px;
  font-size: 16px;
  letter-spacing: 0px;
  background-color: #2953e8 !important;
}
</style>
