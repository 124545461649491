<template>
  <div class="insights pa-5">
    <h1>מודל תמחור / תובנות עסקיות</h1>
    <v-row>
      <v-col cols="6">
        <v-simple-table class="mb-4 final-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th>נושא</th>
                <th>פירוט</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>כמות מכירות</td>
                <td>
                  <v-text-field label="הזינו נתון" v-model.number="numSales" type="number"></v-text-field>
                </td>
              </tr>
              <tr>
                <td>מחיר ליחידה</td>
                <td>
                  <v-text-field label="הזינו נתון" v-model.number="itemPrice" type="number"></v-text-field>
                </td>
              </tr>
              <tr>
                <td>סך ההכנסות</td>
                <td>{{ totalIncome.toLocaleString() }}</td>
              </tr>
              <tr>
                <td>עלות ליחידה</td>
                <td>
                  <v-text-field label="הזינו נתון" v-model.number="itemCost" type="number"></v-text-field>
                </td>
              </tr>
              <tr>
                <td>סך עלות המכירות</td>
                <td>{{ salesCost.toLocaleString() }}</td>
              </tr>
              <tr>
                <td>רווח גולמי</td>
                <td>{{ grossMargin.toLocaleString() }}</td>
              </tr>
              <tr>
                <td>עלויות קבועות</td>
                <td>
                  <v-text-field label="הזינו נתון" v-model.number="fixedExpenses" type="number"></v-text-field>
                </td>
              </tr>
              <tr>
                <td>רווח נקי</td>
                <td>{{ netProfit.toLocaleString() }}</td>
              </tr>
              <tr>
                <td>אחוז הרווח</td>
                <td>{{ profitPercentage.toFixed(2) }}%</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <h2 class="mb-4">הסקת מסקנות</h2>
        <v-expansion-panels class="mb-4">
          <v-expansion-panel>
            <v-expansion-panel-header>כמה לתמחר מוצר כדי להגיע לאחוז רווחיות
              מסוים</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-text-field label="אחוז רווחיות רצוי" v-model.number="wantedPercentage" type="number"
                :rules="inputRules" max="99" ref="wantedPercentage"></v-text-field>
              <v-btn @click="calcProduct" :disabled="!isValid">חישוב</v-btn>
              <p>מחיר המוצר הנדרש: {{ itemPrice }}</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- <v-expansion-panel>
            <v-expansion-panel-header
              >כמה מוצרים למכור כדי להגיע לאחוז רווחיות
              מסוים</v-expansion-panel-header
            >
            <v-expansion-panel-content></v-expansion-panel-content>
          </v-expansion-panel> -->
        </v-expansion-panels>

        <div class="mx-1">
          <v-row>
            <v-col cols="12" md="6" class="pa-2">
              <v-card class="pa-2" height="180" elevation="1">
                <h2 class="text-center mb-2">אחוז רווחיות</h2>
                <p style="font-size: 15px" class="text-center mt-n2">
                  (רווח חלקי עלות המכר)
                </p>
                <p class="text-h2 text-center" v-if="profitPercentage">
                  {{ profitPercentage }}%
                </p>
                <p class="text-center" v-else>הזינו נתונים כדי לחשב</p>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" class="pa-2">
              <v-card class="pa-2" height="180" elevation="1">
                <h2 class="text-center mb-2">אחוז רווחיות לאחר הוצאות</h2>
                <p style="font-size: 15px" class="text-center mt-n2">
                  (רווח חלקי כל ההוצאות)
                </p>
                <p class="text-h2 text-center" v-if="profitPercentageAfterExpenses && Number.isFinite(profitPercentageAfterExpenses)">
                  {{ profitPercentageAfterExpenses }}%
                </p>
                <p class="text-center" v-else>הזינו נתונים כדי לחשב</p>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" class="pa-2">
              <v-card class="pa-2" height="180" elevation="1">
                <h2 class="text-center mb-2">אחוז רווח מתוך הכנסות</h2>
                <p style="font-size: 15px" class="text-center mt-n2">
                  (רווח חלקי הכנסות)
                </p>
                <p class="text-h2 text-center" v-if="profitFinalPercentage">
                  {{ profitFinalPercentage }}%
                </p>
                <p class="text-center" v-else>הזינו נתונים כדי לחשב</p>
              </v-card>
            </v-col>
            <!-- <v-col cols="12" md="6" class="pa-2">
              <v-card class="pa-2" height="180" elevation="1">
                <h2 class="text-center mb-2">נתון נוסף</h2>
              </v-card>
            </v-col> -->
          </v-row>
        </div>

        <!-- <apexchart
          type="radialBar"
          height="350"
          :options="chartOptions"
          :series="[profitPercentage]"
        ></apexchart> -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
//import { plansCollection } from "../firebase";
// import VueApexCharts from "vue-apexcharts";

export default {
  name: "Insights",
  // components: { apexchart: VueApexCharts },
  data: () => ({
    numSales: 0,
    itemPrice: 0,
    itemCost: 0,
    fixedExpenses: 0,
    wantedPercentage: null,
    chartOptions: {
      chart: {
        height: 350,
        type: "radialBar",
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return val.toFixed(2) + "%";
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          // stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["אחוז רווחיות"],
    },
    items: [
      { title: "כמה לתמחר מוצר כדי להגיע לאחוז רווחיות מסוים", value: "" },
    ],
    inputRules: [
      (v) => (v && v >= 1) || "ערך תקין: 1 ומעלה",
      // (v) => (v && v <= 99) || "ערך תקין: 99 ומטה",
    ],
  }),
  computed: {
    totalIncome: function () {
      return this.numSales * (this.itemPrice || 0); 
    },
    salesCost: function () {
      return this.numSales * (this.itemCost || 0);
    },
    grossMargin: function () {
      return this.totalIncome - this.salesCost;
    },
    netProfit: function () {
      return this.grossMargin - this.fixedExpenses;
    },
    // profitPercentage: function () {
    //   return (this.netProfit / this.totalIncome) * 100;
    //   // return (this.netProfit / this.salesCost) * 100;
    // },
    profitPercentage: function () {
      return this.salesCost > 0 ? Number(((this.totalIncome / this.salesCost) * 100 - 100).toFixed(2)) : 0;

    },
    profitPercentageAfterExpenses: function () {
      const profitPercentageAfterExpenses = Number(((this.netProfit / this.salesCost) * 100).toFixed(2));
      return profitPercentageAfterExpenses
    },
    profitFinalPercentage: function () {
      return Number(((this.netProfit / this.totalIncome) * 100).toFixed(2));
    },
    passedDataFromRouter: function () {
      return {
        itemPrice: Number(this.$route.params.price) || 0,
        itemCost: Number(this.$route.params.cost) || 0,
      };
    },
    isValid: function () {
      if (this.wantedPercentage != null) {
        return this.$refs.wantedPercentage.valid;
      } else {
        return true;
      }
    },
  },
  methods: {
    calcProduct: function () {
      //what if over 100%
      //solve the issue of lower percent

      let i;
      if (this.wantedPercentage > this.profitPercentageAfterExpenses) {
        i = this.itemPrice;
      } else {
        i = 0;
        this.itemPrice = i;
      }

      console.log("start at: " + i);

      while (this.profitPercentageAfterExpenses < Math.round(this.wantedPercentage)) {
        this.itemPrice = i;
        console.log(i);
        i++;
      }
      console.log("Final: " + this.itemPrice);
    },
  },
  mounted: function () {
    if (this.passedDataFromRouter) {
      this.itemPrice = this.passedDataFromRouter.itemPrice;
      this.itemCost = this.passedDataFromRouter.itemCost;
      this.numSales = 1;
    }
  },
  watch: {
    passedDataFromRouter: function () {
      this.itemPrice = this.passedDataFromRouter.itemPrice;
      this.itemCost = this.passedDataFromRouter.itemCost;
      this.numSales = 1;
    },
  },
};
</script>

<style></style>
