<template>
  <v-container>
    <h2 class="mb-4">סיכום ביניים</h2>

    <v-simple-table class="mb-4 final-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 25%">פירוט</th>
            <th style="width: 25%">תרחיש שנתי</th>
            <th style="width: 25%">תרחיש שנתי מינימלי</th>
            <th style="width: 25%">תרחיש שנתי מקסימלי</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{typeText}} {{typeText == 'הוצאות' ? 'הקמה' : 'ראשוניות (חד פעמיות)'}}</td>
            <td>{{ calcInitial.toLocaleString() }}</td>
            <td>---</td>
            <td>---</td>
          </tr>
          <tr>
            <td>{{typeText}} קבועות</td>
            <td>{{ calcFixedRange['yearly'].toLocaleString() }}</td>
            <td>{{ calcFixedRange['minimum'].toLocaleString() }}</td>
            <td>{{ calcFixedRange['maximum'].toLocaleString() }}</td>
          </tr>
          <tr>
            <td>{{typeText}} משתנות</td>
            <td>{{ calcDynamicRange['yearly'].toLocaleString() }}</td>
            <td>{{ calcDynamicRange['minimum'].toLocaleString() }}</td>
            <td>{{ calcDynamicRange['maximum'].toLocaleString() }}</td>
          </tr>
          <tr>
            <td>כל ה{{typeText}}</td>
            <td>{{ calcAll['yearly'].toLocaleString() }}</td>
            <td>{{ calcAll['minimum'].toLocaleString() }}</td>
            <td>{{ calcAll['maximum'].toLocaleString() }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col cols="7">
        <h3 class="mb-4">מבט חודשי</h3>
        <apexchart width="700" type="bar" :options="options" :series="calcMonthlyForYearlyGraph"></apexchart>
      </v-col>
      <v-col cols="5">
        <h3 class="mb-4">פילוח {{typeText}}</h3>
        <apexchart
          width="500"
          type="donut"
          :options="donutOptions"
          :series="[calcInitial,calcFixed,calcDynamic]"
        ></apexchart>
      </v-col>
    </v-row>

    <!-- <hr class="mb-4" />
    <div style="direction: ltr">
      <p>{{ initialRowsData }}</p>
      <p>{{ fixedRowsData }}</p>
      <p>{{ dynamicRowsData }}</p>
    </div> -->
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Graphs",
  props: ["initialRowsData", "fixedRowsData", "dynamicRowsData", "typeText"],
  components: { apexchart: VueApexCharts },
  data: () => ({
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false
        },
        fontFamily: "Open Sans Hebrew, Helvetica, Arial"
      },
      xaxis: {
        categories: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12"
        ]
      },
      yaxis: {
        labels: {
          align: "center",
          formatter: value => {
            return Number(value).toLocaleString();
          }
        }
      },
      legend: {
        fontSize: "16px"
      }
    },
    series: [
      {
        name: "series-1",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      },
      {
        name: "series-2",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      }
    ],
    donutOptions: {
      // labels: [
      //   "הוצאות הקמה",
      //   "הוצאות קבועות",
      //   "הוצאות משתנות"
      // ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                fontSize: "16px"
              },
              value: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                formatter: value => {
                  return Number(value).toLocaleString();
                }
              }
            }
          }
        }
      },
      legend: {
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        fontSize: "16px"
      },
      dataLabels: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial"
        }
      },
      tooltip: {
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial"
        },
        y: {
          formatter: value => {
            return Number(value).toLocaleString();
          }
        }
      }
    }
  }),
  computed: {
    calcInitial: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.initialRowsData.reduce(
        (acc, current) => acc + Number(current["yearly"]),
        0
      );
    },
    calcFixed: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.fixedRowsData.reduce(
        (acc, current) => acc + Number(current["yearly"]),
        0
      );
    },
    calcDynamic: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.dynamicRowsData.reduce(
        (acc, current) => acc + Number(current["yearly"]),
        0
      );
    },
    calcAll: function() {
      return { 
          yearly: this.calcInitial + this.calcFixed + this.calcDynamic,
          minimum: this.calcInitial + this.calcFixedRange['minimum'] + this.calcDynamicRange['minimum'],
          maximum: this.calcInitial + this.calcFixedRange['maximum'] + this.calcDynamicRange['maximum'],
        };
    },
    calcFixedRange: function() {
      return {
        yearly: this.fixedRowsData.reduce(
          (acc, current) => acc + Number(current["yearly"]),
          0
        ),
        minimum: this.fixedRowsData.reduce(
          (acc, current) => acc + Number(current["minimum"]),
          0
        ),
        maximum: this.fixedRowsData.reduce(
          (acc, current) => acc + Number(current["maximum"]),
          0
        )
      };
    },
    calcDynamicRange: function() {
      return {
        yearly: this.dynamicRowsData.reduce(
          (acc, current) => acc + Number(current["yearly"]),
          0
        ),
        minimum: this.dynamicRowsData.reduce(
          (acc, current) => acc + Number(current["minimum"]),
          0
        ),
        maximum: this.dynamicRowsData.reduce(
          (acc, current) => acc + Number(current["maximum"]),
          0
        )
      };
    },
    calcMonthly: function() {
      return {
        fixed: this.fixedRowsData.reduce(
          (acc, current) => acc + Number(current["expense"]),
          0
        ),
        dynamic: this.dynamicRowsData.reduce(
          (acc, current) => acc + Number(current["expense"]),
          0
        )
      };
    },
    calcMonthlyForYearlyGraph: function() {
      let initialA = this.calcInitial;
      let fixed = [];
      let dynamic = [];

      for (let index = 0; index < 12; index++) {
        fixed[index] = this.calcMonthly.fixed;
        dynamic[index] = this.calcMonthly.dynamic;
      }

      //   return {
      //     fixedArray: fixed,
      //     dynamicArray: dynamic
      //   };

      return [
        {
          name: this.typeText + " קבועות",
          data: fixed
        },
        {
          name: this.typeText + " משתנות",
          data: dynamic
        },
        {
          name: this.typeText + (this.typeText == "הוצאות" 
          ? " הקמה" 
          : " ראשוניות (חד פעמיות)"),
          data: [initialA]
        }
      ];
    },
    donutLabels: function() {
      //console.log("donutLabels")
      if (this.typeText == "הוצאות") {
        return [
          "הוצאות הקמה",
          "הוצאות קבועות",
          "הוצאות משתנות"
        ]
      } else {
        return [
          "הכנסות ראשוניות",
          "הכנסות קבועות",
          "הכנסות משתנות"
        ]
      }
    }
  },
  mounted: function() {
    this.donutOptions = {
      labels: this.donutLabels
    }
  }
};
</script>

<style>
span.apexcharts-legend-marker {
  margin-left: 5px;
  margin-right: 5px;
}

.final-table td, .final-table th{
    font-size: 16px !important;
}
</style>
