<template>
  <div class="new-plan pa-5">
    <!-- <h1 class="mb-2">תחזית חדשה</h1> -->

    <div v-show="currentPath == 'intro'">
      <v-card class="mb-6 pa-3">
        <h2 class="my-6">הקדמה והגדרות</h2>
        <v-alert prominent dense type="info" color="primary" dark>
          <v-row align="center">
            <v-col class="grow">
              שימו לב לבחור את העסק המתאים בפינה השמאלית העליונה
            </v-col>
            <v-col class="shrink">
              <!-- <v-btn @click="$router.push('/profile')" color="white" rounded style="color: black;">ליצירת עסק חדש</v-btn> -->
              <v-btn
                @click="isCreatingNewBusiness = !isCreatingNewBusiness"
                color="white"
                rounded
                style="color: black;"
                >פעם ראשונה? צרו עסק חדש</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="planName" label="שם התכנית"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              type="number"
              v-model.number="expectedMonthlyprofit"
              label="רווח חודשי רצוי (לאחר מיסים והוצאות)"
            ></v-text-field>
          </v-col>
          <!-- <v-col cols="12" md="4">
          <v-select
            v-model="selectedBusiness"
            :items="businessOptions"
            item-text="title"
            item-value="value"
            label="שיוך לעסק"
          ></v-select>
        </v-col> -->
          <v-col cols="12" md="4">
            <v-select
              v-model="selectedCurrency"
              :items="currencyOptions"
              item-text="title"
              item-value="value"
              label="בחירת מטבע"
            ></v-select>
          </v-col>
        </v-row>

        <CreateNewBusiness v-if="isCreatingNewBusiness" />

        <v-btn
          @click="stepForward"
          class="add-row yazam-button-blue"
          v-if="!isPlanInEdit"
          >יצירת תכנית חדשה והמשך</v-btn
        >
        <v-btn @click="nextStep" class="add-row yazam-button-blue" v-else
          >שמור והמשך</v-btn
        >
      </v-card>
    </div>

    <div v-show="currentPath == 'vision'">
      <v-card class="mb-6 pa-3">
        <h1>תחזית חדשה - חזון ומטרות</h1>
        <v-spacer></v-spacer>
        <p>
          שם התכנית:
          <span
            v-if="$store.state.currentPlanName"
            style="text-decoration: underline"
            >{{ $store.state.currentPlanName }}</span
          >
          <span v-else>
            <!-- <a href="">יש למלא שם תכנית במסך הקדמה והגדרות</a> -->
            <router-link to="/new-plan/intro" style="text-decoration: underline"
              >יש למלא שם תכנית במסך הקדמה והגדרות</router-link
            >
          </span>
        </p>
        <p v-if="$store.state.currentUserData.role == 'superuser'">
          משויך לעסק:
          <span style="text-decoration: underline">{{
            $store.getters.currentBusinessName
          }}</span>
        </p>
      </v-card>

      <!-- <v-card elevation="1">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>חזון ומטרות</v-toolbar-title>
        </v-toolbar>
        <v-tabs vertical class="ml-8">
          <v-tab>
            <v-icon left> mdi-account </v-icon>
            שלב 1
          </v-tab>
          <v-tab>
            <v-icon left> mdi-lock </v-icon>
            שלב 2
          </v-tab>
          <v-tab>
            <v-icon left> mdi-access-point </v-icon>
            שלב 3
          </v-tab>

          <v-tab-item class="pa-2">
            <v-form ref="visionForm">
              <v-container>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-icon color="primary"> mdi-information-outline </v-icon>
                    <h3>כותרת</h3>
                    <p>הסבר על מקטע זה...</p>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-textarea
                      v-model="stepVision.q1"
                      label="שדה מילוי 1"
                      outlined
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-icon color="primary"> mdi-information-outline </v-icon>
                    <h3>כותרת</h3>
                    <p>הסבר על מקטע זה...</p>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-textarea
                      v-model="stepVision.q2"
                      label="שדה מילוי 1"
                      outlined
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-icon color="primary"> mdi-information-outline </v-icon>
                    <h3>כותרת</h3>
                    <p>הסבר על מקטע זה...</p>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-textarea
                      v-model="stepVision.q3"
                      label="שדה מילוי 1"
                      outlined
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-icon color="primary"> mdi-information-outline </v-icon>
                    <h3>כותרת</h3>
                    <p>הסבר על מקטע זה...</p>
                  </v-col>
                  <v-col cols="12" md="9">
                    <v-textarea
                      v-model="stepVision.q4"
                      label="שדה מילוי 1"
                      outlined
                      required
                    ></v-textarea>
                  </v-col>
                </v-row>

                <v-btn @click="test">שמירה ויצירת עסק חדש</v-btn>
              </v-container>
            </v-form>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Morbi nec metus. Suspendisse faucibus, nunc et pellentesque
                  egestas, lacus ante convallis tellus, vitae iaculis lacus elit
                  id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum
                  aliquam libero, non adipiscing dolor urna a orci. Curabitur
                  ligula sapien, tincidunt non, euismod vitae, posuere
                  imperdiet, leo. Nunc sed turpis.
                </p>

                <p>
                  Suspendisse feugiat. Suspendisse faucibus, nunc et
                  pellentesque egestas, lacus ante convallis tellus, vitae
                  iaculis lacus elit id tortor. Proin viverra, ligula sit amet
                  ultrices semper, ligula arcu tristique sapien, a accumsan nisi
                  mauris ac eros. In hac habitasse platea dictumst. Fusce ac
                  felis sit amet ligula pharetra condimentum.
                </p>

                <p>
                  Sed consequat, leo eget bibendum sodales, augue velit cursus
                  nunc, quis gravida magna mi a libero. Nam commodo suscipit
                  quam. In consectetuer turpis ut velit. Sed cursus turpis vitae
                  tortor. Aliquam eu nunc.
                </p>

                <p>
                  Etiam ut purus mattis mauris sodales aliquam. Ut varius
                  tincidunt libero. Aenean viverra rhoncus pede. Duis leo. Fusce
                  fermentum odio nec arcu.
                </p>

                <p class="mb-0">
                  Donec venenatis vulputate lorem. Aenean viverra rhoncus pede.
                  In dui magna, posuere eget, vestibulum et, tempor auctor,
                  justo. Fusce commodo aliquam arcu. Suspendisse enim turpis,
                  dictum sed, iaculis a, condimentum nec, nisi.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <p>
                  Fusce a quam. Phasellus nec sem in justo pellentesque
                  facilisis. Nam eget dui. Proin viverra, ligula sit amet
                  ultrices semper, ligula arcu tristique sapien, a accumsan nisi
                  mauris ac eros. In dui magna, posuere eget, vestibulum et,
                  tempor auctor, justo.
                </p>

                <p class="mb-0">
                  Cras sagittis. Phasellus nec sem in justo pellentesque
                  facilisis. Proin sapien ipsum, porta a, auctor quis, euismod
                  ut, mi. Donec quam felis, ultricies nec, pellentesque eu,
                  pretium quis, sem. Nam at tortor in tellus interdum sagittis.
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-card> -->

      <v-card class="pa-4 mt-6" elevation="1">
        <!-- v-model="vision" -->
        <v-form ref="visionForm">
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מה החזון של העסק?</h3>
                <p>מה תחום הפעילות של העסק? לאן תרצו להגיע בעוד 5 שנים?</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q1"
                  label="מה החזון של העסק?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מהי הבעיה הקיימת בשוק?</h3>
                <p>
                  מה קורה כיום בשוק? מהו הצורך העיקרי עליו אנחנו רוצים לענות
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q2"
                  label="מהי הבעיה הקיימת בשוק?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מהו הפתרון שאנחנו מציעים?</h3>
                <p>
                  מה אנחנו בעצם רוצים להציג ללקוחות שיפתור את הבעיות שיש להם
                  כיום
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q3"
                  label="מהו הפתרון שאנחנו מציעים?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מהו המודל העסקי עליו חשבנו?</h3>
                <p>
                  תשלום חד פעמי, תשלום חודשי קבוע, תשלום מקהל היעד בצורה ישירה
                  או עקיפה
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q4"
                  label="מהו המודל העסקי עליו חשבנו?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מה מיוחד בחברה שלנו?</h3>
                <p>
                  במה אנחנו שונים מחברות נוספות? האם יש לנו ייחודיות כלשהי?
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q5"
                  label="מה מיוחד בחברה שלנו?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>כותרת</h3>
                <p>הסבר על מקטע זה...</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q4"
                  label="שדה מילוי 1"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row> -->

            <!-- <v-btn @click="test">שמירה ויצירת עסק חדש</v-btn> -->
            <v-btn @click="nextStep" class="add-row yazam-button-blue"
              >שמור והמשך</v-btn
            >
          </v-container>
        </v-form>
      </v-card>
    </div>
    <div v-show="currentPath == 'research'">
      <v-card class="mb-6 pa-3">
        <h1>תחזית חדשה - מחקר שוק והיבטים משפטיים</h1>
        <v-spacer></v-spacer>
        <p>
          שם התכנית:
          <span
            v-if="$store.state.currentPlanName"
            style="text-decoration: underline"
            >{{ $store.state.currentPlanName }}</span
          >
          <span v-else>
            <!-- <a href="">יש למלא שם תכנית במסך הקדמה והגדרות</a> -->
            <router-link to="/new-plan/intro" style="text-decoration: underline"
              >יש למלא שם תכנית במסך הקדמה והגדרות</router-link
            >
          </span>
        </p>
        <p v-if="$store.state.currentUserData.role == 'superuser'">
          משויך לעסק:
          <span style="text-decoration: underline">{{
            $store.getters.currentBusinessName
          }}</span>
        </p>
      </v-card>

      <v-alert
        prominent
        dense
        type="info"
        color="primary"
        dark
        class="yazam-sticky"
      >
        <v-row align="center">
          <v-col class="grow">
            לא בטוחים מה למלא? לתיאום ייעוץ עם מומחה במחקר שוק לחצו כאן
          </v-col>
          <v-col class="shrink">
            <!-- <v-btn @click="$router.push('/profile')" color="white" rounded style="color: black;">ליצירת עסק חדש</v-btn> -->
            <v-btn
              @click="getOffer('research')"
              color="white"
              rounded
              style="color: black;"
            >
              <span v-if="offerSent">
                ✓ בקשתך התקבלה
              </span>
              <span v-else> אשמח לתאם ייעוץ עם מומחה</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-card class="pa-4 mt-6" elevation="1">
        <!-- v-model="vision" -->
        <v-form ref="visionForm">
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מהו גודל השוק אליו אנחנו מכוונים?</h3>
                <p>
                  - היקף פעילות השוק בעולם
                  <br />
                  - היקף פעילות השוק מקומית
                  <br />
                  - פוטנציאל גידול השוק
                  <br />
                  - מגמות חברתיות שצריך להתייחס אליהם (מלחמה, מגפה וכו׳)
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepResearch.q1"
                  label="מהו גודל השוק אליו אנחנו מכוונים?"
                  rows="6"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מיהם המתחרים העיקריים שיש לנו?</h3>
                <p>
                  - שם החברה, שנת הקמה
                  <br />
                  - אתר אינטרנט
                  <br />
                  - מידע כללי
                  <br />
                  - יתרונות
                  <br />
                  - חסרונות
                  <br />
                  - מחזור מכירות
                  <br />
                  - גודל שוק
                  <br />
                  - סוגי לקוחות אליהם פונים
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepResearch.q2"
                  label="מיהם המתחרים העיקריים שיש לנו?"
                  rows="9"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מהם המחירים כיום בשוק לשירות דומה לשלנו?</h3>
                <p>
                  - טווח מחירים שיש בשוק היום
                  <br />
                  - מה המחיר כולל? שווי לקוח?
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepResearch.q3"
                  label="מהם המחירים כיום בשוק לשירות דומה לשלנו?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>כמה עסקים דומים לשלי נפתחו/נסגרו ב-3 שנים האחרונות?</h3>
                <!-- <p>
                  תשלום חד פעמי, תשלום חודשי קבוע, תשלום מקהל היעד בצורה ישירה
                  או עקיפה
                </p> -->
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepResearch.q4"
                  label="כמה עסקים דומים לשלי נפתחו/נסגרו ב-3 שנים האחרונות?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <hr class="mb-4" />
            <h2>היבטים משפטיים</h2>

            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>האם קיימים הגבלים משפטיים שעלינו לקחת בחשבון?</h3>
                <!-- <p>
                  במה אנחנו שונים מחברות נוספות? האם יש לנו ייחודיות כלשהי?
                </p> -->
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepResearch.q5"
                  label="האם קיימים הגבלים משפטיים שעלינו לקחת בחשבון?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>
                  האם אנחנו צריכים לקבל אישור/רישיון מסוים בשביל לשווק, למכור
                  ולהפעיל את העסק? במידה וכן, מה עלות הרישיון?
                </h3>
                <!-- <p>
                  במה אנחנו שונים מחברות נוספות? האם יש לנו ייחודיות כלשהי?
                </p> -->
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepResearch.q6"
                  label="האם אנחנו צריכים לקבל אישור/רישיון מסוים בשביל לשווק, למכור ולהפעיל את העסק? במידה וכן, מה עלות הרישיון?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>
                  האם צריך להקים ישות משפטית מקומית/בינלאומית (הקמת חברה
                  מקומית/בינלאומית)?
                </h3>
                <!-- <p>
                  במה אנחנו שונים מחברות נוספות? האם יש לנו ייחודיות כלשהי?
                </p> -->
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepResearch.q7"
                  label="האם צריך להקים ישות משפטית מקומית/בינלאומית (הקמת חברה מקומית/בינלאומית)?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- <v-btn @click="test">שמירה ויצירת עסק חדש</v-btn> -->
            <v-btn @click="nextStep" class="add-row yazam-button-blue"
              >שמור והמשך</v-btn
            >
          </v-container>
        </v-form>
      </v-card>
    </div>

    <div v-show="currentPath == 'people'">
      <v-card class="mb-6 pa-3">
        <h1>תחזית חדשה - בעלי תפקידים</h1>
        <v-spacer></v-spacer>
        <p>
          שם התכנית:
          <span
            v-if="$store.state.currentPlanName"
            style="text-decoration: underline"
            >{{ $store.state.currentPlanName }}</span
          >
          <span v-else>
            <!-- <a href="">יש למלא שם תכנית במסך הקדמה והגדרות</a> -->
            <router-link to="/new-plan/intro" style="text-decoration: underline"
              >יש למלא שם תכנית במסך הקדמה והגדרות</router-link
            >
          </span>
        </p>
        <p v-if="$store.state.currentUserData.role == 'superuser'">
          משויך לעסק:
          <span style="text-decoration: underline">{{
            $store.getters.currentBusinessName
          }}</span>
        </p>
      </v-card>

      <v-card class="pa-4 mt-6" elevation="1">
        <p style="font-size: 18px">
          הכל מתחיל ונגמר בניהול נכון. לא משנה איזה מוצר או שירות יש לנו, ללא
          הניהול הנכון, העסק שלנו לא באמת יתרומם ויצליח.
          <br /><br />
          אז מי הולך לעבוד בעסק שלנו ולגרום לו לעבוד באמת?
        </p>
        <v-simple-table dense fixed-header :height="teamMembersTableHeight">
          <template v-slot:default>
            <thead>
              <tr>
                <th></th>
                <th>שם</th>
                <th>תפקיד</th>
                <th>הגדרת תפקיד</th>
                <th>חוזקות</th>
                <th>רקע מקצועי</th>
                <!-- <th style="width: 20%">פירוט</th>
                <th style="width: 15%" v-if="!isInitial">חודש פעילות להתחלה</th>
                <th style="width: 20%">הערות</th>
                <th style="width: 25%">מבט שנתי / תקופתי</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, order) in countTeamMembers" :key="item">
                <td>
                  <v-btn @click="deleteRow(order)" color="red" icon x-small
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </td>
                <td>
                  <v-textarea
                    v-model="teamMembers[item - 1]['name']"
                    label="שם"
                    rows="3"
                    outlined
                    hide-details
                  ></v-textarea>
                </td>
                <td>
                  <v-textarea
                    v-model="teamMembers[item - 1]['role']"
                    label="תפקיד"
                    rows="3"
                    outlined
                    hide-details
                  ></v-textarea>
                </td>
                <td class="py-2">
                  <v-textarea
                    v-model="teamMembers[item - 1]['roleDesc']"
                    label="הגדרת תפקיד"
                    rows="3"
                    outlined
                    hide-details
                  ></v-textarea>
                </td>
                <td class="py-2">
                  <v-textarea
                    v-model="teamMembers[item - 1]['advantage']"
                    label="חוזקות"
                    rows="3"
                    outlined
                    hide-details
                  ></v-textarea>
                </td>
                <td class="py-2">
                  <v-textarea
                    v-model="teamMembers[item - 1]['background']"
                    label="רקע מקצועי"
                    rows="3"
                    outlined
                    hide-details
                  ></v-textarea>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-btn @click="addTeamMember" class="add-row yazam-button-blue ml-2"
          >הוספת איש צוות</v-btn
        >
        <v-btn @click="nextStep" class="add-row yazam-button-blue"
          >שמור והמשך</v-btn
        >
      </v-card>
    </div>

    <div v-show="currentPath == 'marketing'">
      <v-card class="mb-6 pa-3">
        <h1>תחזית חדשה - שיווק ומכירות</h1>
        <v-spacer></v-spacer>
        <p>
          שם התכנית:
          <span
            v-if="$store.state.currentPlanName"
            style="text-decoration: underline"
            >{{ $store.state.currentPlanName }}</span
          >
          <span v-else>
            <!-- <a href="">יש למלא שם תכנית במסך הקדמה והגדרות</a> -->
            <router-link to="/new-plan/intro" style="text-decoration: underline"
              >יש למלא שם תכנית במסך הקדמה והגדרות</router-link
            >
          </span>
        </p>
        <p v-if="$store.state.currentUserData.role == 'superuser'">
          משויך לעסק:
          <span style="text-decoration: underline">{{
            $store.getters.currentBusinessName
          }}</span>
        </p>
      </v-card>

      <v-card class="pa-4 mt-6" elevation="1">
        <!-- v-model="vision" -->
        <v-form ref="visionForm" @submit.prevent="formSubmitHandler">
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>למי אנחנו מתכננים לשווק ולמכור? מיהו קהל היעד שלנו?</h3>
                <p>B2B / B2C / שילוב של שניהם - לפרט</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepMarketing.q1"
                  label="למי אנחנו מתכננים לשווק ולמכור? מיהו קהל היעד שלנו?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מהו פרופיל הלקוח שלנו?</h3>
                <p>מין, גיל, מיקום, סטטוס אישי, רמת הכנסה, תחומי עניין</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepMarketing.q2"
                  label="מהו פרופיל הלקוח שלנו?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>איך אנחנו מתכוונים למכור את המוצרים שלנו?</h3>
                <p>אתר אינטרנט / מפגשים אישיים / חנות פיזית / שעות עבודה</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepMarketing.q3"
                  label="איך אנחנו מתכוונים למכור את המוצרים שלנו?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>מה מיוחד במוצר / שירות שלנו?</h3>
                <p>יש לנו יתרון יחסי כלשהו?</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepMarketing.q4"
                  label="מה מיוחד במוצר / שירות שלנו?"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <hr class="mb-4" />

            <h2>הזנת מקורות מכירה</h2>
            <p>
              דרך אילו מקורות אנחנו עשויים למכור? הכוונה לערוצי פרסום כמו רשתות
              חברתיות, קמפיינים שיווקיים וכן משווקים ספציפיים
            </p>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="currentSaleSource"
                  label="הזנת מקורות מכירה"
                  @keyup.enter.prevent="addSaleSource($event)"
                ></v-text-field>
                <v-chip
                  v-for="(item, i) in saleSources"
                  :key="i"
                  close
                  @click:close="deleteSaleSource(i)"
                  outlined
                  label
                  color="primary"
                  class="ma-1"
                  >{{ item.name }}</v-chip
                >
                <v-simple-table
                  fixed-header
                  height="300px"
                  class="elevation-1 mt-2"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>מקור המכירה</th>
                        <th style="width: 50%">עלות למכירה</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in saleSources" :key="index">
                        <td>{{ item.name }}</td>
                        <td>
                          <!-- @input="inputChanged('cost',item,index,$event)" -->
                          <v-text-field
                            label="הזינו נתון"
                            type="number"
                            v-model.number="item.cost"
                            :append-icon="`mdi-currency-${selectedCurrency}`"
                            dense
                            flat
                            hide-details
                            rounded
                            solo-inverted
                            class="input-icon-switch"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>

            <hr class="my-4" />
            <h2>בניית מודל SWOT</h2>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>ציין 5 חוזקות שיש לנו</h3>
                <p>במה יש לנו יתרון? במה אנחנו הכי טובים?</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepMarketing.q5"
                  label="חוזקות"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>ציין 5 חולשות שיש לנו</h3>
                <p>
                  במה יש לנו חיסרון? ידע / טכנולוגיה / מימון כספי / זמן – כל מה
                  שקשרו אלינו באופן אישי בעסק
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepMarketing.q6"
                  label="חולשות"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>ציין 5 הזדמנויות שיש לנו</h3>
                <p>
                  האם יש שוק חדש שעדיין לא עובד בתחום העיסוק שלנו? האם קיים משבר
                  כלשהי שמעניק לנו יתרון משמעותי היום?
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepMarketing.q7"
                  label="הזדמנויות"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>ציין 5 איומים חיצוניים שיש לנו</h3>
                <p>
                  מי מאיים על העסק שלנו? מתחרה דומיננטי בשוק? משבר כלשהו שיכול
                  למוטט לנו את העסק? ממה עלינו להיזהר ולקחת בחשבון לפני שאנחנו
                  יוצאים לדרך?
                </p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepMarketing.q8"
                  label="איומים"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- <v-btn @click="test">שמירה ויצירת עסק חדש</v-btn> -->
            <v-btn @click="nextStep" class="add-row yazam-button-blue"
              >שמור והמשך</v-btn
            >
          </v-container>
        </v-form>
      </v-card>
    </div>

    <div v-show="currentPath == 'expenses'">
      <v-card class="mb-6 pa-3">
        <h1>תחזית חדשה - הוצאות</h1>
        <v-spacer></v-spacer>
        <p>
          שם התכנית:
          <span
            v-if="$store.state.currentPlanName"
            style="text-decoration: underline"
            >{{ $store.state.currentPlanName }}</span
          >
          <span v-else>
            <!-- <a href="">יש למלא שם תכנית במסך הקדמה והגדרות</a> -->
            <router-link to="/new-plan/intro" style="text-decoration: underline"
              >יש למלא שם תכנית במסך הקדמה והגדרות</router-link
            >
          </span>
        </p>
        <p v-if="$store.state.currentUserData.role == 'superuser'">
          משויך לעסק:
          <span style="text-decoration: underline">{{
            $store.getters.currentBusinessName
          }}</span>
        </p>

        <p>
          כידוע לכם, כל עסק מורכב מהוצאות והכנסות. האופן שבו תוכלו לבנות מערך
          מכירות לעסק שלכם, תלוי ברובו במספר ההוצאות שלכם. כך נוכל לבצע בדיקת
          היתכנות ולהבין האם העסק באמת כדאי ורווחי עבורינו.
        </p>
      </v-card>
      <ExpensesOnly />
    </div>

    <div v-show="currentPath == 'income'">
      <v-card class="mb-6 pa-3">
        <h1>תחזית חדשה - הכנסות</h1>
        <v-spacer></v-spacer>
        <p>
          שם התכנית:
          <span
            v-if="$store.state.currentPlanName"
            style="text-decoration: underline"
            >{{ $store.state.currentPlanName }}</span
          >
          <span v-else>
            <!-- <a href="">יש למלא שם תכנית במסך הקדמה והגדרות</a> -->
            <router-link to="/new-plan/intro" style="text-decoration: underline"
              >יש למלא שם תכנית במסך הקדמה והגדרות</router-link
            >
          </span>
        </p>
        <p v-if="$store.state.currentUserData.role == 'superuser'">
          משויך לעסק:
          <span style="text-decoration: underline">{{
            $store.getters.currentBusinessName
          }}</span>
        </p>
      </v-card>
      <IncomeOnly />
    </div>

    <div v-show="currentPath == 'taxes'">
      <v-card class="mb-6 pa-3">
        <h1>תחזית חדשה - מיסים</h1>
        <v-spacer></v-spacer>
        <p>
          שם התכנית:
          <span
            v-if="$store.state.currentPlanName"
            style="text-decoration: underline"
            >{{ $store.state.currentPlanName }}</span
          >
          <span v-else>
            <!-- <a href="">יש למלא שם תכנית במסך הקדמה והגדרות</a> -->
            <router-link to="/new-plan/intro" style="text-decoration: underline"
              >יש למלא שם תכנית במסך הקדמה והגדרות</router-link
            >
          </span>
        </p>
        <p v-if="$store.state.currentUserData.role == 'superuser'">
          משויך לעסק:
          <span style="text-decoration: underline">{{
            $store.getters.currentBusinessName
          }}</span>
        </p>
      </v-card>

      <v-card class="pa-4 mt-6" elevation="1">
        <!-- v-model="vision" -->
        <v-form ref="visionForm">
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>כותרת</h3>
                <p>הסבר על מקטע זה...</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q1"
                  label="שדה מילוי 1"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>כותרת</h3>
                <p>הסבר על מקטע זה...</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q2"
                  label="שדה מילוי 1"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>כותרת</h3>
                <p>הסבר על מקטע זה...</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q3"
                  label="שדה מילוי 1"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-icon color="primary"> mdi-information-outline </v-icon>
                <h3>כותרת</h3>
                <p>הסבר על מקטע זה...</p>
              </v-col>
              <v-col cols="12" md="9">
                <v-textarea
                  v-model="stepVision.q4"
                  label="שדה מילוי 1"
                  outlined
                  required
                ></v-textarea>
              </v-col>
            </v-row>

            <!-- <v-btn @click="test">שמירה ויצירת עסק חדש</v-btn> -->
            <v-btn @click="nextStep" class="add-row yazam-button-blue"
              >שמור והמשך</v-btn
            >
          </v-container>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import ExpensesOnly from "@/components/ExpensesOnly";
import IncomeOnly from "@/components/IncomeOnly";
import CreateNewBusiness from "@/components/CreateNewBusiness";
import { plansCollection } from "../firebase";

export default {
  name: "NewPlan",
  components: {
    ExpensesOnly,
    IncomeOnly,
    CreateNewBusiness,
  },
  data: () => ({
    months: 12,
    showReview: false,

    planName: "",
    expectedMonthlyprofit: null,
    isCreatingNewBusiness: false,
    businessOptions1: [
      {
        title: "העסק של רועי",
        value: "123456789",
      },
    ],
    currencyOptions: [
      {
        title: "שקל",
        value: "ils",
      },
      { title: "דולר אמריקאי", value: "usd" },
    ],
    selectedBusiness: null,
    selectedCurrency: "ils",

    currentPlanID: null,
    savedSuccess: true,

    stepVision: {
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
    },

    stepResearch: {
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      q5: "",
      q6: "",
      q7: "",
    },

    stepMarketing: {
      q1: "",
      q2: "",
      q3: "",
      q4: "",
    },

    saleSources: [],
    currentSaleSource: "",

    countTeamMembers: 1,
    teamMembersTableHeight: 180,
    teamMembers: [
      {
        name: "",
        role: "",
        roleDesc: "",
        advantage: "",
        background: "",
      },
    ],
    offerSent: false,
  }),
  methods: {
    stepForward: function() {
      if (this.planName.length != "") {
        this.$router.push("/new-plan/vision");
        this.saveInitialData();
      } else {
        alert("חובה למלא שם תכנית");
      }
    },
    nextStep: function() {
      switch (this.currentPath) {
        case "vision":
          this.saveTempData("vision");
          if (this.savedSuccess) {
            this.$router.push("/new-plan/research");
          }
          break;
        case "research":
          this.saveTempData("research");
          if (this.savedSuccess) {
            this.$router.push("/new-plan/people");
          }
          break;
        case "people":
          this.saveTempData("people");
          if (this.savedSuccess) {
            this.$router.push("/new-plan/marketing");
          }
          break;
        case "marketing":
          this.saveTempData("marketing");
          if (this.savedSuccess) {
            this.$router.push("/new-plan/expenses");
          }
          break;
        case "intro":
          this.saveTempData("intro");
          if (this.savedSuccess) {
            this.$router.push("/new-plan/vision");
          }
          break;
        case "taxes":
          // move to Pension and Deposits
          // this.$router.push("/new-plan/expenses");
          console.log("move to Pension and Deposits");
          break;

        default:
          this.$router.push("/new-plan/vision");
          break;
      }
    },
    saveTempData: function(dataType) {
      switch (dataType) {
        case "vision":
          this.$store.commit("setTempVisionData", this.stepVision);
          this.saveMoreData("stepVision", this.stepVision);
          break;

        case "research":
          this.$store.commit("setTempResearchData", this.stepResearch);
          this.saveMoreData("stepResearch", this.stepResearch);
          break;

        case "people":
          this.$store.commit("setTempteamMembersData", this.teamMembers);
          this.saveMoreData("teamMembers", this.teamMembers);
          break;

        case "marketing":
          this.$store.commit("setTempMarketingData", this.stepMarketing);
          this.$store.commit("setTempsaleSources", this.saleSources);
          this.saveMoreData("stepMarketing", this.stepMarketing);
          this.saveMoreData("saleSources", this.saleSources);
          break;
        case "intro":
          this.saveMoreData("planName", this.$store.state.currentPlanName);
          this.saveMoreData(
            "expectedMonthlyprofit",
            this.expectedMonthlyprofit
          );
          break;

        default:
          break;
      }
      // this.$store.commit("setTempPlanData", {
      //   vision: this.stepVision,
      //   marketing: this.stepMarketing,
      //   teamMembers: this.teamMembers,
      // });
    },
    test: function() {
      console.log(this.stepVision);
      console.log(this.stepVision.q1);
      console.log(this.stepVision.q2);
    },
    addTeamMember: function() {
      this.countTeamMembers++;
      (this.teamMembersTableHeight += 150),
        this.teamMembers.push({
          name: "",
          role: "",
          roleDesc: "",
          advantage: "",
          background: "",
        });
    },
    async saveInitialData() {
      if (
        this.$store.state.currentPlanName &&
        this.$store.state.currentBusiness
      ) {
        const savedPlan = await plansCollection.add({
          planName: this.$store.state.currentPlanName,
          business: this.$store.state.currentBusiness,
          user: this.$store.state.currentUser,
          expectedMonthlyprofit: this.expectedMonthlyprofit,
        });

        console.log("savedPlan");
        console.log(savedPlan.id);
        this.currentPlanID = savedPlan.id;
        this.$store.commit("setCurrentPlanID", this.currentPlanID);
      } else {
        alert("לא ניתן לשמור אם לא מוגדר שם לתכנית או משויך עסק");
      }

      //this.e1++;
    },
    async saveMoreData(name, payload) {
      let currentPlanIDfromStore = this.$store.state.currentPlanID;
      //currentPlanID = "i1mVD3nh7m013D8nuaZJ";
      console.log("currentPlanID: " + currentPlanIDfromStore);

      // was this.currentPlanID in the if and in .doc
      if (currentPlanIDfromStore) {
        await plansCollection
          .doc(currentPlanIDfromStore)
          .update({
            [name]: payload,
          })
          .then(console.log("Saved More data"));
        this.savedSuccess = true;
      } else {
        this.savedSuccess = false;
        alert("לא ניתן לשמור, לא משויכת תכנית עסקית");
      }
    },
    addSaleSource(e) {
      e.preventDefault();
      if (this.currentSaleSource.length > 0) {
        this.saleSources.push({
          name: this.currentSaleSource,
          cost: 0,
        });
        this.currentSaleSource = "";
      }
    },
    deleteSaleSource(i) {
      this.saleSources.splice(i, 1);
    },
    updateDataFromStoreEditMode() {
      console.log("updateDataFromStoreEditMode ran from SplitNewPlan");
      let editedPlan = this.$store.state.editedPlanData;
      this.currentPlanID = this.$store.state.currentPlanID;

      this.expectedMonthlyprofit = editedPlan?.expectedMonthlyprofit
        ? editedPlan?.expectedMonthlyprofit
        : null;
      this.planName = editedPlan?.planName ? editedPlan?.planName : "";
      this.stepVision = editedPlan?.stepVision
        ? editedPlan?.stepVision
        : {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
          };
      this.stepResearch = editedPlan?.stepResearch
        ? editedPlan?.stepResearch
        : {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            q6: "",
            q7: "",
          };
      this.stepMarketing = editedPlan?.stepMarketing
        ? editedPlan?.stepMarketing
        : {
            q1: "",
            q2: "",
            q3: "",
            q4: "",
          };
      this.saleSources = editedPlan?.saleSources ? editedPlan?.saleSources : [];
      this.teamMembers = editedPlan?.teamMembers
        ? editedPlan?.teamMembers
        : [
            {
              name: "",
              role: "",
              roleDesc: "",
              advantage: "",
              background: "",
            },
          ];
    },
    deleteRow: function(order) {
      if (confirm("נא לאשר מחיקת שורה זו") == true) {
        this.teamMembers.splice(order, 1);
        this.countTeamMembers--;
      }
    },
    getOffer(topic) {
      fetch("https://hook.eu1.make.com/2hbgh6763weaq4e7ak4qg1pylqvxrk8f", {
        method: "POST",
        body: JSON.stringify({
          userId: this.$store.state.currentUser,
          userData: this.$store.state.currentUserData,
          services: [topic],
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      this.offerSent = true;
    },
    formSubmitHandler() {
      console.log("vision form triggred - not relevant")
    }
  },
  computed: {
    currentPath: function() {
      if (this.$route.path == "/new-plan/expenses") {
        return "expenses";
      } else if (this.$route.path == "/new-plan/income") {
        return "income";
      } else if (this.$route.path == "/new-plan/vision") {
        return "vision";
      } else if (this.$route.path == "/new-plan/research") {
        return "research";
      } else if (this.$route.path == "/new-plan/people") {
        return "people";
      } else if (this.$route.path == "/new-plan/marketing") {
        return "marketing";
      } else if (this.$route.path == "/new-plan/taxes") {
        return "taxes";
      } else {
        return "intro";
      }
    },
    businessOptions: function() {
      let data = this.$store.state.userBusiness;
      let fixed = [];

      if (data) {
        data.forEach((item) => {
          fixed.push({
            title: item.data.businessname,
            value: item.id,
          });
        });
      }

      return fixed;
    },
    isPlanInEdit: function() {
      if (Object.keys(this.$store.state.editedPlanData).length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created: function() {
    console.log("Created dispatch loadAllBusiness");
    this.$store.dispatch("loadAllBusiness");

    if (this.isPlanInEdit) {
      this.updateDataFromStoreEditMode();
    }
  },
  watch: {
    planName: function() {
      this.$store.commit("setCurrentPlanName", this.planName);
    },

    selectedBusiness: function(value) {
      console.log(value);
      this.$store.commit("setCurrentBusiness", value);
    },

    // saleSources: function () {
    //   if (this.currentPath == "marketing") {
    //     console.log("saleSources watched, saved in temp data in store");
    //     this.$store.commit("setTempsaleSources", this.saleSources);
    //   }
    // },

    saleSources: {
      handler(val) {
        if (this.currentPath == "marketing") {
          console.log("saleSources watched, saved in temp data in store");
          console.log(val);
          this.$store.commit("setTempsaleSources", this.saleSources);
        }
      },
      deep: true,
    },
    isPlanInEdit: function(value) {
      console.log("watched isPlanInEdit: ", value);
      if (value) {
        this.updateDataFromStoreEditMode();
      }
    },
  },
};
</script>

<style>
button.add-row {
  margin-top: 10px;
  font-size: 16px;
  letter-spacing: 0px;
  background-color: #2953e8 !important;
}

.yazam-sticky {
  position: sticky;
  top: 70px;
  z-index: 1;
}
</style>
