<template>
  <div class="login pa-6">
    <div class="inner-box">
      <HelloWorld />
      <h2 class="display-1 font-weight-bold mb-3">
        הרשמה למערכת
      </h2>
      <v-form @submit.prevent="register">
        <v-text-field
          v-model="name"
          label="שם"
          required
          name="name"
        ></v-text-field>
        <v-text-field
          v-model="email"
          label="שם משתמש"
          required
          name="user"
        ></v-text-field>
        <!-- type="password" -->
        <v-text-field
          v-model="password"
          label="סיסמה"
          required
          name="password"
        ></v-text-field>
        <v-btn type="submit" color="#2953e8" class="yazam-button-blue"
          >הרשמה</v-btn
        >
      </v-form>

      <v-alert prominent dense type="success" dark class="mt-3" v-if="finished">
        <v-row align="center">
          <v-col class="grow">
            המשתמש נוצר בהצלחה
          </v-col>
        </v-row>
      </v-alert>
    </div>
  </div>
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";
import { auth } from "../firebase";
import { usersCollection } from "../firebase";

export default {
  name: "Register",
  components: {
    HelloWorld,
  },
  data: () => ({
    name: "",
    email: "",
    password: "",
    user: null,
    finished: false,
  }),
  methods: {
    register: function() {
      auth
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          // console.log("user:", user.user);
          console.log("user uid:", user.user.uid);
          return user.user.uid;
          // this.user = user;
          // this.$store.dispatch("setCurrentUser");
          //this.$store.dispatch("setCurrentUserData");
          // this.$store.dispatch("loadAllPlans");
          // this.$store.dispatch("loadAllBusiness");
          // this.$router.push("/");
        })
        .then((uid) => {
          console.log("step 2, uid: ", uid);
          usersCollection.doc(uid).set({
            name: this.name,
            email: this.email,
            role: "user",
          });
        })
        .then(() => {
          this.finished = true;
          this.name = "";
          this.email = "";
          this.password = "";
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted: function() {
    // ?email=roy@wisite.co.il&password=1234
    console.log("query name:", this.$route.query.name);
    console.log("query email:", this.$route.query.email);
    console.log("query password:", this.$route.query.password);
    this.name = this.$route.query.name;
    this.email = this.$route.query.email;
    this.password = this.$route.query.password;
  },
};
</script>

<style scoped>
/* .v-main {
  background-image: url("https://yazam.app/wp-content/uploads/2022/06/yazam-bg.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 !important;
}

.container {
  padding: 0 !important;
}

.row > .col {
  padding: 0;
}

.login {
  background-color: #ffffff;
  width: 500px;
  height: 100vh;
} */

.login {
  background-image: url("https://yazam.app/wp-content/uploads/2022/06/yazam-bg.jpg");
  object-fit: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 !important;
  margin-top: -30px;
  margin-block: -25px;
}

.inner-box {
  background-color: #ffffff;
  width: 500px;
  height: 100vh;
  padding: 30px;
}

.v-application .display-1 {
  font-family: "Open Sans Hebrew" !important;
  text-align: center;
}
</style>
