<template>
  <div class="home">
    <v-container>
      <v-row>
        <v-col cols="12" md="4" class="pa-2">
          <v-card class="pa-2" height="300" elevation="1">
            <h2>הפעולות שלי</h2>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>תחום</th>
                    <th>פעולות</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>תוכניות עסקיות</td>
                    <td class="text-left">
                      <v-btn
                        to="/new-plan/intro"
                        color="#2953E8"
                        class="yazam-button-blue"
                        width="120"
                        >תכנית חדשה</v-btn
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>העסקים שלי</td>
                    <td class="text-left">
                      <v-btn
                        to="/profile"
                        color="#2953E8"
                        class="yazam-button-blue"
                        width="120"
                        >עסק חדש</v-btn
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>מצא שותף לדרך</td>
                    <td class="text-left">
                      <v-btn
                        color="#2953E8"
                        class="yazam-button-blue"
                        width="120"
                        >בקרוב</v-btn
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>מודל תמחור</td>
                    <td class="text-left">
                      <v-btn
                        to="/insights"
                        color="#2953E8"
                        class="yazam-button-blue"
                        width="120"
                        >בדיקת מודל</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pa-2">
          <v-card class="pa-2" height="300" elevation="1">
            <h2>העסקים שלי</h2>
            <v-simple-table height="250">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>שם העסק</th>
                    <th>תחום פעילות</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in $store.state.userBusiness"
                    :key="index"
                  >
                    <td class="table-name">{{ item.data.businessname }}</td>
                    <td class="table-name">
                      {{ item.data.selectedBusinessField }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" class="pa-2">
          <v-card class="pa-2" height="300" elevation="1">
            <h2>המוצרים שלי</h2>
            <!-- <ul>
              <li v-for="(prod,i) in products" :key="i">
                {{prod}}
              </li>

            </ul>-->
            <v-simple-table height="250">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>פירוט</th>
                    <th>עלות לעסק</th>
                    <th>סכום המכירה</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in products" :key="index">
                    <td class="table-name">{{ item.name }}</td>
                    <td class="table-name">{{ item.cost }}</td>
                    <td class="table-name">{{ item.price }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" class="pa-2">
          <v-card class="pa-2" height="300" elevation="1">
            <h2>התחזיות שלי</h2>
            <v-simple-table height="250">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>שם התכנית</th>
                    <th class="text-left">פעולות</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in $store.state.userPlans"
                    :key="index"
                  >
                    <td class="table-name">{{ item.data.planName }}</td>
                    <td class="text-left">
                      <v-btn color="#2953E8" class="yazam-button-blue"
                        >צפייה בנתונים</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <!-- <v-col cols="12" md="6" class="pa-2">
          <v-card class="pa-2" height="300" elevation="1">
            <h2>התובנות שלי</h2>
          </v-card>
        </v-col> -->
      </v-row>
      <!-- <v-row>
        <v-col cols="7">
          <v-expansion-panels class="mb-4">
            <v-expansion-panel>
              <v-expansion-panel-header
                >איך להשתמש באשף יצירת תחזית עסקית?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <p>תוכן תוכן תוכן....</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >איך מזינים הוצאות?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <p>תוכן תוכן תוכן....</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >איך מזינים הכנסות?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <p>תוכן תוכן תוכן....</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >מה ההבדל בין הוצאות קבועות להוצאות
                משתנות?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <p>תוכן תוכן תוכן....</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >איך מוסיפים שורות לטבלה?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <p>תוכן תוכן תוכן....</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >אפשר להגדיר הוצאות / הכנסות ברמה חודשית ולא רק
                שנתית?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <p>תוכן תוכן תוכן....</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >האם ניתן לקבל עזרה של מומחה מבית
                סימבה?</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <p>תוכן תוכן תוכן....</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="5">
          <v-card class="pa-2" height="335" elevation="1">
            <iframe
              width="100%"
              height="320"
              src="https://www.youtube.com/embed/x11eg6uxjQE"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </v-card>
        </v-col>
      </v-row> -->
    </v-container>
    <!-- <HelloWorld /> -->
    <!-- <v-row class="ma-3">
      <v-col cols="4">
        <v-card>
          <router-link to="/plans">
            <v-img
              src="https://simba-biz.com/wp-content/uploads/2018/04/simba-1.jpg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title>התחזיות שלי</v-card-title>
            </v-img>
          </router-link>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <router-link to="/new-plan">
            <v-img
              src="https://simba-biz.com/wp-content/uploads/2018/04/simba-1.jpg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title>תכנית עסקית חדשה</v-card-title>
            </v-img>
          </router-link>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <router-link to="/insights">
            <v-img
              src="https://simba-biz.com/wp-content/uploads/2018/04/simba-1.jpg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title>מודל תמחור</v-card-title>
            </v-img>
          </router-link>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="ma-3">
      <v-col cols="4">
        <v-card>
          <router-link to="/profile">
            <v-img
              src="https://simba-biz.com/wp-content/uploads/2018/04/simba-1.jpg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title>פרופיל אישי</v-card-title>
            </v-img>
          </router-link>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <router-link to="/development">
            <v-img
              src="https://simba-biz.com/wp-content/uploads/2018/04/simba-1.jpg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title>פיתוח עסקי</v-card-title>
            </v-img>

          </router-link>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <router-link to="/new-plan">
            <v-img
              src="https://simba-biz.com/wp-content/uploads/2018/04/simba-1.jpg"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title>שיחת ייעוץ עם מומחה</v-card-title>
            </v-img>

          </router-link>
        </v-card>
      </v-col>
    </v-row>-->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  computed: {
    products: function() {
      return this.$store.getters.currentBusinessProducts;
    },
  },
};
</script>
