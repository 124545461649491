<template>
  <div class="profile pa-5">
    <v-card class="pa-4" elevation="1">
      <v-row>
        <v-col>
          <h1>תחזיות פיננסיות</h1>
        </v-col>

        <v-col align="end">
          <v-btn
            color="#2953E8"
            class="ml-2 yazam-button-blue"
            @click="exportJson"
            >ייצוא גיבוי</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="4"
          v-for="plan in this.$store.state.userPlans"
          :key="plan.id"
        >
          <!-- v-if to check if plan had completed data -->
          <v-card>
            <router-link :to="`/plans/${plan.id}`">
              <v-img
                src="https://simba-biz.com/wp-content/uploads/2018/04/simba-1.jpg"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200px"
              >
                <v-card-title>{{ plan.data.planName }}</v-card-title>
                <v-chip
                  style="position:absolute; top: 10px; right: 10px;"
                  color="orange accent-2"
                  v-if="!plan.data.expenses"
                >
                  תכנית בבנייה
                </v-chip>
              </v-img>
            </router-link>
          </v-card>
        </v-col>
      </v-row>
      <!-- <p v-else>לא נוצרו תחזיות עסקיות בחשבון זה</p> -->
    </v-card>
  </div>
</template>

<script>
// import { plansCollection } from "../firebase";

export default {
  name: "Plans",
  components: {
    // HelloWorld
  },
  data: () => ({
    loadedPlans: [],
    DEMOLOADED: [
      {
        id: "9IrOHsBnhLswLwX8V0FH",
        data: {
          planName: "רועי התותח",
          fixedRowsData: [
            {
              yearly: 6600,
              expense: "550",
              item: "ארנונה",
              minimum: 6600,
              maximum: 6600,
            },
          ],
          user: "FQfTbjZhPeXb7QtSths7aj4Z6Ak2",
          initialRowsData: [
            {
              minimum: 450,
              maximum: 450,
              expense: "450",
              item: "אחסון לוגיסטי",
              yearly: 450,
            },
          ],
          dynamicRowsData: [
            {
              yearly: 9000,
              item: "מחקר",
              expense: "750",
              maximum: 9000,
              minimum: 9000,
            },
          ],
        },
      },
      {
        id: "zM9wKSSd7H8DTUBwf9p8",
        data: {
          user: "FQfTbjZhPeXb7QtSths7aj4Z6Ak2",
          expenses: {
            dynamicRowsData: [
              {
                minimum: 6000,
                expense: "500",
                maximum: 6000,
                yearly: 6000,
                item: "מחקר",
              },
            ],
            initialRowsData: [
              {
                item: "אחסון לוגיסטי",
                expense: "500",
                yearly: 500,
                minimum: 500,
                maximum: 500,
              },
            ],
            fixedRowsData: [
              {
                minimum: 3000,
                expense: "250",
                item: "ארנונה",
                yearly: 3000,
                maximum: 3000,
              },
            ],
          },
          planName: "רועי הנמר",
          income: {
            dynamicRowsData: [
              {
                minimum: 6000,
                expense: "500",
                yearly: 6000,
                maximum: 6000,
                item: "מחקר",
              },
            ],
            fixedRowsData: [
              {
                expense: "250",
                maximum: 3000,
                yearly: 3000,
                minimum: 3000,
                item: "ארנונה",
              },
            ],
            initialRowsData: [
              {
                yearly: 500,
                maximum: 500,
                expense: "500",
                item: "אחסון לוגיסטי",
                minimum: 500,
              },
            ],
          },
        },
      },
    ],
    loaded: false,
  }),
  methods: {
    exportJson() {
      this.download(this.$store.state.userPlans, "plans-backup.txt", "text/plain");
    },
    download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([JSON.stringify(content)], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    },
  },
  // mounted: async function() {
  //   let plans;
  //   console.log(
  //     "this.$store.state.currentUserData.role",
  //     this.$store.state.currentUserData.role
  //   );
  //   if (this.$store.state.currentUserData.role == "superuser") {
  //     plans = await plansCollection.get();
  //   } else {
  //     plans = await plansCollection
  //       .where("user", "==", this.$store.state.currentUser)
  //       .get();
  //   }

  //   if (plans) {
  //     plans.forEach((doc) => {
  //       //console.log(doc.id, "=>", doc.data());
  //       //this.loadedPlans.push(doc.data());

  //       const docData = {
  //         id: doc.id,
  //         data: doc.data(),
  //       };

  //       this.loadedPlans.push(docData);
  //       this.loaded = true;
  //     });
  //   }
  //   console.log("this.loadedPlans", this.loadedPlans);
  // },
};
</script>
