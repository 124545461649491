<template>
  <v-simple-table
    dense
    fixed-header
    :height="isDynamic ? '380px' : '500px'"
    class="elevation-1"
  >
    <template v-slot:default>
      <thead>
        <tr>
          <th style="width: 20%">פירוט</th>
          <th :style="!isInitial ? 'width: 20%' : 'width: 35%'">
            {{ isInitial ? "סכום חד פעמי" : "סכום חודשי" }}
          </th>
          <th style="width: 15%" v-if="!isInitial">חודש פעילות להתחלה</th>
          <th style="width: 20%">הערות</th>
          <th style="width: 25%">מבט שנתי / תקופתי</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, forindex) in rows" :key="forindex">
          <td class="table-name" @click="currentRename = item">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" dark v-bind="attrs" v-on="on"
                  >mdi-information-outline</v-icon
                >
              </template>
              <span>הסבר קצרצר</span>
            </v-tooltip>
            {{ item }}
            <v-text-field
              :value="item"
              :id="item"
              append-icon="mdi-content-save"
              @click:append="updateRename(item)"
              v-if="currentRename == item"
              dense
              flat
              hide-details
              rounded
              solo-inverted
              class="input-icon-switch my-1"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              label="הזינו נתון"
              type="number"
              @input="inputChanged('value', item, $event)"
              :append-icon="`mdi-currency-${currency}`"
              @click:append="test"
              dense
              flat
              hide-details
              rounded
              solo-inverted
              class="input-icon-switch"
            ></v-text-field>
            <!-- <v-switch label="הזנה כאחוז"></v-switch> -->
          </td>
          <!-- append-icon="mdi-percent-outline" -->
          <td v-if="!isInitial">
            <v-text-field
              label="1"
              type="number"
              @input="inputChanged('startMonth', item, $event)"
              :rules="inputRules"
              :max="months"
              min="1"
              dense
              flat
              hide-details="auto"
              rounded
              solo-inverted
              class="input-icon-switch"
            ></v-text-field>
          </td>
          <td class="py-2">
            <v-textarea
              label="הערות"
              @input="inputChanged('notes', item, $event)"
              rows="1"
              auto-grow
              clearable
              dense
              flat
              hide-details
              rounded
              solo-inverted
            ></v-textarea>
          </td>
          <td>
            <!-- <v-expand-transition>
              <div v-if="indexInRowsData(item) != -1" class="pa-2">
                <v-sparkline
                  :value="[rowsData[indexInRowsData(item)]['minimum'],rowsData[indexInRowsData(item)]['yearly'],rowsData[indexInRowsData(item)]['maximum']]"
                  label-size="15"
                  line-width="30"
                  height="80"
                  type="bar"
                >
                  <template v-slot:label="data">{{ Number(data.value).toLocaleString() }} ₪</template>
                </v-sparkline>
              </div>
            </v-expand-transition>-->
            <div v-if="indexInRowsData(item) != -1">
              צפי שנתי:
              {{
                Number(
                  rowsData[indexInRowsData(item)]["yearlyEstimate"]
                ).toLocaleString()
              }}
              <v-chip color="primary" class="mr-2 percent-ltr"
                >{{
                  (
                    (rowsData[indexInRowsData(item)]["yearlyEstimate"] /
                      allValuesSummed) *
                    100
                  ).toFixed(2)
                }}%</v-chip
              >
            </div>
            <!-- <p v-else>Empty</p> -->
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "Table",
  props: ["rows", "typeProp", "currency", "months"],
  data() {
    return {
      rowsData: [],
      inputRules: [
        (v) => (v && v >= 1) || "ערך תקין: 1 ומעלה",
        (v) => (v && v <= this.months) || "ערך תקין: " + this.months + " ומטה",
      ],
      currentRename: null,
    };
  },

  computed: {
    isInitial() {
      return this.typeProp == "initial" || this.typeProp == "Initial";
    },
    isDynamic() {
      return this.typeProp == "dynamic" || this.typeProp == "Dynamic";
    },
    allValuesSummed() {
      return this.rowsData.reduce(
        (acc, current) => acc + Number(current["yearlyEstimate"]),
        0
      );
    },
    isPlanInEdit: function() {
      if (Object.keys(this.$store.state.editedPlanData).length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    inputChanged(type, item, e) {
      console.log(type);
      console.log(item);
      console.log(e);

      let pos = this.indexInRowsData(item);
      let current = this.rowsData[pos];

      if (pos == -1) {
        this.rowsData.push({
          item: item,
          [type]: e,
          yearlyEstimate: 0,
          yearly: 0,
          months: [],

          //minimum: 0,
          //maximum: 0
        });
        current = this.rowsData[this.rowsData.length - 1];
      } else {
        current[type] = e;
      }
      this.calculateYearly(current);
      this.manipulateMonths(current);
      this.$emit("dataUpdate", this.rowsData, this.typeProp);
    },

    calculateYearly(current) {
      if (current["value"]) {
        if (this.isInitial) {
          current["yearlyEstimate"] = current["value"];
          current["yearly"] = current["value"];
        } else {
          if (current["startMonth"]) {
            current["yearlyEstimate"] =
              current["value"] * (this.months + 1 - current["startMonth"]);
            current["yearly"] =
              current["value"] * (this.months + 1 - current["startMonth"]);
          } else {
            current["yearlyEstimate"] = current["value"] * this.months;
            current["yearly"] = current["value"] * this.months;
          }
        }
      }
    },
    manipulateMonths(current) {
      if (current["value"]) {
        let firstMonth;
        if (current["startMonth"]) {
          firstMonth = current["startMonth"] - 1;
        } else {
          firstMonth = 1;
        }

        // for (i; i <= this.months; i++) {
        //   current[`month${i}`] = Number(current["value"]);
        // }

        for (let index = 0; index < this.months; index++) {
          if (index < firstMonth && current["startMonth"]) {
            //current.months[index] = 0;
            this.$set(current.months, index, 0);
          } else {
            //current.months[index] = Number(current["value"]);
            this.$set(current.months, index, current["value"]);
          }
        }
      }
    },
    replaceName(item) {
      this.currentRename = item;

      // if (this.currentRename == item) {
      //   this.currentRename = null;
      // } else {
      //   this.currentRename = item;
      // }
    },
    updateRename(item) {
      //console.log(item);
      let value = document.getElementById(item).value;
      //console.log(value);
      this.$emit("rowsUpdate", this.typeProp, item, value);
      this.currentRename = null;
    },

    // OLDcalculateYearly(current) {
    //   if (current["expense"]) {
    //     let yearly = this.isInitial
    //       ? current["expense"] * 1
    //       : current["expense"] * 12;
    //     current["yearly"] = yearly;
    //     current["minimum"] = yearly;
    //     current["maximum"] = yearly;
    //   }

    //   if (current["startMonth"] && current["yearly"]) {
    //     current["minimum"] =
    //       current["yearly"] - current["yearly"] * (current["deviation"] / 100);
    //     current["maximum"] =
    //       current["yearly"] + current["yearly"] * (current["deviation"] / 100);
    //   }
    // },

    indexInRowsData(item) {
      return this.rowsData.map((e) => e.item).indexOf(item);
    },
    test(e) {
      console.log(e);
    },
    updateDataFromStoreEditMode() {
      console.log("updateDataFromStoreEditMode ran from TableIncome");
      let editedPlan = this.$store.state.editedPlanData;

      if (editedPlan.expenses) {
        this.rowsData = editedPlan?.expenses[`${this.typeProp}RowsData`]
          ? editedPlan?.expenses[`${this.typeProp}RowsData`]
          : [];
      }

      //       this.initialRowsData = editedPlan.expenses.initialRowsData;
      // this.fixedRowsData = editedPlan.expenses.fixedRowsData;
      // this.dynamicRowsData = editedPlan.expenses.dynamicRowsData;
    },
  },
  created: function() {
    if (this.isPlanInEdit) {
      this.updateDataFromStoreEditMode();
    }
  },
  mounted() {
    console.log("Prop: " + this.typeProp);
    console.log("isInitial: " + this.isInitial);
  },
  watch: {
    isPlanInEdit: function(value) {
      console.log("watched isPlanInEdit from TableCopy: ", value);
      if (value) {
        this.updateDataFromStoreEditMode();
      }
    },
  },
};
</script>

<style>
rect {
  fill: #2953e8;
}

td {
  font-size: 0.95rem !important;
}

.table-name {
  cursor: pointer;
}

/* .table-name:hover {
  background-color: #c1a97d;
  color: #ffffff;
} */

.input-icon-switch i,
.input-icon-switch button {
  font-size: 16px !important;
}
</style>
