<template>
  <div class="development pa-5">
    <v-card class="pa-4" elevation="1">
      <!-- <HelloWorld /> -->
      <h1>האקדמיה ליזם</h1>
      <p>
        תמיד טוב ללמוד משהו חדש שיעזור לכם בעסקים ובדרך היזמית שלכם.
        <br />
        בחרנו לכם את מיטב הקורסים שיעזרו לכם באופן משמעותי בעסקים שלכם, אבל זכרו
        שחשוב מאוד ליישם את הדברים שתלמדו כאן. אז קדימה, בחרו לכם קורס מעניין
        וצאו לדרך!
      </p>

      <v-alert
        v-if="checkedServices.length"
        prominent
        dense
        type="info"
        color="primary"
        dark
        class="yazam-sticky"
      >
        <v-row align="center">
          <v-col class="grow">
            קורסים נבחרים:
            <span v-for="(item, order) in checkedServices" :key="item">
              <span v-if="order != 0">, </span>
              {{ item }}
            </span>
          </v-col>
          <v-col class="shrink">
            <!-- <v-btn @click="$router.push('/profile')" color="white" rounded style="color: black;">ליצירת עסק חדש</v-btn> -->
            <v-btn
              @click="getOffer"
              color="white"
              rounded
              style="color: black;"
            >
              <span v-if="offerSent">
                ✓ בקשתך התקבלה
              </span>
              <span v-else> קבלת הצעה משתלמת לקורסים הנבחרים</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-row>
        <v-col cols="3" v-for="(item, order) in services" :key="order">
          <v-card class="mx-auto">
            <v-img class="white--text align-end" height="200px" :src="item.img">
            </v-img>
            <v-card-title>{{ item.name }}</v-card-title>
            <v-checkbox
              label="אשמח לקבל הצעה"
              v-model="item.checked"
              class="yazam-checkbox"
            ></v-checkbox>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
      services: [
        {
          name: "קורס מכירות",
          img: "https://yazam.app/wp-content/uploads/2023/08/yazam-academy-4.jpg",
          checked: null,
        },
        {
          name: "קורס יזמות עסקית",
          img: "https://yazam.app/wp-content/uploads/2023/08/yazam-academy-5.jpg",
          checked: null,
        },
        {
          name: "קורס שיווק אסטרטגי",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-7.jpg",
          checked: null,
        },
        {
          name: "קורס בניית תכנית עסקית",
          img: "https://yazam.app/wp-content/uploads/2023/08/yazam-academy-2.jpg",
          checked: null,
        },
        {
          name: "קורס ניהול עובדים",
          img: "https://yazam.app/wp-content/uploads/2023/08/yazam-academy-3.jpg",
          checked: null,
        },
        {
          name: "קורס ניהול משא ומתן",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-5.jpg",
          checked: null,
        },
        {
          name: "קורס פיתוח עסקי",
          img: "https://yazam.app/wp-content/uploads/2023/08/yazam-academy-1.jpg",
          checked: null,
        },
        // {
        //   name: "קורס מזורז ליזם הטכנולוגי",
        //   img: "https://yazam.app/wp-content/uploads/2022/06/yazam-11.jpg",
        //   checked: null,
        // },
      ],
      offerSent: false,
    };
  },
  computed: {
    checkedServices: function() {
      return this.services
        .filter((service) => service.checked == true)
        .map((service) => service.name);
    },
  },
  methods: {
    getOffer() {
      console.log(this.$store.state.currentUser);
      console.log(this.checkedServices);

      fetch("https://hook.eu1.make.com/2hbgh6763weaq4e7ak4qg1pylqvxrk8f", {
        method: "POST",
        body: JSON.stringify({
          userId: this.$store.state.currentUser,
          userData: this.$store.state.currentUserData,
          services: this.checkedServices,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      this.offerSent = true;
    },
  },
};
</script>

<style scoped>
.yazam-checkbox {
  margin-top: -10px;
  padding-right: 16px;
}

.yazam-sticky {
  position: sticky;
  top: 70px;
  z-index: 1;
}
</style>
