<template>
  <div class="profile pa-5">
    <v-card id="business-edit-form" outlined class="pa-3 mt-6" elevation="0">
      <h1>יצירת עסק חדש</h1>
      <v-form ref="businessForm">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="businessname"
                label="שם העסק"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="selectedBusinessType"
                :items="businessType"
                label="סוג העסק"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4">
              <v-select
                v-model="selectedBusinessField"
                :items="businessField"
                label="תחום העסק"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="selectedSalesWay"
                :items="salesWay"
                label="ערוץ המכירה"
              ></v-select>
            </v-col>
            <!-- <v-col cols="12" md="4">
              <v-file-input
                :rules="rulesImg"
                accept="image/png, image/jpeg, image/bmp"
                placeholder="העלאת תמונה / לוגו"
                prepend-icon="mdi-camera"
                label="תמונה ראשית"
              ></v-file-input>
            </v-col> -->
            <v-col cols="12" md="4">
              <v-select
                v-model="selectedCurrency"
                :items="currencyOptions"
                item-text="title"
                item-value="value"
                label="בחירת מטבע"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="currentProduct"
                label="המוצרים שלי"
                @keyup.enter="addProduct"
              ></v-text-field>
              <v-chip
                v-for="(prod, i) in products"
                :key="i"
                close
                @click:close="deleteProduct(i)"
                outlined
                label
                color="primary"
                class="ma-1"
                >{{ prod.name }}</v-chip
              >
              <v-simple-table fixed-header height="400px" class="elevation-1">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="width: 25%">פירוט</th>
                      <th>עלות לעסק</th>
                      <th>סכום המכירה</th>
                      <th style="width: 15%">סוג המוצר</th>
                      <th>שיעור הרווח</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in products" :key="index">
                      <td class="table-name">{{ item.name }}</td>
                      <td>
                        <!-- @input="inputChanged('cost',item,index,$event)" -->
                        <v-text-field
                          label="הזינו נתון"
                          type="number"
                          v-model.number="item.cost"
                          :append-icon="`mdi-currency-${selectedCurrency}`"
                          dense
                          flat
                          hide-details
                          rounded
                          solo-inverted
                          class="input-icon-switch"
                        ></v-text-field>
                      </td>
                      <td>
                        <!-- @input="inputChanged('price',item,index,$event)" -->
                        <v-text-field
                          label="הזינו נתון"
                          type="number"
                          v-model.number="item.price"
                          :append-icon="`mdi-currency-${selectedCurrency}`"
                          dense
                          flat
                          hide-details
                          rounded
                          solo-inverted
                          class="input-icon-switch"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-select
                          label="הזינו סוג מוצר"
                          v-model="item.type"
                          :items="productTypes"
                          item-text="title"
                          item-value="value"
                          solo-inverted
                          flat
                          dense
                          rounded
                          hide-details
                          filled
                        ></v-select>
                      </td>
                      <td class="d-flex justify-space-between align-center">
                        {{ ((item.price / item.cost) * 100 - 100).toFixed(2) }}%
                        <v-btn @click="calcInsights(item.price, item.cost)"
                          >חישוב תמחור</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-alert
            prominent
            dense
            type="warning"
            color="primary"
            dark
            v-if="!valid"
          >
            <v-row align="center">
              <v-col class="grow">
                נא למלא את כל השדות לפני שמירה ויצירת עסק
              </v-col>
            </v-row>
          </v-alert>
          <v-alert prominent dense type="success" dark v-if="createdSuccess">
            <v-row align="center">
              <v-col class="grow">
                העסק נוצר בהצלחה
              </v-col>
            </v-row>
          </v-alert>
          <v-btn @click="createNewBusiness" :disabled="!valid"
            >שמירה ויצירת עסק חדש</v-btn
          >

          <!-- <p>שם העסק תחום מיקום בארץ קהל יעד פוטנציאלי מתחרים ידועים סוג המוצר - פיזי,שירות,תוכנה ערוץ המכירה - וירטואלי, חנות פיזית, גם וגם מודל עסקי - רכישה חד פעמית, מנוי חודשי</p> -->
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import { businessCollection } from "../firebase";

export default {
  name: "Profile",
  components: {
    // HelloWorld
  },
  data: () => ({
    loaded: false,
    //loadedBusiness: [],
    //loadedBusiness: this.$store.state.userBusiness,
    currentBusiness: "",
    isCreatingNewBusiness: true,

    // valid: false,
    firstname: "",
    phoneNum: "",
    nameRules: [(v) => !!v || "שם הוא שדה חובה"],
    email: "",
    emailRules: [
      (v) => !!v || "אימייל הוא שדה חובה",
      (v) => /.+@.+/.test(v) || "נא להזין כתובת אימייל תקנית",
    ],
    businessname: "",
    businessType: ["עוסק פטור", "עוסק מורשה", 'חברה בע"מ'],
    selectedBusinessType: "",
    businessField: [
      "כושר",
      "פיננסים",
      "טכנולוגיה",
      "שירותים",
      "תחבורה",
      "אופנה",
      "איקומרס",
      "תוכנה",
      "אחר",
    ],
    selectedBusinessField: "",
    salesWay: ["פיזי", "אונליין", "גם וגם"],
    selectedSalesWay: "",
    rulesImg: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Avatar size should be less than 2 MB!",
    ],
    currencyOptions: [
      {
        title: "שקל",
        value: "ils",
      },
      { title: "דולר אמריקאי", value: "usd" },
    ],
    productTypes: [
      {
        title: "חד פעמי",
        value: "onetime",
      },
      { title: "ריטיינר / מחזורי", value: "subscription" },
    ],
    selectedCurrency: "ils",
    products: [],
    currentProduct: "",
    createdSuccess: false,
  }),
  created: function() {
    console.log("loadAllBusiness From Created ");
    //console.warn(this.$store.state.userBusiness);
    //this.loadAllBusiness();
    //this.loadedBusiness = this.$store.state.userBusiness;
  },
  computed: {
    loadedBusiness: function() {
      return this.$store.state.userBusiness;
    },
    currentBusinessFromStore: function() {
      return this.$store.state.currentBusiness;
    },
    valid: function() {
      if (
        this.businessname &&
        this.selectedBusinessType &&
        this.selectedBusinessField &&
        this.selectedSalesWay &&
        this.products.length > 0
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async loadAllBusiness() {
      this.loadedBusiness = [];
      const business = await businessCollection
        .where("user", "==", this.$store.state.currentUser)
        .get();

      if (business) {
        business.forEach((doc) => {
          //console.log(doc.id, "=>", doc.data());
          //this.loadedPlans.push(doc.data());

          const docData = {
            id: doc.id,
            data: doc.data(),
          };

          this.loadedBusiness.push(docData);
          this.loaded = true;
        });
      }
      console.log("the business");
      console.log(business);
      this.$store.commit("setUserBusiness");
    },
    async loadBusinessData(id) {
      const business = await businessCollection
        .doc(id)
        .get()
        .then((doc) => doc.data());
      console.log(business);

      if (business) {
        this.businessname = business.businessname;
        this.selectedBusinessType = business.selectedBusinessType;
        this.selectedBusinessField = business.selectedBusinessField;
        this.selectedSalesWay = business.selectedSalesWay;
        this.selectedCurrency = business.selectedCurrency;
        this.products = business.products;
      }

      this.currentBusiness = id;
      this.isCreatingNewBusiness = false;
      this.$vuetify.goTo("#business-edit-form");
      this.$store.commit("setCurrentBusiness", id);
      console.log("loadBusinessData");
    },
    async createNewBusiness() {
      if (this.valid) {
        const newBusiness = await businessCollection.add({
          user: this.$store.state.currentUser,
          businessname: this.businessname,
          selectedBusinessType: this.selectedBusinessType,
          selectedBusinessField: this.selectedBusinessField,
          selectedSalesWay: this.selectedSalesWay,
          selectedCurrency: this.selectedCurrency,
          products: this.products,
        });

        if (newBusiness) {
          // this.businessname = "";
          // this.selectedBusinessType = "";
          // this.selectedBusinessField = "";
          // this.selectedSalesWay = "";
          // this.selectedCurrency = "";

          this.$refs.businessForm.reset();
          this.products = [];
        }

        console.log("newBusiness");
        console.log(newBusiness.id);

        this.createdSuccess = true;

        this.currentBusiness = newBusiness.id;
        //this.loadAllBusiness();
        this.$store.dispatch("loadAllBusiness");
        this.$store.commit("setCurrentBusiness", newBusiness.id);
      } else {
        console.log("not valid");
      }
    },
    async updateBusiness() {
      if (this.valid && this.currentBusiness) {
        await businessCollection
          .doc(this.currentBusiness)
          .set({
            user: this.$store.state.currentUser,
            businessname: this.businessname,
            selectedBusinessType: this.selectedBusinessType,
            selectedBusinessField: this.selectedBusinessField,
            selectedSalesWay: this.selectedSalesWay,
            selectedCurrency: this.selectedCurrency,
            products: this.products,
          })
          .then(() => {
            console.log(`Business ${this.currentBusiness} Updated`);
          });
        this.updateProducts();
      } else {
        console.log("not valid");
      }
    },
    newBusinessForm() {
      this.$refs.businessForm.reset();
      this.products = [];
      this.isCreatingNewBusiness = true;
    },
    addProduct() {
      if (this.currentProduct.length > 0) {
        this.products.push({
          name: this.currentProduct,
          cost: 0,
          price: 0,
        });
        this.currentProduct = "";
        this.updateProducts();
      }
    },
    deleteProduct(i) {
      this.products.splice(i, 1);
      this.updateProducts();
    },
    updateProducts() {
      this.$store.dispatch("setCurrentProducts", this.products);
    },
    inputChanged(type, item, index, e) {
      console.log(type);
      console.log(item);
      console.log(index);
      console.log(e);

      this.products[index][type] = Number(e);
      this.updateProducts();
    },
    calcInsights(itemPrice, itemCost) {
      console.log(itemPrice, itemCost);
      this.$router.push({
        name: "Insights",
        params: { price: itemPrice, cost: itemCost },
      });
    },
  },
  watch: {
    currentBusinessFromStore: function() {
      this.loadBusinessData(this.currentBusinessFromStore);
    },
  },
};
</script>
