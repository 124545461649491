<template>
  <v-container>
    <!-- <h2 class="mb-4">סיכום ביניים</h2> -->

    <!-- <v-simple-table class="mb-4 final-table">
      <template v-slot:default>
        <thead>
          <tr>
            <th style="width: 25%">פירוט</th>
            <th style="width: 25%">תרחיש שנתי</th>
            <th style="width: 25%">תרחיש שנתי מינימלי</th>
            <th style="width: 25%">תרחיש שנתי מקסימלי</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{typeText}} {{typeText == 'הוצאות' ? 'הקמה' : 'ראשוניות (חד פעמיות)'}}</td>
            <td>{{ calcInitial.toLocaleString() }}</td>
            <td>---</td>
            <td>---</td>
          </tr>
          <tr>
            <td>{{typeText}} קבועות</td>
            <td>{{ calcFixedRange['yearlyEstimate'].toLocaleString() }}</td>
          </tr>
          <tr>
            <td>{{typeText}} משתנות</td>
            <td>{{ calcDynamicRange['yearlyEstimate'].toLocaleString() }}</td>

          </tr>
          <tr>
            <td>כל ה{{typeText}}</td>
            <td>{{ calcAll['yearlyEstimate'].toLocaleString() }}</td>

          </tr>
        </tbody>
      </template>
    </v-simple-table>-->

    <v-row>
      <v-col>
        <h3 class="mb-4">פילוח {{typeText}}</h3>
        <apexchart height="400" type="line" :options="chartOptionsLine" :series="seriesLine"></apexchart>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="7">
        <h3 class="mb-4">מבט חודשי</h3>
        <apexchart width="700" type="bar" :options="options" :series="calcMonthlyForYearlyGraph"></apexchart>
      </v-col>
      <v-col cols="5">
        <h3 class="mb-4">פילוח {{typeText}}</h3>
        <apexchart
          width="500"
          type="donut"
          :options="donutOptions"
          :series="[calcInitial,calcFixed,calcDynamic]"
        ></apexchart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Graphs",
  props: ["initialRowsData", "fixedRowsData", "dynamicRowsData", "typeText"],
  components: { apexchart: VueApexCharts },
  data: () => ({
    options: {
      chart: {
        stacked: true,
        toolbar: {
          show: false
        },
        fontFamily: "Open Sans Hebrew, Helvetica, Arial"
      },
      xaxis: {
        categories: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12"
        ]
      },
      yaxis: {
        labels: {
          align: "center",
          formatter: value => {
            return Number(value).toLocaleString();
          }
        }
      },
      legend: {
        fontSize: "16px"
      }
    },
    series: [
      {
        name: "series-1",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      },
      {
        name: "series-2",
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      }
    ],
    donutOptions: {
      // labels: [
      //   "הוצאות הקמה",
      //   "הוצאות קבועות",
      //   "הוצאות משתנות"
      // ],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                fontSize: "16px"
              },
              value: {
                fontFamily: "Open Sans Hebrew, Helvetica, Arial",
                formatter: value => {
                  return Number(value).toLocaleString();
                }
              }
            }
          }
        }
      },
      legend: {
        fontFamily: "Open Sans Hebrew, Helvetica, Arial",
        fontSize: "16px"
      },
      dataLabels: {
        style: {
          fontSize: "14px",
          fontWeight: "600",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial"
        }
      },
      tooltip: {
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans Hebrew, Helvetica, Arial"
        },
        y: {
          formatter: value => {
            return Number(value).toLocaleString();
          }
        }
      }
    },
    seriesLine: [
      {
        name: "Likes",
        data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5]
      }
    ],
    chartOptionsLine: {
      chart: {
        // height: 1000,
        // type: 'line',
        toolbar: {
          show: false
        },
        fontFamily: "Open Sans Hebrew, Helvetica, Arial"
      },
      stroke: {
        width: 7,
        curve: "smooth"
      },
      xaxis: {
        type: "datetime",
        categories: [
          "1/11/2000",
          "2/11/2000",
          "3/11/2000",
          "4/11/2000",
          "5/11/2000",
          "6/11/2000",
          "7/11/2000",
          "8/11/2000",
          "9/11/2000",
          "10/11/2000",
          "11/11/2000",
          "12/11/2000",
          "1/11/2001",
          "2/11/2001",
          "3/11/2001",
          "4/11/2001",
          "5/11/2001",
          "6/11/2001"
        ],
        tickAmount: 10,
        labels: {
          formatter: function(value, timestamp, opts) {
            return opts.dateFormatter(new Date(timestamp), "dd MMM");
          }
        }
      },
      title: {
        text: "Social Media",
        align: "center",
        style: {
          fontSize: "16px",
          color: "#666"
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          gradientToColors: ["#FDD835"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100, 100, 100]
        }
      },
      markers: {
        size: 4,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
          size: 7
        }
      },
      yaxis: {
        min: -10,
        max: 40,
        title: {
          text: "Engagement"
        }
      }
    }
  }),
  computed: {
    calcInitial: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.initialRowsData.reduce(
        (acc, current) => acc + Number(current["yearlyEstimate"]),
        0
      );
    },
    calcFixed: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.fixedRowsData.reduce(
        (acc, current) => acc + Number(current["yearlyEstimate"]),
        0
      );
    },
    calcDynamic: function() {
      //   return this.initialRowsData.map(x=>x['yearly']).reduce((acc, current) => acc+current, 0);
      return this.dynamicRowsData.reduce(
        (acc, current) => acc + Number(current["yearlyEstimate"]),
        0
      );
    },
    calcAll: function() {
      return {
        yearly: this.calcInitial + this.calcFixed + this.calcDynamic,
        minimum:
          this.calcInitial +
          this.calcFixedRange["minimum"] +
          this.calcDynamicRange["minimum"],
        maximum:
          this.calcInitial +
          this.calcFixedRange["maximum"] +
          this.calcDynamicRange["maximum"]
      };
    },
    calcFixedRange: function() {
      return {
        yearly: this.fixedRowsData.reduce(
          (acc, current) => acc + Number(current["yearly"]),
          0
        ),
        minimum: this.fixedRowsData.reduce(
          (acc, current) => acc + Number(current["minimum"]),
          0
        ),
        maximum: this.fixedRowsData.reduce(
          (acc, current) => acc + Number(current["maximum"]),
          0
        )
      };
    },
    calcDynamicRange: function() {
      return {
        yearly: this.dynamicRowsData.reduce(
          (acc, current) => acc + Number(current["yearly"]),
          0
        ),
        minimum: this.dynamicRowsData.reduce(
          (acc, current) => acc + Number(current["minimum"]),
          0
        ),
        maximum: this.dynamicRowsData.reduce(
          (acc, current) => acc + Number(current["maximum"]),
          0
        )
      };
    },
    calcMonthly: function() {
      return {
        fixed: this.fixedRowsData.reduce(
          (acc, current) => acc + Number(current["expense"]),
          0
        ),
        dynamic: this.dynamicRowsData.reduce(
          (acc, current) => acc + Number(current["expense"]),
          0
        )
      };
    },
    calcMonthlyForYearlyGraph: function() {
      let initialA = this.calcInitial;
      let fixed = [];
      let dynamic = [];

      for (let index = 0; index < 12; index++) {
        fixed[index] = this.calcMonthly.fixed;
        dynamic[index] = this.calcMonthly.dynamic;
      }

      //   return {
      //     fixedArray: fixed,
      //     dynamicArray: dynamic
      //   };

      return [
        {
          name: this.typeText + " קבועות",
          data: fixed
        },
        {
          name: this.typeText + " משתנות",
          data: dynamic
        },
        {
          name:
            this.typeText +
            (this.typeText == "הוצאות" ? " הקמה" : " ראשוניות (חד פעמיות)"),
          data: [initialA]
        }
      ];
    },
    donutLabels: function() {
      //console.log("donutLabels")
      if (this.typeText == "הוצאות") {
        return ["הוצאות הקמה", "הוצאות קבועות", "הוצאות משתנות"];
      } else {
        return ["הכנסות ראשוניות", "הכנסות קבועות", "הכנסות משתנות"];
      }
    }
  },
  mounted: function() {
    this.donutOptions = {
      labels: this.donutLabels
    };
  }
};
</script>

<style>
span.apexcharts-legend-marker {
  margin-left: 5px;
  margin-right: 5px;
}

.final-table td,
.final-table th {
  font-size: 16px !important;
}
</style>
     