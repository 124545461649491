<template>
  <div class="development pa-5">
    <v-card class="pa-4" elevation="1">
      <!-- <HelloWorld /> -->
      <h1>פיתוח עסקי</h1>
      <p>
        תודות לקשרים העסקיים שלנו, נוכל לדאוג לכם לתנאים הטובים ביותר לטובת
        השירותים שייקחו אתכם צעד קדימה
      </p>

      <v-alert
        v-if="checkedServices.length"
        prominent
        dense
        type="info"
        color="primary"
        dark
        class="yazam-sticky"
      >
        <v-row align="center">
          <v-col class="grow">
            שירותים נבחרים:
            <span v-for="(item, order) in checkedServices" :key="item">
              <span v-if="order != 0">, </span>
              {{ item }}
            </span>
          </v-col>
          <v-col class="shrink">
            <!-- <v-btn @click="$router.push('/profile')" color="white" rounded style="color: black;">ליצירת עסק חדש</v-btn> -->
            <v-btn
              @click="getOffer"
              color="white"
              rounded
              style="color: black;"
            >
              <span v-if="offerSent">
                ✓ בקשתך התקבלה
              </span>
              <span v-else> קבלת הצעה משתלמת לשירותים הנבחרים</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>

      <v-row>
        <v-col cols="3" v-for="(item, order) in services" :key="order">
          <v-card class="mx-auto">
            <v-img class="white--text align-end" height="200px" :src="item.img">
            </v-img>
            <v-card-title>{{ item.name }}</v-card-title>
            <v-checkbox
              label="אשמח לקבל הצעה"
              v-model="item.checked"
              class="yazam-checkbox"
            ></v-checkbox>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
      services: [
        {
          name: "בניית אתרים",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-3.jpg",
          checked: null,
        },
        {
          name: "ייעוץ וליווי עסקי",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-5.jpg",
          checked: null,
        },
        {
          name: "פתיחת חברה וייעוץ משפטי",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-6.jpg",
          checked: null,
        },
        {
          name: "ייעוץ מס",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-10.jpg",
          checked: null,
        },
        {
          name: "שיווק ומיתוג",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-7.jpg",
          checked: null,
        },
        {
          name: "כתיבת תוכן",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-1.jpg",
          checked: null,
        },
        {
          name: "צלמים ועורכי וידאו",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-9.jpg",
          checked: null,
        },
        {
          name: "קרנות השקעה במיזמים",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-2.jpg",
          checked: null,
        },
        {
          name: "מערכות CRM וניהול לעסק",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-8.jpg",
          checked: null,
        },
        {
          name: "חברות סליקה והעברות כספים",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-4.jpg",
          checked: null,
        },
        {
          name: "משרדי ראיית חשבון והקמת עסק ברשויות",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-13.jpg",
          checked: null,
        },
        {
          name: "פיתוח תוכנה ואפליקציות",
          img: "https://yazam.app/wp-content/uploads/2022/06/yazam-11.jpg",
          checked: null,
        },
      ],
      offerSent: false,
    };
  },
  computed: {
    checkedServices: function() {
      return this.services
        .filter((service) => service.checked == true)
        .map((service) => service.name);
    },
  },
  methods: {
    getOffer() {
      console.log(this.$store.state.currentUser);
      console.log(this.checkedServices);

      fetch("https://hook.eu1.make.com/2hbgh6763weaq4e7ak4qg1pylqvxrk8f", {
        method: "POST",
        body: JSON.stringify({
          userId: this.$store.state.currentUser,
          userData: this.$store.state.currentUserData,
          services: this.checkedServices,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });

      this.offerSent = true;
    },
  },
};
</script>

<style scoped>
.yazam-checkbox {
  margin-top: -10px;
  padding-right: 16px;
}

.yazam-sticky {
  position: sticky;
  top: 70px;
  z-index: 1;
}
</style>
